import React, { FC } from 'react';

import c from './HeaderLongread.sass';

interface TProps {
  show: boolean
}

const HeaderLongread: FC<TProps> = ({ show }) => (show ? (
  <div className={c.headerLongread}>
    headerLongread
  </div>
) : null);

HeaderLongread.displayName = 'HeaderLongread';
export default HeaderLongread;
