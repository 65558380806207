import { useState } from 'react';
import { SubscriptionPlan } from '~/resources/models';

const SUBSCRIPTION_FLOW_KEY = 'subscription_flow';

export enum SubscriptionType {
  NEW = 'new',
  EXISTING = 'existing',
  PUBLIC = 'public',
  PROMO = 'promo',
}

export enum SubscriptionFlowStep {
  AUTH = 'auth',
  PAYMENT = 'payment',
  CHECK = 'check',
  SUCCESS = 'success',
}

export interface SubscriptionFlow {
  step: SubscriptionFlowStep,
  type: SubscriptionType;
  successUrl: string;
  plan?: SubscriptionPlan;
  subscriptionStartUrl?: string;
}

export type SubscriptionFlowState = [
  SubscriptionFlow | undefined,
  (flowVal: SubscriptionFlow | undefined) => void,
] | undefined;

const clearSubscriptionFlow = () => {
  // console.log('[SUBSCRIPTION FLOW]: CLEAR FROM LS');
  if (typeof window !== 'undefined') {
    localStorage.removeItem(SUBSCRIPTION_FLOW_KEY);
  }
};

const restoreSubscriptionFlow = (): SubscriptionFlow | undefined => {
  // console.log('[SUBSCRIPTION FLOW]: RESTORE FROM LS');
  if (typeof window !== 'undefined') {
    const value = localStorage.getItem(SUBSCRIPTION_FLOW_KEY);
    if (value) {
      return JSON.parse(value);
    }
  }
  return undefined;
};

const saveSubscriptionFlow = (flowVal: SubscriptionFlow | undefined) => {
  // console.log('[SUBSCRIPTION FLOW]: SET TO LS', flowVal);
  if (typeof window !== 'undefined') {
    if (flowVal) {
      localStorage.setItem(SUBSCRIPTION_FLOW_KEY, JSON.stringify(flowVal));
    } else {
      clearSubscriptionFlow();
    }
  }
};

export const useSubscriptionFlow = (): SubscriptionFlowState => {
  const initValue = restoreSubscriptionFlow();
  const [flow, setFlow] = useState<SubscriptionFlow | undefined>(initValue);
  const setSubscriptionFlow = (flowVal: SubscriptionFlow | undefined) => {
    // console.log('setSubscriptionFlow', flowVal);
    saveSubscriptionFlow(flowVal);
    return setFlow(flowVal);
  };
  return [flow, setSubscriptionFlow];
};
