import React, {
  createContext, FC, useState, FormEvent, useContext, useEffect,
} from 'react';
import Modal from 'react-modal';
import { keys, sendPurchaseEvent, states } from '~/utils/purshase-event';
import classes from './Form.sass';
import config from '../../config';
import Loader from '../Loader';
import CloseIcon from '../Subscription/ModalSubscription/CloseIcon';

const Context = createContext<{
  w: number,
  orderingReport: [any, React.Dispatch<React.SetStateAction<any>>] | null; }>({
    w: 23,
    orderingReport: null,
  });

export const useFormContext = () => useContext<any>(Context);

const FormContext: FC<any> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(states[keys.SUBMIT]);
  const [order, setOrder] = useState<any>();
  const [email, setEmail] = useState('');
  const [isPaymentFormReady, setIsPaymentFormReady] = useState(false);
  const [orderingReport, setOrderingReport] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [isPreOrder, setIsPreOrder] = useState(false);
  const [
    originalViewportMetaContent,
    setOriginalViewportMetaContent,
  ] = useState<string | null>(null);

  useEffect(() => {
    if (!orderingReport) return;

    setOpen(true);
    setState(states[keys.SUBMIT]);
  }, [orderingReport]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    setState(states[keys.PAYMENT]);

    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      const originalContent = viewportMeta.getAttribute('content');
      setOriginalViewportMetaContent(originalContent);
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
    }

    fetch(`${config('API_HOST')}/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: email,
        email,
        products: [
          { id: orderingReport.id, quantity: 1 },
        ],
      }),
    }).then((response) => {
      setLoading(false);
      return response.json();
    }).then((response) => {
      if (!response.error) {
        setOrder(response);
      } else {
        setState(states[keys.SOMETHING_WENT_WRONG]);
      }
    });

    setEmail('');
  };

  const onClose = () => {
    setOpen(false);
    setState(states[keys.SUBMIT]);
    setIsPaymentFormReady(false);
    setOrderingReport(undefined);
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta && originalViewportMetaContent) {
      viewportMeta.setAttribute('content', originalViewportMetaContent);
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('order_id');
    const status = urlParams.get('status');
    const productPrice = urlParams.get('productPrice');
    const productId = urlParams.get('productId');
    const productTitle = urlParams.get('productTitle');

    const sendEvent = () => {
      if (!orderId || !productPrice || !productId || !productTitle) return;
      sendPurchaseEvent({
        orderId,
        revenue: productPrice,
        product: {
          id: productId,
          price: productPrice,
          name: productTitle,
        },
      });
    };

    if (orderId) {
      setIsPaymentFormReady(false);
      window.history.pushState(
        {},
        document.title,
        document.location.origin + document.location.pathname,
      );
      setOpen(true);
      setState(states[keys.LOADING]);
      setIsPreOrder(status === 'pre_order');
      setTimeout(() => {
        fetch(`${config('API_HOST')}/orders/${orderId}/status`)
          .then((response) => response.json())
          .then((response) => {
            if (response.error) {
              console.log(response.error);
              return;
            }

            switch (response.data.status) {
              case 'in_pay': {
                sendEvent();
                setState(states[keys.IN_PAY]);
                break;
              }
              case 'failed': {
                setState(states[keys.FAIL]);
                break;
              }
              case 'pending':
              case 'processed': {
                sendEvent();
                setState(states[keys.SUCCESS]);
                break;
              }
              default: {
                setState(states[keys.SOMETHING_WENT_WRONG]);
                break;
              }
            }
          });
      }, 5000);
    }
  }, []);

  useEffect(() => {
    if (!order) return;

    switch (order.confirmation.type) {
      case 'redirect': {
        window.location.href = order.confirmation.confirmation_url;
        break;
      }
      case 'embedded': {
        const productPrice = orderingReport.status === 'active' ? orderingReport.active_price : orderingReport.pre_order_price;
        const productId = orderingReport.id;
        const productTitle = orderingReport.title;
        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: order.confirmation.confirmation_token,
          return_url: `${window.location.href}?order_id=${order?.data.id}&status=${orderingReport.status}&productPrice=${productPrice}&productId=${productId}&productTitle=${productTitle}`,
          embedded_3ds: true,
          error_callback(error: any) {
            console.log(error);
          },
        });
        checkout.render('payment-form')
          .then(() => {
            setIsPaymentFormReady(true);
          });
        break;
      }
      case 'free_of_charge': {
        setState(states[keys.FREE_SUCCESS]);
        break;
      }
      default:
    }
  }, [order]);

  return (
    <Context.Provider value={{ w: 23, orderingReport: [orderingReport, setOrderingReport] }}>
      {children}

      <Modal
        isOpen={open}
        ariaHideApp={false}
        onRequestClose={onClose}
        overlayClassName={classes.modal}
        shouldCloseOnOverlayClick={false}
        className={classes.modal__content}
        style={{ overlay: { zIndex: 10000 } }}
      >
        {state.key === keys.LOADING && (
          <div className={classes.form}>
            <Loader />
          </div>
        )}

        {state.key === keys.SUBMIT && (
          <div className={classes.form}>
            <button className={classes.modal__close} type="button" onClick={onClose}>
              <CloseIcon />
            </button>
            <div className={classes.form__content}>
              <h1 className={classes.form__title}>
                Оставьте e-mail и мы вам все пришлем после оплаты
              </h1>
            </div>

            <form className={classes.form__submit} onSubmit={onSubmit}>
              <input
                className={classes.form__input}
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="e-mail"
                aria-label="Укажите свой email"
              />
              <button className={classes.form__button} type="submit" disabled={loading || !email}>Далее</button>
            </form>
          </div>
        )}

        {state.key === keys.PAYMENT && (
          <div className={`${classes.form} ${classes.payment_form_wrapper}`}>
            <button className={classes.modal__close} type="button" disabled={!isPaymentFormReady} onClick={onClose}>
              <CloseIcon />
            </button>
            {!isPaymentFormReady && <Loader className={classes.payment_form} />}
            <div className={classes.payment_form} id="payment-form" />
          </div>
        )}

        {state.key === keys.SUCCESS && (
          <div className={classes.form}>
            <div className={classes.form__content}>
              <h1 className={`${classes.form__title} ${classes.form__title_ok}`}>
                <span>Спасибо</span>
                <span>за покупку!</span>
              </h1>

              <h2 className={classes.form__subTitle}>
                {isPreOrder
                  ? 'Мы вышлем вам отчёт, как только он будет готов'
                  : 'Отчёт скоро будет у вас в почте'}
              </h2>
            </div>

            <button
              className={`${classes.form__submit} ${classes.form__submit_ok}`}
              onClick={onClose}
              type="button"
            >
              Ура!
            </button>
          </div>
        )}

        {state.key === keys.FREE_SUCCESS && (
          <div className={classes.form}>
            <div className={classes.form__content}>
              <h2 className={classes.form__subTitle}>Отчет скоро будет у вас в почте</h2>
            </div>
            <button
              className={`${classes.form__submit} ${classes.form__submit_ok}`}
              onClick={onClose}
              type="button"
            >
              Ура!
            </button>
          </div>
        )}

        {state.key === keys.IN_PAY && (
          <div className={classes.form}>
            <div className={classes.form__content}>
              <h1 className={`${classes.form__title} ${classes.form__title_ok}`}>
                <span>Платёж обрабатывается</span>
              </h1>
              <h2 className={classes.form__subTitle}>
                Как только платёж будет зачислен, мы отправим отчёт вам на почту
              </h2>
            </div>
            <button
              className={`${classes.form__submit} ${classes.form__submit_ok}`}
              onClick={onClose}
              type="button"
            >
              Хорошо
            </button>
          </div>
        )}

        {state.key === keys.FAIL && (
          <div className={classes.form}>
            <div className={classes.form__content}>
              <h1 className={`${classes.form__title} ${classes.form__title_ok}`}>
                <span>Ошибка</span>
              </h1>

              <h2 className={classes.form__subTitle}>Не удалось зачислить платёж</h2>
            </div>
            <button
              className={`${classes.form__submit} ${classes.form__submit_ok}`}
              onClick={onClose}
              type="button"
            >
              Назад
            </button>
          </div>
        )}

        {state.key === keys.SOMETHING_WENT_WRONG && (
          <div className={classes.form}>
            <div className={classes.form__content}>
              <h1 className={`${classes.form__title} ${classes.form__title_ok}`}>
                <span>Упс...</span>
              </h1>
              <h2 className={classes.form__subTitle}>Что-то пошло не так</h2>
            </div>
            <button
              className={`${classes.form__submit} ${classes.form__submit_ok}`}
              onClick={onClose}
              type="button"
            >
              Назад
            </button>
          </div>
        )}
      </Modal>
    </Context.Provider>
  );
};

FormContext.displayName = 'FormContext';
export default FormContext;
