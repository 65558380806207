import { match as Match } from 'react-router';
import useResource, { ResourceResult, Resource } from './useResource';
import { Tag } from './models';

import config from '../config';
import anyFetch from './anyFetch';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}/tags`;
const CACHE_KEY_PREFIX = 'tags/';

const tagFetcher = (slug: string) => async (params = {}, opts = {}): Promise<Resource<Tag>> => {
  const res = await anyFetch(`${PATH()}/${slug}`, opts);
  const data = await res.json();
  return data;
};

const useTag = (slug: string): ResourceResult<Tag> =>
  useResource({}, {}, tagFetcher(slug), `${CACHE_KEY_PREFIX}${slug}`);

export const fetchSSRTag = async (
  auth: Auth,
  path: string,
  match: Match,
  opts: RequestInit = {},
): Promise<any> => {
  const { tag } = (match as Match<{tag: string}>).params;
  const res = await tagFetcher(tag)({}, opts);
  auth.setCache(`${CACHE_KEY_PREFIX}${tag}`, res);
};

export default useTag;
