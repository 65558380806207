import React, {
  FC, MutableRefObject, useEffect, useRef, useState,
} from 'react';
import BuyButton from './BuyButton';
import { useFormContext } from './Form';
import classes from './ReportContent.sass';
import PageReportHeaderNew from './PageReportHeaderNew';

const FOOTER_HEIGHT = 600;
const HEADER_HEIGHT = 500;

interface TProps {
  report: any;
  gradient: string;
}

const ReportContent: FC<TProps> = ({ report, gradient }) => {
  const buyButtonRef = useRef<HTMLButtonElement>() as MutableRefObject<HTMLButtonElement>;

  const [, setOrderingReport] = useFormContext().orderingReport;

  const [screenMd, setScreenMd] = useState(false);

  const [hideBuyButton, setHideBuyButton] = useState(false);
  const hideBuyButtonRef = useRef(hideBuyButton);

  useEffect(() => {
    hideBuyButtonRef.current = hideBuyButton;
  }, [hideBuyButton]);

  const getBodyScrollTop = () => window.pageYOffset
    || (document.documentElement && document.documentElement.scrollTop)
    || (document.body && document.body.scrollTop);

  useEffect(() => {
    if (typeof window === 'undefined') return undefined;

    buyButtonRef.current.onclick = () => {
      setOrderingReport(report);
    };

    const scroll = () => {
      const viewportHeight = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
      );

      const scrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight,
      );

      if (
        getBodyScrollTop() >= scrollHeight - viewportHeight - FOOTER_HEIGHT
        && !hideBuyButtonRef.current
      ) {
        setHideBuyButton(true);
      } else if (
        getBodyScrollTop() < scrollHeight - viewportHeight - FOOTER_HEIGHT
        && hideBuyButtonRef.current
        && scrollHeight > HEADER_HEIGHT
      ) {
        setHideBuyButton(false);
      }
    };

    window.addEventListener('scroll', scroll);

    const onResize = () => setScreenMd(window.innerWidth < 1024);
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  const renderPrice = () => {
    if (report.status === 'pre_order') return report.pre_order_price;
    if (report.status === 'active' && report.active_price) return report.active_price;
    return 0;
  };

  return (
    <>
      {!screenMd && <PageReportHeaderNew report={report} gradient={gradient} />}
      <div className={classes.report}>
        {report.description && (
          <div className={classes.report__description}>
            {report.description}
          </div>
        )}
        <div
          className={classes.report__content}
          dangerouslySetInnerHTML={{ __html: report.content }}
        />
        {/* <div style={{
          position: 'sticky', bottom: 40, marginTop: '-80px', textAlign: 'center',
        }}
        > */}
        <BuyButton
          ref={buyButtonRef}
          hide={hideBuyButton || !screenMd}
          price={renderPrice()}
          caption={report.caption}
          // style={{
          //   width: '100%', maxWidth: '290px', bottom: 40, marginTop: '-80px',
          // marginLeft: 'auto', marginRight: 'auto',
          // }}
          style={{
            position: 'fixed',
            bottom: 30,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
        {/* </div> */}
      </div>
    </>
  );
};

ReportContent.displayName = 'ReportContent';
export default ReportContent;
