import { MutableRefObject, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router';

export const useViewedGa = () => {
  const match = useRouteMatch();
  const { path, url } = match;

  useEffect(() => {
    const paths = ['/post/:slug', '/super/:slug', '/longread/:slug'];
    if (paths.includes(path)) {
      const viewedPosts = (window as any).viewedPosts || [];
      if (!viewedPosts.includes(url)) {
        viewedPosts.push(url);
      }
      if (typeof window === 'undefined' || typeof (window as any).dataLayer === 'undefined') {
        console.log('Window object not find');
        return;
      }
      (window as any).viewedPosts = viewedPosts;
      const len = (window as any).viewedPosts.length;
      if (len === 2) {
        (window as any).dataLayer.push({
          event: 'sp_event',
          eventCategory: 'view_pages',
          eventAction: 'two_and_more',
        });
      } else if (len === 4) {
        (window as any).dataLayer.push({
          event: 'sp_event',
          eventCategory: 'view_pages',
          eventAction: 'four_and_more',
        });
      }
    }
  }, [path, url]);
};

export const useScrolledGa = (ref: MutableRefObject<HTMLDivElement>) => {
  const { url } = useRouteMatch();
  const start = useRef(0);
  const gaStatus = useRef({});

  const gaEvent = (proc: ('50%' | '75%')) => {
    const status = gaStatus.current[proc];
    if (!status) {
      gaStatus.current[proc] = 'fetched';
      if (typeof window !== 'undefined' && typeof (window as any).dataLayer !== 'undefined') {
        (window as any).dataLayer.push({
          event: 'sp_event',
          eventCategory: 'scroll_depth',
          eventAction: proc,
        });
      }
    }
  };

  const onScroll = () => {
    if (ref?.current) {
      const height = ref.current.clientHeight;
      const passed = window.pageYOffset - start.current;
      const proc = passed / (height / 100);

      if (proc >= 50) {
        gaEvent('50%');
      }
      if (proc >= 75) {
        gaEvent('75%');
      }
    }
  };

  useEffect(() => {
    start.current = window.pageYOffset;
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [url]);
};
