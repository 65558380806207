import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { NavLink } from 'react-router-dom';

import useConfig from '~/resources/useConfig';
import Title from '~/components/typography/Title';
import Paragraph from '~/components/typography/Paragraph';
import ArrowRightIcon from '~/components/icons/ArrowRight';
import Logo from '~/components/icons/Logo';
import CloseIcon from '~/components/icons/Close';
import SearchIcon from '~/components/icons/Search';
import StarIcon from '~/components/icons/Star';
import SubscriptionForm from '~/components/Subscription/SubscriptionForm';
import { MailingStatus } from '~/resources/useMailing';
import clsx from 'clsx';
import { SubscriptionMessage } from '~/components';
import GuideContents from '~/components/page/guideSidebar/GuideContents';
import useWindowDimensions from '~/utils/useWindowDimensions';
import { SCREEN_MD } from '~/utils/breakpoints';
import AdminPage from '~/components/page/AdminPage';
import ProfilePage from '~/components/page/ProfilePage';
import LoginPage from '~/components/page/LoginPage';
import { usePageContext } from '.';
import c from './MenuPage.sass';
import SubscribePage from './SubscribePage';
import { FetchStatus } from '~/resources';

type TConditions = {
  isAdmin: boolean;
  isSubscribed: boolean;
  isGuideContentsVisible: boolean;
}
const MenuPage: FC = () => {
  const { data, fetchBy } = useConfig();
  const { profile, profileStatus } = usePageContext();
  const { width: windowWidth } = useWindowDimensions();

  const [mailingStatus, setMailingStatus] = useState<MailingStatus>();

  const banner = data?.banner;
  const topics = data ? data.topics : [];
  // const [hide, setHide] = usePageContext().hideMenu;
  // const { guideContents } = usePageContext();
  const { hideMenu, guideContents } = usePageContext();
  const [hide, setHide] = hideMenu || [false, () => {}];

  const isGuideContentsExist = useMemo<boolean>(() => {
    return !!guideContents && !!guideContents[0]
    && !!guideContents[0].contents && !!guideContents[0].contents.length;
  }, [profile]);

  const isSidebarVisible = windowWidth >= SCREEN_MD;

  const {isAdmin, isSubscribed, isGuideContentsVisible} = useMemo<TConditions>(() => {
    const isAdmin = !!(profile && profile[0] && profile[0].is_admin);
    const isSubscribed = !!(profile && profile[0] && profile[0].is_subscribed);
    const isGuideContentsVisible = isGuideContentsExist
      && isSidebarVisible
      && (isSubscribed || isAdmin);
    return {
      isAdmin,
      isSubscribed,
      isGuideContentsVisible
    };
  }, [profile, isGuideContentsExist]);

  const status = profileStatus?.[0] ?? undefined;
  const isLoading = !status || status === FetchStatus.Fetching;

  const handleCloseSidebar = () => {
    setHide(true);
  };

  useEffect(() => {
    fetchBy();
  }, []);

  const handleChangeMailingStatus = (newMailingStatus: MailingStatus) => {
    setMailingStatus(newMailingStatus);
  };

  const renderTopics = useMemo<JSX.Element[]>(() => {
   return topics && topics.map((topic) => (
    <li key={topic.id}>
      <NavLink
        exact
        to={`/topic/${topic.slug}`}
        className={c.menu_link}
        activeClassName={c.menu_link_active}
        onClick={handleCloseSidebar}
      >
        <Paragraph weight="bold">{topic.title}</Paragraph>
      </NavLink>
    </li>
  ))
  }, [topics]);

  return (
    <div className={`${c.menu} ${c[`menu-hide-${hide}`]} ${banner && !isSubscribed ? c.menu__with_banner : ''}`}>
      <div className={c.menu_wrap}>
        <div className={c.menu_logo_wrapper}>
          <NavLink to="/" onClick={handleCloseSidebar} aria-label="На главную">
            <Logo />
          </NavLink>
          {!hide && <button type="button" className={c.close_button} onClick={handleCloseSidebar}><CloseIcon /></button>}
        </div>
        {isGuideContentsVisible 
          ? <GuideContents /> 
          : (
          <nav className={c.menu_list}>
            <ul className={c.menu_topics}>
              <li className={c.menu_search_container}>
                <NavLink
                  to="/search"
                  className={c.menu_link}
                  activeClassName={c.menu_link_active}
                  onClick={handleCloseSidebar}
                >
                  <SearchIcon />
                  <Paragraph weight="bold">Поиск</Paragraph>
                </NavLink>
                <div className={c.top_divider} />
              </li>
              {renderTopics}
              <SubscribePage
                onClick={handleCloseSidebar}
                isAdmin={isAdmin}
                isSubscribed={isSubscribed}
                isLoading={isLoading}
              />
              <AdminPage isAdmin={isAdmin}/>
            </ul>
          </nav>
        )}
      </div>
      <div>
        <div className={c.bottom_links_container}>
          <NavLink
            exact
            to="/about"
            className={c.menu_link}
            activeClassName={c.menu_link_active}
            onClick={handleCloseSidebar}
          >
            <Paragraph weight="bold">О проекте</Paragraph>
          </NavLink>
        </div>
        <div className={c.menu_email_subscribe_wrapper}>
          <div className={c.menu_search_container}>
            <NavLink
              exact
              to="/about"
              className={c.menu_link}
              activeClassName={c.menu_link_active}
              onClick={handleCloseSidebar}
            >
              <Paragraph weight="bold">О&nbsp;проекте</Paragraph>
            </NavLink>
          </div>
          {!isSubscribed && (
            <div className={c.reminder_subscription_container}>
              <NavLink
                to="/subscription"
                className={c.reminder_subscription_container_link}
                onClick={handleCloseSidebar}
              >
                <Title level={4}>Подписка Reminder</Title>
                <StarIcon />
              </NavLink>
            </div>
          )}
          <div className={c.login_section}>
            <ProfilePage />
            <LoginPage />
          </div>
          <div className={clsx(c.top_divider, c.top_divider__only_mobile)} />
          <div className={c.menu_email_subscribe_wrapper_inner}>
            <Title level={4}>Рассылка от редакции</Title>
            {!mailingStatus ? (
              <SubscriptionForm
                formClassName={c.email_subscription_container}
                inputClassName={c.email_text_field}
                buttonClassName={c.email_submit_button}
                inputPlaceholder="Ваш Email"
                onChangeStatus={handleChangeMailingStatus}
              >
                <ArrowRightIcon />
              </SubscriptionForm>
            ) : <SubscriptionMessage status={mailingStatus} />}
          </div>
        </div>
      </div>
    </div>
  );
};

MenuPage.displayName = 'MenuPage';
export default MenuPage;
