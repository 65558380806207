import React, { FC } from 'react';

import { Page } from '~/components';
import ServiceText from '~/components/ServiceText';
import c from '~/components/post/ContentPost.sass';
import Helmeter from '~/screens/Helmeter';
import config from '~/config';
import Banner from '~/components/Banner';

export const PublicOfferScreen: FC = () => {
  const canonicalLink = `${config('APP_HOST')}/public_offer`;

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title="Публичная оферта — Reminder"
          metaTitle="Публичная оферта — Reminder"
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <div className={c.container}>
          <div className={c.wrap}>
            <ServiceText name="public_offer" />
          </div>
        </div>
      </Page>
    </>
  );
};

PublicOfferScreen.displayName = 'PublicOfferScreen';
export default PublicOfferScreen;
