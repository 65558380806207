import React, { FC } from 'react';

const TelegramBenefitIcon: FC = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icons/Telegram" clipPath="url(#clip0_6134_3303)">
      <path id="Vector 11" d="M3 26.2051L9.91525 38H37L24.322 18L3 26.2051Z" fill="#F85B2E" />
      <path id="Vector 12 (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M7.23438 9.69468C8.6962 7.39753 13.1984 3.81836 19.9742 6.07697C23.7207 7.32579 25.2326 10.5223 25.6723 13.6004C26.1082 16.652 25.5604 19.8815 24.9349 21.9367L22.0649 21.0632C22.606 19.2851 23.0582 16.5146 22.7024 14.0246C22.3505 11.561 21.2791 9.67419 19.0255 8.92302C13.8013 7.18162 10.6369 9.93579 9.76536 11.3053L7.23438 9.69468Z" fill="#25D3FF" />
      <path id="Vector 13" d="M12.5 29.5L18.5 39.5L25.5 26L12.5 29.5Z" fill="#25D3FF" />
    </g>
    <defs>
      <clipPath id="clip0_6134_3303">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

TelegramBenefitIcon.displayName = 'TelegramBenefitIcon';
export default TelegramBenefitIcon;
