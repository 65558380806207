import { match as Match } from 'react-router';
import * as utils from '~/utils';

import queryString from 'query-string';
import useResource, {
  ResourceResult, Resource, QueryParams,
} from './useResource';
import { Post } from './models';
import config from '../config';
import anyFetch from './anyFetch';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}/posts`;

export const postFetcher = (slug: string, auth?: Auth) => async (
  params: QueryParams = {},
  opts: RequestInit = {},
): Promise<Resource<Post>> => {
  let path = `${PATH()}/${slug}`;
  const paramsStr = queryString.stringify(params, {
    arrayFormat: 'bracket',
  });

  if (paramsStr) {
    path += `?${paramsStr}`;
  }
  const res = await anyFetch(path, opts);
  const data = await res.json();
  if (auth && data.icon) {
    auth?.setCache('postToken', data.icon);
  }
  return data;
};

const generateCacheKey = (slug: string) => `posts/${slug}`;

const usePost = (
  slug: string,
  auth?: Auth,
): ResourceResult<Post> =>
  useResource({}, {}, postFetcher(slug, auth), generateCacheKey(slug));

export default usePost;

export const fetchSSRPost = async (
  auth: Auth,
  path: string,
  match: Match<{ slug: string}>,
  opts: RequestInit = {},
): Promise<any> => {
  const { slug } = match.params;
  const key = new URLSearchParams(
    utils.getSearch(`${config('APP_HOST')}${path}`),
  ).get('key') || null;
  if (slug) {
    const res = await postFetcher(slug, auth)(key ? { key } : {}, opts);
    const { id } = res.data;
    auth.setCache(generateCacheKey(slug), res);
    return id;
  }
};
