import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import {
  SearchInput, PostSearch, Page, Loader, Paginator,
} from '~/components';
import useSearch from '~/resources/useSearch';
import { FetchStatus, ResourceMeta } from '~/resources';
import config from '~/config';
import Banner from '~/components/Banner';
import Helmeter from './Helmeter';

import c from './SearchScreen.sass';

const useQueryUrl = () => {
  const { pathname, search } = useLocation();
  try {
    const url = new URL(`${config('APP_HOST')}${pathname}${search}`);
    return url.searchParams.get('search') || '';
  } catch (e) {
    return '';
  }
};

type TProps = {
  page: number,
  search: string,
  onMeta: (meta: ResourceMeta) => void,
  onLoading: (loading: boolean) => void,
}

const List: FC<TProps> = ({
  page, search, onMeta, onLoading,
}) => {
  const {
    meta, status, fetchBy, data: posts,
  } = useSearch({ page: String(page), search });
  useEffect(() => {
    if (search) {
      fetchBy();
    }
  }, [page, search]);
  useEffect(() => {
    if (meta) {
      onMeta(meta);
    }
  }, [meta]);
  useEffect(() => {
    if (search) {
      onLoading(status === FetchStatus.Fetching);
    }
  }, [status === FetchStatus.Fetching]);

  if (!posts) {
    return page > 1 ? <Loader /> : null;
  }

  return (
    <>
      {posts.map((post) => (
        <PostSearch key={post.id} post={post} />
      ))}
    </>
  );
};

const SearchScreen: FC = () => {
  const queryInit = useQueryUrl();
  const [search, setSearch] = useState(queryInit);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<ResourceMeta>();

  return (
    <>
      <Banner />
      <Page dark>
        <Helmeter
          title="Поиск — Reminder"
          metaTitle="Поиск — Reminder"
        />
        <div className={c.search_screen}>
          <SearchInput
            loading={loading}
            length={meta ? meta.total : 0}
            setSearch={setSearch}
            queryInit={queryInit}
          />
          {search && (
            <Paginator onMeta={setMeta} onLoading={setLoading}>
              {(page, handleMeta, handleLoading) => (
                <List
                  page={page}
                  search={search}
                  onMeta={handleMeta}
                  onLoading={handleLoading}
                />
              )}
            </Paginator>
          )}
        </div>
      </Page>
    </>
  );
};

SearchScreen.displayName = 'SearchScreen';
export default SearchScreen;
