import useResource, { ResourceResult, Resource } from './useResource';
import { Profile } from './models';

import config from '../config';
import anyFetch from './anyFetch';

const PATH = () => `${config('API_HOST')}/profile`;

type QueryParams = {
    [key: string]: string | string[]
};

const profileFetcher = async (params = {}, opts = {}): Promise<Resource<Profile>> => {
  const res = await anyFetch(PATH(), opts);
  const data = await res.json();
  return data;
};

const useProfile = (): ResourceResult<Profile> =>
  useResource({}, {}, profileFetcher, false);

export default useProfile;
