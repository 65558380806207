import React, { Dispatch, FC, SetStateAction } from 'react';
import Modal from 'react-modal';
import c from '../../Reports/Form.sass';

interface TProps {
  onSubmit: Dispatch<SetStateAction<void>>
  open: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
}

const UnsubscribeConfirmModal: FC<TProps> = ({
  onSubmit,
  open,
  setOpenModal,
}) => (
  <Modal
    isOpen={open}
    ariaHideApp={false}
    overlayClassName={c.modal}
    shouldCloseOnOverlayClick={false}
    className={c.modal__content}
    style={{ overlay: { zIndex: 10000 } }}
  >
    <div className={c.form}>
      <div className={c.form__content}>
        <h1 className={`${c.form__title} ${c.form__title_ok}`}>
          <span>Вы уверены что хотите отменить подписку?</span>
        </h1>
        {/* <h2 className={c.form__subTitle}>{states[key].subtitle}</h2> */}
      </div>
      <div className={`${c.form__button_block}`}>
        <button
          className={`${c.form__submit} ${c.form__submit_ok} ${c.form__submit_red}`}
          onClick={() => {
            setOpenModal(false);
            onSubmit();
          }}
          type="button"
        >
          Отписаться
        </button>
        <button
          className={`${c.form__submit} ${c.form__submit_ok}`}
          onClick={() => setOpenModal(false)}
          type="button"
        >
          Закрыть
        </button>
      </div>
    </div>
  </Modal>
);

UnsubscribeConfirmModal.displayName = 'UnsubscribeConfirmModal';
export default UnsubscribeConfirmModal;
