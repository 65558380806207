import React, { FC } from 'react';

const ExtendedLinkIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.7173 16.073L14.6154 10.175V14.4H15.75V8.25H9.6V9.38458H13.8L7.92693 15.2577L8.7173 16.073ZM12.0016 21.5C10.698 21.5 9.46966 21.2506 8.31673 20.752C7.16379 20.2533 6.15555 19.5724 5.292 18.7092C4.42843 17.846 3.74721 16.8382 3.24833 15.6858C2.74944 14.5334 2.5 13.3053 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42758 6.13472 5.29075 5.2795C6.15393 4.42427 7.16173 3.74721 8.31415 3.24833C9.46658 2.74944 10.6946 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.302 21.2506 14.5303 20.752 15.6832C20.2533 16.8362 19.5765 17.8444 18.7217 18.708C17.8669 19.5715 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5Z"
        fill="black"
      />
    </svg>
  );
}

ExtendedLinkIcon.displayName = 'ExtendedLinkIcon';
export default ExtendedLinkIcon;
