import React, { FC } from 'react';
import { MiniBookLink } from '~/resources/models';
import c from './MiniBooksList.sass';

interface TProps {
  onClick: (slug: string, fileName: string) => void;
  books: MiniBookLink[] | null;
}

const MiniBooksList: FC<TProps> = ({ onClick, books }) => {
  if (!books || books.length < 1) return null;

  return (
    <>
      <div className={c.title}>Список мини-книг</div>
      <div className={c.wrapper}>
        { books.map((book) => (
          <div key={`book_${book.slug}`}>
            <button
              type="button"
              className={c.item}
              onClick={() => onClick(book.slug, book.title)}
            >
              { book.title }
            </button>
          </div>
        )) }
      </div>
    </>
  );
};

MiniBooksList.displayName = 'MiniBooksList';
export default MiniBooksList;
