import { useLocation } from 'react-router-dom';
import config from '~/config';

const useAnalytics = (): void => {
  const { pathname } = useLocation();
  if (typeof window !== 'undefined' && typeof (window as any).gtag !== 'undefined') {
    (window as any).gtag('event', 'page_view', {
      page_location: config('APP_HOST') + pathname,
      page_path: pathname,
      page_title: '',
    });
  }
};

export default useAnalytics;
