import React, { FC } from 'react';

const TwoIcon: FC = () => (
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.66699" y="1" width="38" height="38" rx="19" fill="white" />
    <rect x="1.66699" y="1" width="38" height="38" rx="19" stroke="#F85B2E" strokeWidth="2" />
    <path d="M23.3705 17.52C23.3705 16.32 22.5305 15.36 20.8505 15.36C19.1105 15.36 17.9905 16.38 17.6305 18.04L15.2705 17.6C15.6905 15.02 17.6505 13.2 20.8705 13.2C24.0505 13.2 25.8905 14.9 25.8905 17.48C25.8905 19.8 24.3305 21.1 22.0705 22.64L19.0305 24.72H26.0505V27H15.4905V24.68L20.4505 21.22C22.3105 19.92 23.3705 19.08 23.3705 17.52Z" fill="#F85B2E" />
  </svg>
);

TwoIcon.displayName = 'TwoIcon';
export default TwoIcon;
