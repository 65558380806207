import React, { FC } from 'react';

const ThreeIcon: FC = () => (
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.33301" y="1" width="38" height="38" rx="19" fill="white" />
    <rect x="1.33301" y="1" width="38" height="38" rx="19" stroke="#F85B2E" strokeWidth="2" />
    <path d="M20.211 27.2C17.071 27.2 15.151 25.98 14.591 23.34L16.991 22.86C17.331 24.36 18.451 25.04 20.271 25.04C22.311 25.04 23.391 24.2 23.391 22.78C23.391 21.36 22.391 20.64 20.431 20.64C19.811 20.64 19.111 20.72 18.611 20.84L17.991 19.1L22.611 15.6H15.271V13.4H25.271V15.92L21.331 18.86H21.391C24.091 18.86 25.851 20.14 25.851 22.74C25.851 25.56 23.671 27.2 20.211 27.2Z" fill="#F85B2E" />
  </svg>
);

ThreeIcon.displayName = 'ThreeIcon';
export default ThreeIcon;
