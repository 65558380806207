import React, { FC, useMemo } from 'react';

import clsx from 'clsx';
import c from './ItemList.sass';

interface TProps {
  items: Array<FC | React.ReactNode>;
  className?: string;
  noDividersIdx?: number[];
}

const ItemList: FC<TProps> = ({
  items, className, noDividersIdx,
}) => {
  const getClass = () => (className ? ` ${c[className]}` : '');

  const renderItems = useMemo<JSX.Element[]>(() => {
    return items.map((item, index) => (
      <div
        key={index}
        className={clsx({
          [c.no_divider]: noDividersIdx?.includes(index),
        })}
      >
        {item}
      </div>
    ))
  }, [items]);

  return (
    <div className={c.container + getClass()}>
      {renderItems}
    </div>
  );
};

ItemList.defaultProps = {
  className: '',
};

ItemList.displayName = 'ItemList';
export default ItemList;
