import React, { FC } from 'react';

const CloseIcon: FC = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="11.2649"
      y1="5.64995"
      x2="5.61023"
      y2="11.3046"
      stroke="white"
      strokeWidth="2.89405"
      strokeLinecap="round"
    />
    <line
      x1="11.0285"
      y1="11.3046"
      x2="5.37382"
      y2="5.64993"
      stroke="white"
      strokeWidth="2.89405"
      strokeLinecap="round"
    />
  </svg>
);

CloseIcon.displayName = 'CloseIcon';
export default CloseIcon;
