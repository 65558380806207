import queryString from 'query-string';

import useResource, { ResourceResult, Resource, QueryParams } from './useResource';
import { Post } from './models';
import config from '../config';
import anyFetch from './anyFetch';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}/recommendations`;

const recommendsFetcher = async (params: QueryParams = {}): Promise<Resource<Post[]>> => {
  let path = PATH();
  const paramsStr = queryString.stringify(params, {
    arrayFormat: 'bracket',
  });
  if (paramsStr) {
    path += `?${paramsStr}`;
  }
  const res = await anyFetch(path);
  const data = await res.json();
  return data;
};

const generateCacheKey = (params: QueryParams = {}) =>
  `recommendations${JSON.stringify(params)}`;

const useRecommends = (params: QueryParams = {}): ResourceResult<Post[]> =>
  useResource(params, {}, recommendsFetcher, generateCacheKey(params));

export default useRecommends;

export const fetchSSRRecommends = async (
  auth: Auth,
  postId: number,
): Promise<any> => {
  const res = await recommendsFetcher({ post_id: String(postId) });
  auth.setCache(generateCacheKey({ post_id: String(postId) }), res);
};
