import { useReducer } from 'react';
import { usePageContext } from '~/components';

export type ResourceMeta = {
  current_page: number,
  from: number,
  last_page: number,
  path: string,
  per_page: number,
  to: number,
  total: number,
};

export enum FetchStatus {
  Idle,
  Fetching,
  Fetched,
  Error,
}

export type ResourceState<T> = {
  status: FetchStatus,
  error: string,
  data: T | undefined,
  meta: ResourceMeta | undefined,
};

type FetchBy = (
  clearCache?: boolean,
  params?: QueryParams,
  opts?: RequestInit,
  cacheKey?: false | string,
) => () => void;

export type ResourceResult<T> = ResourceState<T> & {
  fetchBy: FetchBy
};

enum ActionType {
  Nothing,
  Fetching,
  Refetching,
  Fetched,
  FetchError
}

type Action<T> = {
  type: ActionType,
  payload?: Resource<T>,
  error?: string,
}

export type Resource<T> = {
  data: T,
  meta: ResourceMeta,
  error?: string,
};

type CacheStore<T> = {
  [key: string]: T,
}

export type QueryParams = {
  [key: string]: string | string[]
};

export type Fetcher<T> = (params?: QueryParams, opts?: RequestInit) => Promise<Resource<T>>;

export default function useResource<T>(
  params: QueryParams,
  opts: RequestInit,
  fetcher: Fetcher<T>,
  cacheKey: string | false,
): ResourceResult<T> {
  const { auth } = usePageContext();
  const initialState: ResourceState<T> = {
    status: FetchStatus.Idle,
    error: '',
    data: undefined,
    meta: undefined,
  };

  // Если передан ключ для кеша и кеш не пустой, стейт сразу заполняем
  let currentState = { ...initialState };
  if (cacheKey && auth.DATA[cacheKey]) {
    currentState = {
      ...initialState,
      status: FetchStatus.Fetched,
      data: auth.DATA[cacheKey].data,
      meta: auth.DATA[cacheKey].meta,
    };
  }
  if (cacheKey && auth.promises[cacheKey]) {
    currentState = {
      ...initialState,
      status: FetchStatus.Fetching,
    };
  }

  const [state, dispatch] = useReducer((prevState: ResourceState<T>, action: Action<T>) => {
    switch (action.type) {
      case ActionType.Nothing:
        return initialState;
      case ActionType.Fetching:
        return { ...initialState, status: FetchStatus.Fetching };
      case ActionType.Refetching:
        return { ...prevState, status: FetchStatus.Fetching };
      case ActionType.Fetched:
        return {
          ...initialState,
          status: FetchStatus.Fetched,
          data: action.payload?.data,
          meta: action.payload?.meta,
        };
      case ActionType.FetchError:
        return {
          ...initialState,
          status: FetchStatus.Error,
          error: action.error,
        } as ResourceState<T>;
      default:
        return prevState;
    }
  }, currentState);

  const fetchBy: FetchBy = (clearCache = false) => {
    let cancelRequest = false;
    const controller = new AbortController();
    const { signal } = controller;
    const cleanup = () => {
      cancelRequest = true;
      controller.abort();
      if (cacheKey && auth.promises[cacheKey]) {
        delete auth.promises[cacheKey];
      }
    };

    const fetchData = async () => {
      try {
        const credentials = auth ? auth.AUTH : null;
        const data = await fetcher(params, { ...opts, credentials, signal });
        if (cacheKey) {
          auth.DATA[cacheKey] = data;
          delete auth.promises[cacheKey];
        }
        if (cancelRequest) {
          return;
        }
        dispatch({ type: ActionType.Fetched, payload: data });
      } catch (error) {
        if (cancelRequest) {
          return;
        }
        dispatch({ type: ActionType.FetchError, error: error.message });
      }
    };

    if (cacheKey && auth.DATA[cacheKey] && !clearCache) {
      dispatch({ type: ActionType.Fetched, payload: auth.DATA[cacheKey] });
    } else {
      if (cacheKey && auth.promises[cacheKey]) {
        auth.promises[cacheKey]
          .then(() => {
            if (auth.DATA[cacheKey]) {
              dispatch({ type: ActionType.Fetched, payload: auth.DATA[cacheKey] });
            }
          });
        return cleanup;
      }
      dispatch({ type: clearCache ? ActionType.Refetching : ActionType.Fetching });
      const promise = fetchData();
      if (cacheKey) {
        auth.promises[cacheKey] = promise;
      }
    }

    return cleanup;
  };

  return { ...state, fetchBy };
}
