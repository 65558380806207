import React, { FC } from 'react';

const StarIcon: FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.89781 16.6154L8.0646 13.3755L3.50059 12.5326C2.70851 12.3992 2.38281 12.1774 2.38281 11.6448C2.38281 11.4674 2.42945 11.2897 2.52234 11.0678L3.64012 7.7394C3.82629 7.16275 4.05986 6.89646 4.47846 6.89646C4.71164 6.89646 4.99071 6.98498 5.36343 7.16275L9.60135 9.02676L9.04246 4.58876C8.99621 4.4558 8.99621 4.32247 8.99621 4.14507C8.99621 3.47917 9.27565 3.25732 10.1602 3.25732H13.8397C14.6314 3.25732 15.0041 3.52361 15.0041 4.18915L14.9571 4.58876L14.3985 9.02676L18.5898 7.16275C18.9625 6.98498 19.2416 6.89646 19.4748 6.89646C19.8471 6.89646 20.1266 7.16275 20.3127 7.7394L21.4772 11.0678C21.5704 11.2897 21.6167 11.4674 21.6167 11.6448C21.6167 12.1774 21.291 12.3992 20.4993 12.5326L15.8887 13.3755L19.1021 16.6154C19.4282 16.9261 19.6143 17.2369 19.6143 17.5032C19.6143 17.8135 19.3815 18.1242 18.9625 18.3905L15.9819 20.4319C15.6559 20.6538 15.4227 20.7427 15.1902 20.7427C14.8175 20.7427 14.5847 20.5208 14.2586 19.9883L11.9768 16.0828L9.74126 19.9883C9.4148 20.4764 9.13574 20.7427 8.80965 20.7427C8.57685 20.7427 8.2974 20.6538 7.97132 20.4319L5.03734 18.3905C4.61837 18.0798 4.38518 17.8135 4.38518 17.5032C4.38518 17.2369 4.57135 16.9261 4.89781 16.6154Z"
        fill="currentColor"
      />
    </svg>
  );
}

StarIcon.displayName = 'StarIcon';
export default StarIcon;
