import React, { FC } from 'react';

const ArrowRightIcon: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5384 14.6779L10.859 14.0192L14.4391 10.4391H3.75V9.49361H14.4391L10.8461 5.90065L11.5256 5.242L16.25 9.96636L11.5384 14.6779Z"
        fill="black"
      />
    </svg>
  );
}

ArrowRightIcon.displayName = 'ArrowRightIcon';
export default ArrowRightIcon;
