import anyFetch from '~/resources/anyFetch';
import config from '~/config';
import { ymReachGoal } from '~/utils/ym';

interface bodyProps {
  token: string;
}

const PATH = `${config('API_HOST')}/postviews`;

export const increasePostViewFetcher = async (body: bodyProps): Promise<any> => {
  ymReachGoal('post_viewed');
  const res = await anyFetch(
    PATH,
    { method: 'POST', body: JSON.stringify(body) },
  );
  const data = await res.json();
  return data;
};

export const updatePostViewFetcher = async (slug: string): Promise<any> => {
  const res = await anyFetch(
    `${PATH}?slug=${slug}`,
    { method: 'GET' },
  );
  const data = await res.json();
  return data;
};

export default increasePostViewFetcher;
