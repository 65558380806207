import { useEffect, useRef, useState } from 'react';

const SCROLL_X_STEP = 560;

export default function useCarousel() {
  const ref = useRef<HTMLDivElement>(null);

  const minScrollPosition = 0;
  const maxScrollPosition = ref.current ? ref.current.scrollWidth - ref.current.clientWidth : 0;

  const [, setMounted] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleScroll = () => {
    if (!ref.current) return;

    setScrollPosition(ref.current.scrollLeft);
  };

  const isLeftDisabled = scrollPosition === minScrollPosition;
  const isRightDisabled = Math.abs(scrollPosition - maxScrollPosition) <= 2;

  const handleChange = (direction: 'left' | 'right') => {
    if (!ref.current) return;

    const newPositionLeft = direction === 'left'
      ? scrollPosition - SCROLL_X_STEP
      : scrollPosition + SCROLL_X_STEP;

    ref.current.scrollTo({ left: newPositionLeft, behavior: 'smooth' });
  };

  return {
    ref, isLeftDisabled, isRightDisabled, handleScroll, handleChange,
  };
}
