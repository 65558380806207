import React, { FC } from 'react';

import c from './MenuSoc.sass';

const MenuSoc: FC = () => (
  <div className={c.mobile}>
    {/* <a
      className={c.link}
      href="https://m.facebook.com/justakindreminder"
      rel="noreferrer"
      target="_blank"
    >
      <span>Facebook</span>
    </a> */}
    <a
      className={c.link}
      href="https://t.me/justakindreminder"
      rel="noreferrer"
      target="_blank"
    >
      <span>Telegram</span>
    </a>
  </div>
);

MenuSoc.displayName = 'MenuSoc';
export default MenuSoc;
