import React, { Dispatch, FC, SetStateAction } from 'react';

import Title from '~/components/typography/Title';
import clsx from 'clsx';
import ArrowDownIcon from '~/components/icons/ArrowDown';
import { useDebounce } from '~/utils/hooks/useDebounce';
import { usePageContext } from '~/components';
import ContentItem from '~/components/post/stickyTableOfContents/ContentItem';
import c from './StickyTableOfContents.sass';

type TProps = {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const StickyTableOfContents: FC<TProps> = ({
  isOpen, setOpen
}) => {
  const { guideContents } = usePageContext();

  const isGuideContentsExist = guideContents && guideContents[0]
    && guideContents[0].contents && guideContents[0].contents.length;

  const handleClose = () => {
    setOpen(false);
  };

  const isOpenDebounced1 = useDebounce(isOpen, 1);
  const isOpenDebounced300 = useDebounce(isOpen, 300);

  if (!isGuideContentsExist) return null;

  const contents = guideContents[0]?.contents ?? [];
  const isActive = guideContents[0]?.activeIdx ?? -1;

  return (
    <>
      {isOpen && (
        <div className={clsx(c.backdrop_fade_2, {
          [c.backdrop_fade_2_is_open]: isOpenDebounced1,
        })}
        />
      )}
      <div className={clsx(c.overlay, {
        [c.overlay_is_open]: isOpen,
        [c.overlay_is_open_z_index]: isOpen || isOpenDebounced300,
      })}
      >
        <div className={c.empty_space} onClick={handleClose}>
          <ArrowDownIcon />
        </div>
        <div className={c.sticky_table_of_contents}>
          <Title level={4} className={c.table_of_contents_title}>Оглавление</Title>
          {contents.map((content, idx) => (
            <ContentItem
              text={content}
              isActive={isActive === idx}
              idx={idx}
              key={idx}
              close={handleClose}
            />
          ))}
        </div>
      </div>
    </>
  );
}

StickyTableOfContents.displayName = 'StickyTableOfContents';
export default StickyTableOfContents;
