import { match as Match } from 'react-router';

import useResource, {
  ResourceResult, Resource,
} from './useResource';
import { Report } from './models';
import config from '../config';
import anyFetch from './anyFetch';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}/products`;

export const reportFetcher = (slug: string, key: string | null = null) => async (
  opts: RequestInit = {},
): Promise<Resource<Report>> => {
  const path = `${PATH()}/${slug}${key ? `?key=${key}` : ''}`;
  const res = await anyFetch(path, opts);
  const data = await res.json();
  return data;
};

const generateCacheKey = (slug: string) => `products/${slug}`;

const useReport = (
  slug: string, key: string | null = null,
): ResourceResult<Report> =>
  useResource({}, {}, reportFetcher(slug, key), generateCacheKey(slug));

export default useReport;

export const fetchSSRReport = async (
  auth: Auth,
  match: Match<{ slug: string }>,
  opts: RequestInit = {},
  key: string | null = null,
): Promise<any> => {
  const { slug } = match.params;
  if (slug) {
    const res = await reportFetcher(slug, key)(opts);
    auth.setCache(generateCacheKey(slug), res);
  }
};
