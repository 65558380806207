import useResource, { ResourceResult, Resource, Fetcher } from './useResource';
import { TAuthor } from './models';
import anyFetch from './anyFetch';
import { match as Match, useHistory } from 'react-router';
import config from '../config';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}/authors`;

type QueryParams = {
    [key: string]: string | string[]
};

export const authorFetcher = (authorId: string) => async (
    params: QueryParams = {},
    opts: RequestInit = {},
  ): Promise<Resource<TAuthor>> => {
    let path = `${PATH()}/${authorId}`;
    const res = await anyFetch(path, opts);
    const data = await res.json();
    return data as Resource<TAuthor>;
  };

const useAuthor = (
    authorId: string,
  ): ResourceResult<TAuthor> =>
    useResource({}, {}, authorFetcher(authorId), `authors/${authorId}`);
  

export default useAuthor;

export const fetchSSRAuthor = async (
    auth: Auth,
    match: Match<{ authorId: string}>,
    opts: RequestInit = {}
): Promise<any> => {
  const { authorId } = match.params;
  if (authorId) {
    const res = await authorFetcher(authorId)({}, opts);

    if (!res.data) {
        return null;
    }

    const { id } = res.data;
    auth.setCache(`authors/${authorId}`, res);
    return id;
  }
};
