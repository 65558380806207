import React, { FC } from 'react';

const ArrowIcon: FC = () => (
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.89424 8.10567C9.28476 7.71514 9.28476 7.08198 8.89424 6.69145L2.53028 0.327492C2.13975 -0.0630326 1.50659 -0.0630326 1.11606 0.327492C0.725537 0.718016 0.725537 1.35118 1.11606 1.74171L6.77292 7.39856L1.11606 13.0554C0.725537 13.4459 0.725537 14.0791 1.11606 14.4696C1.50659 14.8602 2.13975 14.8602 2.53028 14.4696L8.89424 8.10567ZM7.72363 8.39856H8.18713V6.39856H7.72363V8.39856Z"
      fill="black"
    />
  </svg>
);

ArrowIcon.displayName = 'ArrowIcon';
export default ArrowIcon;
