export type TSubscriptionFeature = {
  title: string;
  subtitle: string;
  description: string;
}

export const subscriptionFeaturesList: TSubscriptionFeature[] = [
  {
    title: '1000+',
    subtitle: 'статей в журнале',
    description: 'Вдохновляющие эссе. Интервью. Личный опыт. Разбор научных исследований.',
  },
  {
    title: 'Библиотека гайдов',
    subtitle: '+1 каждый месяц',
    description: 'Мы прочитали все нужные книги и исследования, послушали подкасты и поговорили с экспертами. Вам остается: взять и внедрить рекомендации в жизнь.',
  },
  {
    title: 'Закрытое сообщество',
    subtitle: '1000+ человек',
    description: 'Проводим встречи с известными учеными, предпринимателями, врачами, исследователями и мыслителями. В закрытом канале пробуем новые практики, делимся результатами и общаемся.',
  },
  {
    title: 'Рассылка от редакции',
    subtitle: 'Полная версия',
    description: 'Внутри — все, что заинтересовало нас в течение недели: новости науки, лучшие статьи и блоги, сервисы и приложения. Каждый вторник у вас в почте.',
  },
];
