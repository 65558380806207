import React, { FC } from 'react';

const RssIcon: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.26324 17.0833C3.89296 17.0833 3.57602 16.9515 3.31241 16.6879C3.0488 16.4243 2.91699 16.1074 2.91699 15.7371C2.91699 15.3669 3.0488 15.0501 3.31241 14.7865C3.57602 14.5228 3.89296 14.391 4.26324 14.391C4.63338 14.391 4.95026 14.5228 5.21387 14.7865C5.47748 15.0501 5.60928 15.3669 5.60928 15.7371C5.60928 16.1074 5.47748 16.4243 5.21387 16.6879C4.95026 16.9515 4.63338 17.0833 4.26324 17.0833ZM14.3914 17.0833C14.3914 15.485 14.0909 13.9926 13.4899 12.6063C12.8888 11.2197 12.0691 10.0074 11.031 8.96917C9.99289 7.93111 8.7806 7.11153 7.39408 6.51042C6.00755 5.90944 4.51519 5.60896 2.91699 5.60896V3.75C4.77171 3.75 6.5038 4.09806 8.11324 4.79417C9.72283 5.49014 11.1334 6.44389 12.3449 7.65542C13.5564 8.86694 14.5102 10.2769 15.2062 11.8854C15.9023 13.4938 16.2503 15.2264 16.2503 17.0833H14.3914ZM9.58366 17.0833C9.58366 16.1528 9.41005 15.2882 9.06283 14.4896C8.7156 13.691 8.23644 12.9861 7.62533 12.375C7.01421 11.7639 6.30935 11.2847 5.51074 10.9375C4.71213 10.5903 3.84755 10.4167 2.91699 10.4167V8.55771C4.10394 8.55771 5.21171 8.78014 6.24033 9.225C7.2688 9.66986 8.17116 10.2792 8.94741 11.0529C9.72116 11.8285 10.3305 12.7301 10.7753 13.7579C11.2202 14.7856 11.4426 15.894 11.4426 17.0833H9.58366Z"
        fill="white"
      />
    </svg>
  );
}

RssIcon.displayName = 'RssIcon';
export default RssIcon;
