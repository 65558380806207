import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import Auth from './resources/Auth';
import config from './config';

Sentry.init({
  dsn: config('SENTRY_DSN'),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

let rootElement = document.getElementById('app');

if (rootElement === null) {
  rootElement = document.createElement('<div id="app" />');
  document.append(rootElement);
}

const auth = new Auth();

const component = (
  <BrowserRouter>
    <App auth={auth} />
  </BrowserRouter>
);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(component, rootElement);
} else {
  ReactDOM.render(component, rootElement);
}
