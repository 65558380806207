import config from '../config';
import anyFetch from './anyFetch';
import Auth from './Auth';

const socAuthLogout = async (auth: Auth): Promise<void> => {
  const path = `${config('API_HOST')}/logout`;
  const opts = auth && auth.AUTH ? { credentials: auth.AUTH } : {};
  await anyFetch(path, opts);
};

export default socAuthLogout;
