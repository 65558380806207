import React from 'react';

import Title from '~/components/typography/Title';
import c from './HeaderCard.sass';

type TProps = {
  title: string;
  imageSrc: string;
}

const HeaderCard: React.FC<TProps> = (props: TProps) => {
  const { title, imageSrc } = props;

  return (
    <div className={c.header_card}>
      <img alt={title} src={imageSrc} className={c.header_card__image} />
      <Title level={4}>{title}</Title>
    </div>
  );
}

HeaderCard.displayName = 'HeaderCard';
export default HeaderCard;
