import React, { FC } from 'react';
import Paragraph from '~/components/typography/Paragraph';

import clsx from 'clsx';
import { usePageContext } from '~/components';
import c from './GuideContentItem.sass';

type TProps = {
  text: string;
  isActive: boolean;
  idx: number;
};

const GuideContentItem: FC<TProps> = ({  text, isActive, idx }) => {
  const { guideContents } = usePageContext();

  const handleClick = () => {
    if (!guideContents) return;

    const [_, setGuideContents] = guideContents;

    setGuideContents((prev) => {
      if (!prev) return prev;

      return { ...prev, shouldScrollToIdx: idx };
    });
  };

  return (
    <div className={c.guide_content_item} onClick={handleClick}>
      <Paragraph
        size="small"
        className={clsx(c.guide_content_item__text, {
          [c.guide_content_item__text_is_active]: isActive,
        })}
      >
        {text}
      </Paragraph>
      <div className={clsx(c.guide_content_item__point, {
        [c.guide_content_item__point_is_active]: isActive,
      })}
      />
    </div>
  );
}

GuideContentItem.displayName = 'GuideContentItem';
export default GuideContentItem;
