import React, { FC } from 'react';

const BgGeomIcon: FC = () => (

  <svg width="346" height="488" viewBox="0 0 346 488" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="43.6309" cy="167" r="43" fill="#25D3FF" />
    <path d="M220.902 79.4516L239.083 60.1814L212.891 55.1677C208.335 54.3747 206.468 53.0552 206.468 49.8875C206.468 48.8323 206.733 47.775 207.276 46.4555L213.685 26.6586C214.758 23.2288 216.095 21.645 218.505 21.645C219.843 21.645 221.445 22.1715 223.577 23.2288L247.916 34.3156L244.698 7.91916C244.433 7.12832 244.433 6.33533 244.433 5.28016C244.433 1.3195 246.035 0 251.121 0L272.242 0C276.784 0 278.929 1.58383 278.929 5.54234L278.651 7.91916L275.446 34.3156L299.507 23.2288C301.653 22.1715 303.255 21.645 304.592 21.645C306.724 21.645 308.34 23.2288 309.399 26.6586L316.087 46.4555C316.63 47.775 316.895 48.8323 316.895 49.8875C316.895 53.0552 315.014 54.3747 310.472 55.1677L284.001 60.1814L302.447 79.4516C304.328 81.2998 305.387 83.1479 305.387 84.7317C305.387 86.5778 304.063 88.426 301.653 90.0098L284.544 102.152C282.663 103.471 281.326 104 279.988 104C277.857 104 276.519 102.68 274.652 99.5127L261.542 76.2839L248.71 99.5127C246.843 102.416 245.241 104 243.36 104C242.023 104 240.421 103.471 238.554 102.152L221.71 90.0098C219.299 88.1616 217.962 86.5778 217.962 84.7317C217.962 83.1479 219.035 81.2998 220.902 79.4516Z" fill="#25D3FF" />
    <path d="M265.834 380.297C259.548 410.914 245.282 436.806 227.374 453.931C209.462 471.061 188.031 479.325 167.288 475.066C146.545 470.807 130.104 454.767 120.391 431.965C110.68 409.169 107.771 379.75 114.057 349.133C120.344 318.515 134.61 292.623 152.517 275.498C170.43 258.368 191.861 250.104 212.604 254.363C233.347 258.622 249.788 274.662 259.501 297.465C269.212 320.261 272.121 349.679 265.834 380.297Z" stroke="#25D3FF" strokeWidth="3" />
    <path d="M210.432 344.503C182.495 331.153 160.607 314.835 147.764 299.32C141.34 291.559 137.246 284.08 135.723 277.342C134.211 270.65 135.234 264.723 139.01 259.84C142.825 254.908 149.213 251.349 157.719 249.262C166.213 247.179 176.683 246.6 188.46 247.515C212.011 249.344 240.562 257.133 268.489 270.478C296.426 283.828 318.314 300.146 331.157 315.661C337.581 323.422 341.675 330.902 343.198 337.639C344.711 344.332 343.687 350.259 339.911 355.141C336.096 360.073 329.708 363.633 321.202 365.719C312.709 367.802 302.238 368.382 290.461 367.467C266.91 365.637 238.359 357.848 210.432 344.503Z" stroke="#25D3FF" strokeWidth="3" />
  </svg>

);

BgGeomIcon.displayName = 'BgGeomIcon';
export default BgGeomIcon;
