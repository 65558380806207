import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import StarIcon from '~/components/icons/Star';
import clsx from 'clsx';
import c from './Topic.sass';

type TTopicSize = 'md' | 'lg';

interface TProps {
  title?: string
  subtitle?: string
  href?: string
  showDot?: boolean
  colorOrange?: boolean
  isGuide?: boolean
  size?: TTopicSize
}

const Topic: FC<TProps> = ({
  title, subtitle, href, showDot = false, colorOrange = false, isGuide = false, size = 'md',
}) => (
  <div className={clsx(c.topic, {
    [c.topic_dot]: showDot,
    [c.topic_orange]: colorOrange,
    [c.topic_purple]: isGuide,
    [c.topic_size_lg]: size === 'lg',
  })}
  >
    {title && (
      href ? (
        <Link to={href} className={`${c.item} ${c.item_isBlack}`}>
          {isGuide && <div className={c.star_container}><StarIcon /></div>}
          {title}
        </Link>
      ) : (
        <div className={`${c.item} ${c.item_isBlack}`}>
          {isGuide && <div className={c.star_container}><StarIcon /></div>}
          {title}
        </div>
      )
    )}
    {subtitle && (
    <div className={`${c.item} ${c.item_subtitle}`}>{subtitle}</div>
    )}
  </div>
);

Topic.displayName = 'Topic';
export default Topic;
