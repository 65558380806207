import React, { Dispatch, FC, SetStateAction } from 'react';
import Button from '~/components/Button/Button';

import BulletedListIcon from '~/components/icons/BulletedList';
import Paragraph from '~/components/typography/Paragraph';
import { useIsScrolling, VerticalScrollDirection } from '~/utils/hooks/useIsScrolling';
import clsx from 'clsx';
import CloseIcon from '~/components/icons/Close';
import c from './StickyButton.sass';

type TProps = {
  isOpenStickTableOfContents: boolean;
  setOpenStickyTableOfContents: Dispatch<SetStateAction<boolean>>;
}

const StickyButton: FC<TProps> = ({
  isOpenStickTableOfContents, setOpenStickyTableOfContents 
}) => {
  const { scrollDirectionY } = useIsScrolling();

  const handleClick = () => {
    setOpenStickyTableOfContents((prev) => !prev);
  };

  const renderContentCloseState = () => (
    <>
      <CloseIcon />
      <Paragraph variant="caption" className={c.sticky_button__text_label}>Закрыть</Paragraph>
    </>
  );

  const renderContentOpenState = () => (
    <>
      <BulletedListIcon />
      <Paragraph variant="caption" className={c.sticky_button__text_label}>Оглавление</Paragraph>
    </>
  );

  return (
    <Button
      variant="secondary"
      className={clsx(c.sticky_button, {
        [c.sticky_button_blured]: scrollDirectionY === VerticalScrollDirection.Down,
      })}
      onClick={handleClick}
    >
      {isOpenStickTableOfContents ? renderContentCloseState() : renderContentOpenState()}
    </Button>
  );
}

StickyButton.displayName = 'StickyButton';
export default StickyButton;
