import { useRouteMatch } from 'react-router';
import { usePageContext } from '~/components';
import c from '~/components/page/PageMain.sass';
import Paragraph from '~/components/typography/Paragraph';
import React, { FC, useMemo } from 'react';

const ADMIN_URLS = ['/post/:slug', '/super/:slug', '/longread/:slug'];

type TProps = {
  isAdmin: boolean;
}
const AdminPage: FC<TProps> = ({isAdmin}) => {
  if (!isAdmin) return null;
  const match = useRouteMatch<{ slug?: string }>();
  const { path, params: { slug } } = match;

  // const { profile } = usePageContext();
  const [lastPost] = usePageContext().lastPost;

  // const isAdmin = profile && profile[0] && profile[0].is_admin;
  const isAdminUrl = ADMIN_URLS.indexOf(path) >= 0;

  const editUrl = useMemo<string | null>(() => {
    if (!isAdminUrl) {
      return null;
    }
    let prefix = '/admin/editor-post';
    if (path === '/longread/:slug') {
      prefix = '/admin/editor-longread';
    }
    if (lastPost?.partner_material) {
      prefix = '/admin/editor-partner';
    }
    return `${prefix}/${slug}`;
  }, [isAdminUrl]);


  return (
    <>
      <li>
        <a href="/admin" target="_blank" className={c.login}>
          <Paragraph weight="bold">Управление</Paragraph>
        </a>
      </li>
      {editUrl && (
        <li>
          <a
            href={editUrl}
            rel="noreferrer"
            target="_blank"
            className={c.login}
          >
            <Paragraph weight="bold">Редактировать</Paragraph>
          </a>
        </li>
      )}
    </>
  );
};

AdminPage.displayName = 'AdminPage';
export default AdminPage;
