import React, { FC } from 'react';
import { useConfig } from '~/resources';
import disableScroll from '~/utils/disableScroll';
import c from './Auth.sass';
import {
  LogoGoogleIcon, LogoMailruIcon, LogoVKontakteIcon, LogoYandexIcon,
} from './Icons';
import { AuthModalState, usePageContext } from '../page/context.page';

interface LinkItem {
  name: string,
  provider: string,
  url: string,
  icon: JSX.Element,
}

const Socials: FC = () => {
  const { data } = useConfig();
  const socialLinks = data ? data.social_links : {};
  const { authModal } = usePageContext();

  const linksArr: LinkItem[] = [
    {
      name: 'Google',
      provider: 'google',
      url: socialLinks.google ?? 'http://google.com',
      icon: (<LogoGoogleIcon />),
    },
    {
      name: 'Yandex',
      provider: 'yandex',
      url: socialLinks.yandex ?? 'http://ya.ru',
      icon: (<LogoYandexIcon />),
    },
    {
      name: 'ВКонтакте',
      provider: 'vkontakte',
      url: socialLinks.vkontakte ?? 'http://vk.com',
      icon: (<LogoVKontakteIcon />),
    },
    {
      name: 'Mail.ru',
      provider: 'mailru',
      url: socialLinks.mailru ?? 'http://mail.ru',
      icon: (<LogoMailruIcon />),
    },
  ];

  const links: LinkItem[] = [];
  linksArr.forEach((link) => {
    if (socialLinks[link.provider]) {
      links.push(link);
    }
  });

  const onClick = (url: string, provider: string) => {
    if (!authModal) { return; }
    authModal[1]({ authModalState: AuthModalState.NONE });
    disableScroll(false);
    window.open(url, `${provider}-social-auth`, 'modal');
  };

  return (
    <div className={c.social_wrapper}>
      {links.map((link) => (
        <button
          type="button"
          key={link.provider}
          className={`${c.btn} ${c.btn_provider}`}
          onClick={() => onClick(link.url, link.provider)}
        >
          {link.icon}
          {link.name}
        </button>
      ))}
    </div>
  );
};

Socials.displayName = 'Socials';
export default Socials;
