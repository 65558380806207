import React, { FC, useEffect, useState } from 'react';
import {
  Link, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom';
import useSubscription from '~/resources/useSubscription';
import { SubscriptionPlan } from '~/resources/models';
import useSubscriptionFlow from '~/resources/useSubscriptionFlow';
import { SubscriptionStatus } from '~/utils/purshase-event';
import useProfile from '~/resources/useProfile';
import SubscriptionHeader from '~/components/PaidSubscription/ui/SubscriptionHeader';
import SubscriptionFeatures from '~/components/PaidSubscription/ui/SubscriptionFeatures';
import SubscriptionReviews from '~/components/PaidSubscription/ui/SubscriptionReviews';
import Title from '~/components/typography/Title';
import c from './SubscriptionComponent.sass';
import Loader from '../Loader';
import { SubscriptionFlowStep, SubscriptionType, usePageContext } from '../page';
import { SubscriptionPayment } from './SubscriptionPayment';
import { SubscriptionStatusModal } from './SubscriptionStatusModal';
import { SubscriptionCheck } from './SubscriptionCheck';

interface TProps {
  discountPromocode: string | undefined;
}

const SubscriptionComponent: FC<TProps> = ({ discountPromocode }) => {
  const { url } = useRouteMatch();
  const { fetchBy: fetchPlans, data: plans } = useSubscription({
    discount_promocode: discountPromocode || '',
  });
  const location = useLocation();
  const utmCampaign = new URLSearchParams(location.search).get('utm_campaign');
  const utmSource = new URLSearchParams(location.search).get('utm_source');
  const utm = (utmCampaign || utmSource)
    ? JSON.stringify({ utmCampaign, utmSource }) : undefined;
  const {
    buySubscription,
    subscriptionFlowVal,
    cancelSubscription,
    checkPayment,
  } = useSubscriptionFlow();
  const { profile, auth } = usePageContext();
  const { data: profileData, fetchBy: fetchProfile } = useProfile();
  const step = subscriptionFlowVal && subscriptionFlowVal.step;
  const plan = subscriptionFlowVal && subscriptionFlowVal.plan;
  const [finalStatus, setFinalStatus] = useState<SubscriptionStatus>();
  const [orderId, setOrderId] = useState<number>();
  const history = useHistory();

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    if (!profileData || !profile || !profile[1]) { return; }
    profile[1](profileData);
  }, [profileData]);

  const buy = (selectedPlan: SubscriptionPlan) => {
    buySubscription(selectedPlan, SubscriptionType.NEW, url);
  };

  const onStatus = (status: SubscriptionStatus) => {
    if (status === SubscriptionStatus.PAYMENT_CHECK_PROCESSED) {
      fetchProfile();
      auth?.clearCache();
    }
    setFinalStatus(status);
    cancelSubscription();
  };

  const onSuccessPayment = (oId: number) => {
    setOrderId(oId);
    checkPayment();
  };

  const cancel = () => {
    setFinalStatus(undefined);
    cancelSubscription();
    fetchProfile();
    fetchPlans();
  };

  const renderInactiveDiscountPromocode = () => (
    <div className={c.subscription_promo_error}>
      <Title level={2}>Промокод не работает, попробуйте другой</Title>
      <button
        onClick={() => history.push('/subscription')}
        type="button"
        className={c.pay_button}
      >
        Попробовать ещё
      </button>
    </div>
  );

  const renderHeader = () => {
    if (!plans) return (<Loader />);

    if (plans.length === 0) {
      return renderInactiveDiscountPromocode();
    }

    return <SubscriptionHeader plans={plans} onSubmit={buy} />;
  };

  const renderPayment = () => (step === SubscriptionFlowStep.PAYMENT && plan
    ? (
      <SubscriptionPayment
        plan={plan}
        promocode={discountPromocode || ''}
        onError={onStatus}
        onSuccess={onSuccessPayment}
        onClose={cancel}
        utm={utm}
      />
    )
    : null
  );

  const renderCheck = () => (step === SubscriptionFlowStep.CHECK && orderId && (
    <SubscriptionCheck
      orderId={orderId}
      onClose={cancel}
      onCheck={onStatus}
    />
  ));

  const renderStatus = () => (finalStatus && (
    <SubscriptionStatusModal status={finalStatus} onClose={cancel} />
  ));

  return (
    <div className={c.wrapper}>
      {renderHeader()}
      {renderPayment()}
      {renderCheck()}
      {renderStatus()}
      <div className={c.subscription_content_wrapper}>
        <SubscriptionFeatures />
        <SubscriptionReviews />
      </div>
    </div>
  );
};

SubscriptionComponent.displayName = 'SubscriptionComponent';
export default SubscriptionComponent;
