import React, { FC, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import useAnalytics from '~/utils/Analytics';

import { ProviderPage } from '~/components';
import ScrollToTop from '~/components/ScrollToTop/ScrollToTop';
import { useIsMounted } from '~/utils/hooks/useIsMounted';
import routes from './routes';
import './style.sass';
import Auth from './resources/Auth';
import Helmeter from './screens/Helmeter';

interface TProps {
  auth: Auth,
}

const App: FC<TProps> = ({ auth }) => {
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!isMounted()) return;

    smoothscroll.polyfill();
  }, []);

  useAnalytics();
  return (
    <ProviderPage auth={auth}>
      <Helmeter
        title="Reminder"
        metaTitle="Reminder"
        metaDescription="Reminder — это медиа о здоровье, саморазвитии и практической философии."
      />
      <ScrollToTop />
      <Switch>
        {routes.map(({ path, component, exact }) => (
          <Route
            key={path}
            {...{ path, component, exact }}
          />
        ))}
      </Switch>
    </ProviderPage>
  );
};

App.displayName = 'App';
export default App;
