import React, { FC } from 'react';

import { MailingStatus } from '~/resources/useMailing';
import Title from '~/components/typography/Title';

type TProps = {
  status: MailingStatus;
};

const FooterMailingStatusText: FC<TProps> = ({ status }) => {
  switch (status) {
    case MailingStatus.OK: {
      return (
        <Title level={4}>Есть. Следующее наше письмо будет у вас в почте!</Title>
      );
    }
    case MailingStatus.EXISTS: {
      return (
        <div>
          <Title level={4}>Кажется, вы уже подписаны.</Title>
          <Title level={4}>
            Если нет, то напишите нам:
            {' '}
            <a href="mailto:newsletter@reminder.media">
              <u>newsletter@reminder.media</u>
            </a>
          </Title>
        </div>
      );
    }
    case MailingStatus.ERROR: {
      return (
        <div>
          <Title level={4}>Что-то пошло не так.</Title>
          <Title level={4}>
            Напишите нам, если не удалось подписаться:
            {' '}
            <a href="mailto:newsletter@reminder.media">
              <u>newsletter@reminder.media</u>
            </a>
          </Title>
        </div>
      );
    }
    default: {
      return null;
    }
  }
}

FooterMailingStatusText.displayName = 'FooterMailingStatusText';
export default FooterMailingStatusText;
