export interface PaymentFormParams {
  confirmationType: string;
  confirmationUrl?: string;
  confirmationToken?: string;
  elementId: string;
  onError?: (error?: any) => void;
  onSuccess?: () => void;
}

export const initPaymentForm = (params: PaymentFormParams): void => {
  switch (params.confirmationType) {
    case 'redirect': {
      if (params.confirmationUrl) {
        window.location.href = params.confirmationUrl;
      }
      break;
    }
    case 'embedded':
    default: {
      try {
        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: params.confirmationToken,
          embedded_3ds: true,
          error_callback(error: any) {
            if (params.onError) {
              params.onError(error);
            }
            console.log(error);
          },
        });
        checkout.on('success', () => {
          checkout.destroy();
          if (params.onSuccess) {
            params.onSuccess();
          }
        });
        checkout.on('fail', () => {
          checkout.destroy();
          if (params.onError) {
            params.onError();
          }
        });
        checkout.render('payment-form');
      } catch (e) {
        if (params.onError) {
          params.onError();
        }
      }
      break;
    }
  }
};
