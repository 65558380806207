import React, { FC } from 'react';

const CloseIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="24"
      height="24"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4142 12L21.1925 4.22168L19.7783 2.80746L12 10.5858L4.22183 2.80762L2.80762 4.22183L10.5858 12L2.80776 19.778L4.22197 21.1922L12 13.4142L19.7782 21.1924L21.1924 19.7782L13.4142 12Z"
      // fill="#F85B2E"
      // fill="#A5A5A5"
    />
  </svg>
);

CloseIcon.displayName = 'CloseIcon';
export default CloseIcon;
