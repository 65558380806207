import React, { FC, useEffect, useState } from 'react';
import { MenuSoc } from '~/components';

import c from './MenuSubscription.sass';

const MenuSubscription: FC = ({
  children,
}) => {
  const [hide, setHide] = useState(false);

  const onScroll = () => {
    const footer = document.querySelector<HTMLElement>('#footer');
    const offsetTop = footer ? footer.getBoundingClientRect().y : 0;
    setHide((offsetTop + 270) <= window.innerHeight);
  };

  useEffect(() => {
    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  const gaEvent = () => {
    (window as any).dataLayer.push({
      event: 'sp_event',
      eventCategory: 'subscription',
      eventAction: 'click_btn_subscription',
    });
  };

  return (
    <div className={`${c.container} ${c[`hide-${hide}`]}`}>
      <div className={c.wrap}>
        {children}
        <a href="#footer-mailing" className={c.title} onClick={gaEvent}>Рассылка</a>
        {/* {status ? (
          <div className={c.desc}>
            <SubscriptionMessage status={status} />
          </div>
        ) : (
          <>
            <div className={c.desc}><ServiceText name="mailing_text" /></div>
            <div className={c.form}>
              <SubscriptionForm
                tags={['mobile_menu1']}
                onChangeStatus={setStatus}
              >
                <svg viewBox="0 0 16 16">
                  <path d="M9.07555 0.924609L13.3664 7.16726H0V8.8332H13.3664L9.07555 15.0756L10.498 16L16 8.00011L10.498 0L9.07555 0.924609Z" />
                </svg>
              </SubscriptionForm>
            </div>
            <a href="#footer-mailing" className={c.button} onClick={gaEvent}>Подписаться</a>
          </>
        )} */}
      </div>

      <MenuSoc />
    </div>
  );
};

MenuSubscription.displayName = 'MenuSubscription';
export default MenuSubscription;
