import React, { FC } from 'react';

import c from './Sceletons.sass';

const SidebarSceleton: FC = () => (
  <li className={c.sidebar_skeleton} />
);

SidebarSceleton.displayName = 'SidebarSceleton';
export default SidebarSceleton;
