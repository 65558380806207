import React, {
  ChangeEvent, FC, FormEvent, useEffect, useState,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import anyFetch from '~/resources/anyFetch';
import config from '~/config';
import validatePassword from '~/utils/password';
import useProfile from '~/resources/useProfile';
import useSubscriptionFlow from '~/resources/useSubscriptionFlow';
import disableScroll from '~/utils/disableScroll';
import {
  AlertModalType, AuthModalState, usePageContext,
} from '~/components';
import c from './Auth.sass';
import { EyeCloseIcon, EyeOpenIcon } from './Icons';
import Loader from '../Loader';
import { SubscriptionFlowStep } from '../page';

interface CheckFormData {
  password: string;
  confirmPassword: string;
}

interface TProps {
  urlKey: string;
}

const ChangePasswordForm: FC<TProps> = ({ urlKey }) => {
  const { url } = useRouteMatch();
  const {
    authModal,
    alertModal,
    profile: profileState,
  } = usePageContext();
  const history = useHistory();
  const [formData, setFormData] = useState<CheckFormData>({
    password: '',
    confirmPassword: '',
  });
  const { subscriptionFlowVal, buyAfterAuth } = useSubscriptionFlow();
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(true);
  const { data: profile } = useProfile();
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const setProfile = profileState && profileState[1];

  const onChange = (e: ChangeEvent<HTMLInputElement>, value?: unknown) => {
    const { name, value: eventValue } = e.target;
    setShowError(false);
    setFormData((prev) => ({ ...prev, [name]: value ?? eventValue }));
  };

  useEffect(() => {
    const isValid = validatePassword(formData.password);
    const isEqual = formData.password === formData.confirmPassword;
    setIsPasswordInvalid(!isValid || !isEqual);
  }, [formData]);

  useEffect(() => {
    if (!profile || !setProfile) { return; }
    setProfile(profile);
    disableScroll(false);
    if (authModal) {
      authModal[1]({ authModalState: AuthModalState.NONE });
    }
    if (
      subscriptionFlowVal
      && subscriptionFlowVal.step === SubscriptionFlowStep.AUTH
      && url === subscriptionFlowVal.subscriptionStartUrl
    ) {
      buyAfterAuth();
    } else {
      history.push('/profile');
    }
  }, [profile]);

  const onError = () => {
    setShowError(false);
  };

  const onSuccess = () => {
    if (!alertModal || !authModal) { return; }
    alertModal[1]({ alertType: AlertModalType.PASSWORD_CHANGED_SUCCESS });
    authModal[1]({ authModalState: AuthModalState.NONE });
    disableScroll(true);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { password, confirmPassword } = formData;

    setShowError(isPasswordInvalid);
    if (isPasswordInvalid) {
      return;
    }

    setIsLoading(true);
    const res = await anyFetch(`${config('API_HOST')}/auth/confirm-change-password`, {
      method: 'POST',
      body: JSON.stringify({
        password,
        password_confirmation: confirmPassword,
        key: urlKey,
        client_id: config('CLIENT_ID'),
        client_secret: config('CLIENT_SECRET'),
      }),
    });
    const data = await res.json();
    setIsLoading(false);

    if (data.result === 'ok') {
      onSuccess();
    } else {
      onError();
    }
  };

  return (
    <>
      <div className={c.title}>Придумайте пароль</div>
      <form
        className={c.form}
        onSubmit={onSubmit}
      >
        <div
          className={showError ? `${c.inputText_password} ${c.inputText_invalid}` : c.inputText_password}
        >
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Пароль"
            aria-label="Password"
            value={formData.password}
            className={c.fullWidth}
            onChange={onChange}
            required
          />
          {showPassword ? (
            <button
              type="button"
              className={c.icon_eye}
              onClick={() => setShowPassword(false)}
            >
              <EyeOpenIcon />
            </button>
          ) : (
            <button
              type="button"
              className={c.icon_eye}
              onClick={() => setShowPassword(true)}
            >
              <EyeCloseIcon />
            </button>
          )}
        </div>
        <div
          className={showError ? `${c.inputText_password} ${c.inputText_invalid}` : c.inputText_password}
        >
          <input
            type={showPassword ? 'text' : 'password'}
            name="confirmPassword"
            placeholder="Повторите пароль"
            aria-label="ConfirmPassword"
            value={formData.confirmPassword}
            className={c.fullWidth}
            onChange={onChange}
            required
          />
          {showPassword ? (
            <button
              type="button"
              className={c.icon_eye}
              onClick={() => setShowPassword(false)}
            >
              <EyeOpenIcon />
            </button>
          ) : (
            <button
              type="button"
              className={c.icon_eye}
              onClick={() => setShowPassword(true)}
            >
              <EyeCloseIcon />
            </button>
          )}
        </div>
        <span
          className={c.info}
        >
          Пароль должен содержать не менее 8 символов,
          в том числе хотя бы одну цифру и заглавную букву
        </span>
        <button
          type="submit"
          aria-label="Сохранить"
          className={
            isPasswordInvalid
              ? `${c.btn} ${c.btn_disabled}`
              : `${c.btn} ${c.btn_enabled}`
          }
        >
          Сохранить
        </button>
      </form>
      {isLoading && (
        <div className={c.modal__loading}>
          <Loader />
        </div>
      )}
    </>
  );
};

ChangePasswordForm.displayName = 'ChangePasswordForm';
export default ChangePasswordForm;
