const disableScroll = (disable: boolean): void => {
  const isServer = typeof window === 'undefined';
  if (!isServer) {
    if (disable) {
      window.document.body.style.height = '100vh';
      window.document.body.style.overflow = 'hidden';
    } else {
      window.document.body.style.height = 'auto';
      window.document.body.style.overflow = 'auto';
    }
  }
};

export default disableScroll;
