import { useEffect } from 'react';
import { Post } from '~/resources/models';
import {
  HookTinkoffDatamindDestroy,
  HookTinkoffDatamindInit,
} from '~/utils/partner-hooks/HooksTinkoffDatamind';

export declare type PartnerMaterialsHookType = {
  onEnter?: (slug?: string) => void,
  onOut?: (slug?: string) => void
}

export declare type PartnerMaterialsHookListType = {
  [slug: string]: PartnerMaterialsHookType
}

export const PartnerHooks: PartnerMaterialsHookListType = {
  'tri-kognitivnye-oshibki-kotorye-meshayut-investoram': {
    onEnter: HookTinkoffDatamindInit,
    onOut: HookTinkoffDatamindDestroy,
  },
};

export const usePartnerMaterialHooks = (post: Post | undefined): void => {
  const hook = () => {
    if (post) {
      // eslint-disable-next-line camelcase
      const { partner_material } = post;
      const slug = post.slug.replace(/post|longread|super\//, '');
      // eslint-disable-next-line camelcase
      if (partner_material) {
        const hookObj = PartnerHooks[slug];
        if (hookObj && hookObj.onEnter) {
          hookObj.onEnter(slug);
        }
        return () => {
          if (hookObj && hookObj.onOut) {
            hookObj.onOut(slug);
          }
        };
      }
    }
    return undefined;
  };
  useEffect(hook, [post]);
};
