import { TSliderItem } from '~/components/slider/types';

import image5 from '../images/image5.png';
import image4 from '../images/image4.png';
import image3 from '../images/image3.png';
import image2 from '../images/image1.png';
import image1 from '../images/image2.png';

export const guidesItems: TSliderItem[] = [
  {
    title: 'Гайд: Второй мозг',
    description: 'Как построить персональную систему управления информацией',
    imageSrc: image1,
    href: 'https://reminder.media/post/second-brain',
  },
  {
    title: 'Гайд: Как превратить работу дома в удовольствие',
    description: '50+ идей для вдохновения: столы, стулья, лампы, звук, растения и др.',
    imageSrc: image2,
    href: 'https://reminder.media/post/gayd-kak-prevratit-rabotu-iz-doma-v-udovolstvie',
  },
  {
    title: 'Гайд: Здоровое долголетие',
    description: 'Методы и стратегии, которые помогут продлить свою жизнь, сохранив активность и здоровье',
    imageSrc: image3,
    href: 'https://reminder.media/post/longevity-guide',
  },
  {
    title: 'Гайд по психологической устойчивости',
    description: 'Развиваем резилентность: история вопроса, практики и видео-инструкции, полезные ссылки',
    imageSrc: image4,
    href: 'https://reminder.media/post/gayd-rezilentnost',
  },
  {
    title: 'Гайд: Привычки',
    description: 'Как заводить хорошие привычки и избавляться от плохих',
    imageSrc: image5,
    href: 'https://reminder.media/post/habits-guide',
  },
];
