import React, { FC, useEffect, useRef, useState } from 'react';
import likePost from '~/resources/useLike';
import { Post } from '~/resources/models';
import Paragraph from '~/components/typography/Paragraph';
import ApplauseIcon from './ApplauseIcon';
import classes from './PostLike.sass';

interface TProps {
  post: Post
}

interface Cache {
  [key: number]: {
    date: number
    count: number
  }
}

enum Constants {
  maxDailyCount = 5,
  cacheName = 'userlikes',
  timeLimit = 86400000,
  afterClickDelay = 1000,
  successDelay = 500,
  notificationDelay = 1000,
}

const PostLike: FC<TProps> = ({ post: { id: postId, likes_count: likes } }) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [clickCount, setClickCount] = useState(0);
  const [cachedClicks, setCachedClicks] = useState(0);
  const [totalCount, setTotalCount] = useState(likes);
  const [updatedCount, setUpdatedCount] = useState(0);
  const [dirty, setDirty] = useState(false);
  const [timeIsOver, setTimeIsOver] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dailyLimit, setDailyLimit] = useState(false);
  const [touched, setTouched] = useState(false);
  const [notification, setNotification] = useState(false);

  const setCache = () => {
    if (localStorage) {
      const currentCache: Cache = JSON.parse(localStorage.getItem(Constants.cacheName) || '{}');
      currentCache[postId] = {
        date: Date.now() + Constants.timeLimit,
        count: clickCount + cachedClicks,
      };
      localStorage.setItem(Constants.cacheName, JSON.stringify(currentCache));
    }
  };

  const getCache = () => {
    if (localStorage) {
      const currentCache: Cache = JSON.parse(localStorage.getItem(Constants.cacheName) || '{}');
      const expDate = currentCache[postId]?.date || 0;
      const count = currentCache[postId]?.count || 0;

      if (expDate < Date.now()) {
        delete currentCache[postId];
        localStorage.setItem(Constants.cacheName, JSON.stringify(currentCache));
      } else if (count === Constants.maxDailyCount) {
        setDailyLimit(true);
        setCachedClicks(count);
      } else if (count < Constants.maxDailyCount) {
        setCachedClicks(count);
      }
    }
  };

  const postLikes = async () => {
    if (overlayRef.current) {
      overlayRef.current.style.opacity = '1';
      setTimeIsOver(true);

      try {
        await likePost({ post_id: postId, count: clickCount });
        setCache();
        setTimeout(() => {
          setSuccess(true);
          setDirty(false);
          overlayRef.current?.removeAttribute('style');
          setUpdatedCount(totalCount + clickCount);
          setCachedClicks((prev) => prev + clickCount);
          setClickCount(0);
        }, Constants.successDelay);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const clickHandler = () => {
    if (clickCount + cachedClicks < Constants.maxDailyCount) {
      setDirty(true);
      setClickCount((prev) => prev + 1);
    } else {
      setNotification(true);
    }
  };

  const removeSuccess = () => {
    if (success && !dailyLimit) {
      setSuccess(false);
      setTimeIsOver(false);
    }
  };

  const touchStart = () => {
    if (!dailyLimit && !timeIsOver) {
      setTouched(true);
    }
  };

  const touchEnd = () => {
    if (!dailyLimit && !timeIsOver) {
      setTouched(false);
    }
  };

  useEffect(() => {
    getCache();
  }, []);

  useEffect(() => {
    let timeout: any;
    if (notification) {
      timeout = setTimeout(() => setNotification(false), Constants.notificationDelay);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [notification]);

  useEffect(() => {
    let timeout: any;
    if (dirty) {
      timeout = setTimeout(postLikes, Constants.afterClickDelay);
    }
    if (clickCount === Constants.maxDailyCount - cachedClicks) {
      setDailyLimit(true);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [clickCount]);

  useEffect(() => {
    let timeout: any;
    if (updatedCount > totalCount) {
      timeout = setTimeout(() => {
        setTotalCount((prev) => prev + 1);
      }, 100);
    }
    return (() => clearTimeout(timeout));
  }, [updatedCount, totalCount]);

  const counter = !dirty ? (
    <span className={classes.likes_total}>
      { totalCount }
    </span>
  ) : (
    <span>
      { `+${clickCount + cachedClicks}` }
    </span>
  );

  return (
    <div className={classes.wrapper}>
      <button
        ref={buttonRef}
        type="button"
        className={
          (timeIsOver && !success ? ` ${classes.post_progress}` : '')
          + (success ? ` ${classes.post_success}` : '')
          + (touched ? ` ${classes.btn_active}` : '')
        }
        disabled={(dailyLimit || timeIsOver) && notification}
        onClick={clickHandler}
        onTransitionEnd={removeSuccess}
        onTouchStart={touchStart}
        onTouchEnd={touchEnd}
      >
        <div ref={overlayRef} className={classes.overlay} style={{ opacity: `0.${!dirty ? '0' : clickCount + cachedClicks}` }} />
        <div className={classes.inner + (dirty ? ` ${classes.inner_dirty}` : '')}>
          <ApplauseIcon />
          <Paragraph>
            {timeIsOver && !success ? 'Спасибо!' : counter}
          </Paragraph>
        </div>
      </button>
      <div className={classes.notification + (notification ? ` ${classes.notification_active}` : '')}>
        Вы уже оценили материал
      </div>
    </div>
  );
};

PostLike.displayName = 'PostLike';
export default PostLike;
