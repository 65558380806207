import React, { useState, useEffect, FC, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';

import config from '~/config';

interface TProps {
  name?: string
}

const JsonLd: FC<TProps> = ({ name = '' }) => {
  const location = useLocation();

  const generateData = useMemo<JSX.Element>(() => {
    const url = `${config('APP_HOST')}${location.pathname}`;
    const siteName = 'Reminder';
    let body: any = { '@context': 'http://schema.org' };
    if (name) {
      body = {
        ...body,
        '@type': 'NewsMediaOrganization',
        name,
        url,
      };
    } else {
      const alternateName = 'Reminder — издание о здоровье, саморазвитии и практической философии. Мы ищем ответы на самые важные вопросы: как принимать более качественные решения на работе и в личной жизни, как добиваться больших результатов, жить дольше и счастливее.';
      body = {
        ...body,
        '@graph': [
          {
            '@type': 'WebSite', url, name: siteName, alternateName,
          },
          { '@type': 'Organization', url, name: siteName },
        ],
      };
    }

    return (
      <script type="application/ld+json">
        {JSON.stringify(body)}
      </script>
    );
  }, [name, location]);

  return (
    <Helmet>
      <meta name="key" content="Reminder" />
      {generateData}
    </Helmet>
  );
};

JsonLd.displayName = 'JsonLd';
export default JsonLd;
