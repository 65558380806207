import React, { FC, HtmlHTMLAttributes } from 'react';
import clsx from 'clsx';

import c from './Paragraph.sass';

type TProps = HtmlHTMLAttributes<HTMLParagraphElement> & {
  variant?: 'body' | 'caption',
  size?: 'middle' | 'small',
  weight?: 'bold' | 'normal',
};

const Paragraph: FC<TProps> = ({
  variant = 'body', size = 'middle', weight = 'normal', children, className, ...rest
}) => {
  const classNameString = clsx({
    [c.body]: variant === 'body',
    [c.body__small]: variant === 'body' && size === 'small',
    [c.caption]: variant === 'caption',
    [c.caption__small]: variant === 'caption' && size === 'small',
    [c.bold]: weight === 'bold',
  }, className);

  return (
    <p {...rest} className={classNameString}>{children}</p>
  );
}

Paragraph.displayName = 'Paragraph';
export default Paragraph;
