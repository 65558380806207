import { useRef } from 'react';

import anyFetch from './anyFetch';
import config from '../config';

export enum MailingStatus {
  OK = 'ok',
  EXISTS = 'exists',
  ERROR = 'error',
}

export const mailingSend = async (body: any): Promise<any> => {
  const res = await anyFetch(
    `${config('API_HOST')}/mailings/subscribe`,
    { method: 'POST', body: JSON.stringify(body) },
  );
  const data = await res.json();
  return data;
};

const useMailing = (): [string, (status: string) => void] => {
  const ref = useRef('');
  const setStatus = (status: string) => {
    ref.current = status;
  };

  return [ref.current, setStatus];
};

export default useMailing;
