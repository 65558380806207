import React, { FC } from 'react';

const GiftIcon: FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.42926 17.9167V8.84604H2.08301V4.50333H6.39238C6.27488 4.36764 6.19502 4.21479 6.1528 4.04479C6.11058 3.87493 6.08947 3.69653 6.08947 3.50958C6.08947 2.88639 6.30759 2.35667 6.74384 1.92042C7.18009 1.48417 7.70981 1.26604 8.33301 1.26604C8.65245 1.26604 8.94842 1.33305 9.22092 1.46708C9.49329 1.60125 9.73683 1.78049 9.95155 2.00479C10.1663 1.77187 10.4099 1.59056 10.6824 1.46083C10.9547 1.33097 11.2506 1.26604 11.5701 1.26604C12.1934 1.26604 12.7232 1.48417 13.1595 1.92042C13.5957 2.35667 13.8138 2.88639 13.8138 3.50958C13.8138 3.69444 13.7906 3.87021 13.744 4.03688C13.6975 4.20354 13.6198 4.35903 13.5109 4.50333H17.9163V8.84604H16.5701V17.9167H3.42926ZM11.5701 2.51604C11.2886 2.51604 11.0526 2.61125 10.8622 2.80167C10.6718 2.99208 10.5765 3.22806 10.5765 3.50958C10.5765 3.79111 10.6718 4.02708 10.8622 4.2175C11.0526 4.40806 11.2886 4.50333 11.5701 4.50333C11.8516 4.50333 12.0877 4.40806 12.2782 4.2175C12.4686 4.02708 12.5638 3.79111 12.5638 3.50958C12.5638 3.22806 12.4686 2.99208 12.2782 2.80167C12.0877 2.61125 11.8516 2.51604 11.5701 2.51604ZM7.33947 3.50958C7.33947 3.79111 7.43467 4.02708 7.62509 4.2175C7.81551 4.40806 8.05148 4.50333 8.33301 4.50333C8.61454 4.50333 8.85051 4.40806 9.04092 4.2175C9.23134 4.02708 9.32655 3.79111 9.32655 3.50958C9.32655 3.22806 9.23134 2.99208 9.04092 2.80167C8.85051 2.61125 8.61454 2.51604 8.33301 2.51604C8.05148 2.51604 7.81551 2.61125 7.62509 2.80167C7.43467 2.99208 7.33947 3.22806 7.33947 3.50958ZM3.33301 5.75312V7.59625H9.37467V5.75312H3.33301ZM9.37467 16.6667V8.84604H4.67905V16.6667H9.37467ZM10.6247 16.6667H15.3203V8.84604H10.6247V16.6667ZM16.6663 7.59625V5.75312H10.6247V7.59625H16.6663Z"
        fill="currentColor"
      />
    </svg>
  );
}

GiftIcon.displayName = 'GiftIcon';
export default GiftIcon;
