import queryString from 'query-string';

import config from '~/config';
import * as utils from '~/utils';
import useResource, { ResourceResult, Resource, QueryParams } from './useResource';
import { Report } from './models';
import anyFetch from './anyFetch';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}/products`;

export const reportFetcher = async (
  params: QueryParams = {},
  opts: RequestInit = {},
): Promise<Resource<Report[]>> => {
  let path = PATH();
  const fillParams = {
    ...params,
    status: ['active', 'pre_order'],
    sort: 'published_at',
  };
  const paramsStr = queryString.stringify(fillParams, {
    arrayFormat: 'bracket',
  });

  if (paramsStr) {
    path += `?${paramsStr}`;
  }
  const res = await anyFetch(path, opts);
  const data = await res.json();
  return data;
};
const generateCacheKey = (params: QueryParams = {}) => `products${JSON.stringify(params)}`;

const useReports = (
  params: QueryParams = {},
  opts: RequestInit = {},
): ResourceResult<Report[]> =>
  useResource(params, opts, reportFetcher, generateCacheKey(params));

export default useReports;

export const fetchSSRReports = async (
  auth: Auth,
  path: string,
  opts: RequestInit = {},
): Promise<any> => {
  const pageParam = new URLSearchParams(utils.getSearch(path)).get('page');
  const pageInt = pageParam ? parseInt(pageParam, 10) : 1;
  const page = pageInt > 0 ? String(pageInt) : '1';
  const params: QueryParams = { type: 'report', page: String(page) };
  const res = await reportFetcher(params, opts);
  auth.setCache(`products${JSON.stringify(params)}`, res);
};
