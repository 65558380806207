import React, { FC } from 'react';
import config from '~/config';
import DonateForm from '~/components/Donate/DonateForm';
import useWindowDimensions from '~/utils/useWindowDimensions';
import c from '~/components/Donate/Donate.sass';
import Helmeter from './Helmeter';

const qaTexts = [
  {
    id: 0,
    question: 'На что пойдут мои деньги?',
    answer: 'Мы потратим средства на работу Reminder: зарплаты редакторов, дизайнеров, разработчиков.',
  },
  {
    id: 1,
    question: 'Как я могу проверить результат?',
    answer: 'Раз в год мы будем предоставлять публичные отчеты о расходах некоммерческой организации, которая управляет Reminder.',
  },
  {
    id: 2,
    question: 'Как отписаться от регулярных платежей?',
    answer: `Если вы решили отказаться от регулярных пожертвований, перейдите по <a style="text-decoration:underline;" href="${config('APP_HOST')}/donate/unsubscribe" target="_blank">ссылке</a> и введите адрес своей электронной почты.`,
  },
  {
    id: 3,
    question: 'Мне было бы удобнее поддержать вас через Patreon. Как это сделать?',
    answer: 'Мы будем рады, если вы станете патроном Reminder на Patreon. Сделать это можно здесь. <a style="text-decoration:underline;" target="_blank" href="https://www.patreon.com/remindermedia">https://www.patreon.com/remindermedia</a>',
  },
  {
    id: 4,
    question: 'Я хочу поддержать вас рекламой, куда обратиться?',
    answer: 'Мы будем рады рекламодателям. Напишите на <a style="text-decoration:underline;" href="mailto:newsletter@reminder.media">newsletter@reminder.media</a>.',
  },
];

const DonateScreen: FC = () => {
  const canonicalLink = `${config('APP_HOST')}/donate`;

  const { width } = useWindowDimensions();
  const isDesktop = width > 639;

  const logo = `${config('APP_HOST')}/public/logo.svg`;
  const editImg = `${config('APP_HOST')}/public/donate/edit.png`;
  const editImg2x = `${config('APP_HOST')}/public/donate/edit@2x.png`;
  const chatImg = `${config('APP_HOST')}/public/donate/chat.png`;
  const chatImg2x = `${config('APP_HOST')}/public/donate/chat@2x.png`;
  const titleSvg = `${config('APP_HOST')}/public/donate/title.svg`;
  const ogImage = `${config('APP_HOST')}/public/donate/donate-og.png`;

  return (
    <>
      <Helmeter
        title="Поддержите Reminder. Инвестируйте в себя."
        metaTitle="Вы можете поддержать Reminder"
        metaDescription="Подпишитесь на регулярные платежи — станьте членом клуба Reminder"
        link={[
          { rel: 'canonical', href: canonicalLink },
        ]}
        srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
        img={ogImage}
      />
      {/* Title */}
      <div className={c.container}>
        <div className={c.wrap}>
          <a href="/">
            <img src={logo} className={c.logo} alt="#" />
          </a>
          {isDesktop ? (
            <div className={c.titleContainer}>
              <div className={c.titleBox}>
                <p className={c.title}>Поддержите Reminder.</p>
                <svg
                  width="171"
                  height="16"
                  viewBox="0 0 171 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M170.707 8.70711C171.098 8.31658 171.098 7.68342 170.707 7.29289L164.343 0.928932C163.953 0.538408 163.319 0.538408 162.929 0.928932C162.538 1.31946 162.538 1.95262 162.929 2.34315L168.586 8L162.929 13.6569C162.538 14.0474 162.538 14.6805 162.929 15.0711C163.319 15.4616 163.953 15.4616 164.343 15.0711L170.707 8.70711ZM0 9H170V7H0V9Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={c.titleBox}>
                <svg
                  width="171"
                  height="16"
                  viewBox="0 0 171 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M170.707 8.70711C171.098 8.31658 171.098 7.68342 170.707 7.29289L164.343 0.928932C163.953 0.538408 163.319 0.538408 162.929 0.928932C162.538 1.31946 162.538 1.95262 162.929 2.34315L168.586 8L162.929 13.6569C162.538 14.0474 162.538 14.6805 162.929 15.0711C163.319 15.4616 163.953 15.4616 164.343 15.0711L170.707 8.70711ZM0 9H170V7H0V9Z"
                    fill="white"
                  />
                </svg>
                <p className={c.title}>Инвестируйте в себя.</p>
              </div>
            </div>
          ) : (
            <img src={titleSvg} alt="#" className={c.titleMobile} />
          )}
          {/* Description */}
          <div className={c.description}>
            Мы находим самую важную информацию о здоровье и саморазвитии и делимся с вами.
            Каждый ваш рубль мы направим на то, чтобы вы получили еще больше пользы и знаний.
            Это инвестиции. Они к вам вернутся.
          </div>
          {/* Form */}
          <p className={c.subtitle}>Выберите вариант</p>
          <DonateForm />
          {/* Offer */}
          <p className={c.offer}>
            Совершая пожертвование, вы принимаете
            {' '}
            <a className={c.offerLink} href="/public_offer">оферту</a>
            {' '}
            и
            {' '}
            <a className={c.offerLink} href="privacy_policy">политику конфиденциальности</a>
          </p>
          {/* Promises */}
          <p className={c.subtitle}>На что мы потратим деньги:</p>
          <div className={c.promiseContainer}>
            <div className={c.promiseBox}>
              <img src={editImg} srcSet={`${editImg} 1x, ${editImg2x} 2x`} alt="#" className={c.promiseImage} />
              <p className={c.promiseText}>
                <b>Цель 1.</b>
                {' '}
                Пригласим еще двух редакторов, чтобы глубже прорабатывать темы.
              </p>
            </div>
            <div className={c.promiseBox}>
              <img src={chatImg} srcSet={`${chatImg} 1x, ${chatImg2x} 2x`} alt="#" className={c.promiseImage} />
              <p className={c.promiseText}>
                <b>Цель 2.</b>
                {' '}
                Сделаем комментарии на сайте — только для участников сообщества.
              </p>
            </div>
          </div>
          {/* QA */}
          <p className={c.subtitle}>Вопросы и ответы</p>
          <div className={c.qaContainer}>
            {qaTexts.map((el, i) => (
              <label key={el.id} htmlFor={`qa${i}`} className={c.qaWrapper}>
                <input
                  type="checkbox"
                  name="qa"
                  id={`qa${i}`}
                  className={c.qaInput}
                />
                <div className={c.qaBox}>
                  <p className={c.qaText}>{el.question}</p>
                  <svg
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.18077 8.82967L0.541993 2.20285C-0.107529 1.27496 0.556286 -8.85647e-07 1.68892 -8.36138e-07L10.3111 -4.59251e-07C11.4437 -4.09742e-07 12.1075 1.27496 11.458 2.20285L6.81923 8.82967C6.42113 9.39839 5.57887 9.39838 5.18077 8.82967Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <p
                  className={c.qaHidden}
                  dangerouslySetInnerHTML={{ __html: el.answer }}
                />
              </label>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

DonateScreen.displayName = 'DonateScreen';
export default DonateScreen;
