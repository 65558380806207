import React, { FC } from 'react';
import classes from './ReportPrice.sass';

const RUBLE = <span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif' }}>&#8381;</span>;

interface TProps {
  active_price?: number;
  pre_order_price?: number;
  status?: string;
  color?: string;
  fontSize?: number;
  fontSizeDiscount?: number;
}

const ReportPrice: FC<TProps> = ({
  active_price, pre_order_price, status, color, fontSize, fontSizeDiscount,
}) => {
  const style = color ? { color } : {};

  const renderPrice = () => {
    if (status === 'pre_order') {
      return (
        <>
          <span className={classes.reportPrice__type}>
            Предзаказ
          </span>
          <div className={classes.reportPrice__priceContainer}>
            <span
              className={classes.reportPrice__price}
              style={style}
            >
              {` ${pre_order_price}`}
              {RUBLE}
            </span>
            <span
              className={classes.reportPrice__discount}
              style={{ ...style, fontSize: fontSizeDiscount }}
            >
              {` ${active_price}`}
              {RUBLE}
            </span>
          </div>
        </>
      );
    }
    if (status === 'active' && active_price) {
      return (
        <>
          <span className={classes.reportPrice__type}>
            Стоимость
          </span>
          <div className={classes.reportPrice__priceContainer}>
            <span
              className={classes.reportPrice__price}
              style={style}
            >
              {` ${active_price}`}
              {RUBLE}
            </span>
          </div>
        </>
      );
    }
    return (
      <span
        className={`${classes.reportPrice__type} ${classes.reportPrice__type_free}`}
        style={{ ...style, fontSize }}
      >
        Бесплатно
      </span>
    );
  };

  return (
    <div className={classes.reportPrice} style={{ ...style, fontSize }}>
      {renderPrice()}
    </div>
  );
};

ReportPrice.displayName = 'ReportPrice';
export default ReportPrice;
