import { FetchStatus } from '~/resources';

// Subscription key
const localStorageSubscriptionKey = 'subscription';
export const setSubscriptionKey = (): void => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(localStorageSubscriptionKey, 'true');
  }
};

export const onLocalCheckSubscription = (): boolean =>
  typeof window !== 'undefined'
  && Boolean(localStorage.getItem(localStorageSubscriptionKey));

// Subscriber key
const localStorageSubscriberKey = 'subscriber';
export const setSubscriberKey = (): void => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(localStorageSubscriberKey, 'true');
  }
};

export const onLocalCheckSubscriber = (): boolean =>
  typeof window !== 'undefined'
  && Boolean(localStorage.getItem(localStorageSubscriberKey));

export const isResourceFetchError = (data: any, status: number) : boolean =>
  [FetchStatus.Fetched, FetchStatus.Error].includes(status) && data === undefined;
