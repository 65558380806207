import React, { FC } from 'react';
// import { FetchStatus } from '~/resources';
import { NavLink } from 'react-router-dom';
// import { usePageContext } from '~/components';
import Paragraph from '~/components/typography/Paragraph';
import c from './MenuPage.sass';
import SidebarSceleton from '../Sceletons';

interface TProps {
  isLoading: boolean;
  onClick: () => void;
  isSubscribed?: boolean;
  isAdmin?: boolean;
}

const SubscribePage: FC<TProps> = ({
  isLoading, onClick, isSubscribed = false, isAdmin = false
}) => {
  // const { profile, profileStatus } = usePageContext();
  // const status = profileStatus?.[0] ?? undefined;
  // const isLoading = !status || status === FetchStatus.Fetching;
  // const isAdmin = profile && profile[0] && profile[0].is_admin;
  // const isSubscribed = profile && profile[0] && profile[0].is_subscribed;
  if (isLoading) {
    return <SidebarSceleton />;
  }

  return (
    <>
      <li>
        <NavLink
          exact
          to="/guides"
          className={c.menu_link}
          activeClassName={c.menu_link_active}
          onClick={onClick}
        >
          <Paragraph weight="bold">Гайды</Paragraph>
        </NavLink>
      </li>
      {(isSubscribed || isAdmin) && (
        <li>
          <NavLink
            exact
            to="/video"
            className={c.menu_link}
            activeClassName={c.menu_link_active}
            onClick={onClick}
          >
            <Paragraph weight="bold">Видео</Paragraph>
          </NavLink>
        </li>
      )}
    </>
  );
};

SubscribePage.displayName = 'SubscribePage';
export default SubscribePage;
