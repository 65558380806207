import React, { FC, ReactNode } from 'react';

import clsx from 'clsx';
import classes from './Badge.sass';

type TProps = {
  children: ReactNode;
  primary?: boolean;
};


const Badge: FC<TProps> = (props: TProps) => {
  const { children, primary = false } = props;

  return (
    <div className={clsx(classes.badge, { [classes.badge__primary]: primary })}>{children}</div>
  );
}

Badge.displayName = 'Badge';
export default Badge;
