import { useEffect, useRef, useState } from 'react';
import disableScroll from '~/utils/disableScroll';
import { PAGE_HEADER_ID } from '~/utils/ids';
import useWindowDimensions from '~/utils/useWindowDimensions';
import { SCREEN_MD } from '~/utils/breakpoints';

const PAGE_HEADER_DEFAULT_Z_INDEX = '1000';
const PAGE_HEADER_CUSTOM_Z_INDEX = '1';

export default function useStickyTableOfContents() {
  const [isOpenStickyTableOfContents, setOpenStickyTableOfContents] = useState(false);

  const pageHeader = useRef<HTMLElement | null>(null);
  const { width: windowWidth } = useWindowDimensions();

  useEffect(() => {
    pageHeader.current = document.getElementById(PAGE_HEADER_ID);
  }, []);

  const reset = () => {
    disableScroll(false);

    if (!pageHeader.current) return;

    pageHeader.current.style.zIndex = PAGE_HEADER_DEFAULT_Z_INDEX;
  };

  useEffect(() => {
    disableScroll(isOpenStickyTableOfContents);

    if (!pageHeader.current) return;

    pageHeader.current.style.zIndex = isOpenStickyTableOfContents
      ? PAGE_HEADER_CUSTOM_Z_INDEX
      : PAGE_HEADER_DEFAULT_Z_INDEX;
  }, [isOpenStickyTableOfContents]);

  useEffect(() => {
    if (windowWidth < SCREEN_MD) {
      reset();
    }
  }, [windowWidth]);

  useEffect(() => () => {
    reset();
  }, []);

  return { isOpenStickyTableOfContents, setOpenStickyTableOfContents };
}
