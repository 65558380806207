import React, { FC, HTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';

import { Range } from '~/utils/types/Range';
import c from './Title.sass';

type TProps = {
  level: Range<1, 5>
  children: ReactNode
} & HTMLAttributes<HTMLElement>

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4'
const Title: FC<TProps> = ({
  level, children, className, ...rest
}) => {
  const classNameString = clsx(c.title, c[`title__level_${level}`], className);

  const Heading = `h${level}` as HeadingTag;

  return (
    <Heading {...rest} className={classNameString}>
      {children}
    </Heading>
  );
}

Title.displayName = 'Title';
export default Title;
