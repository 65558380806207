import React, { FC } from 'react';
import { useParams } from 'react-router';
import { Page, Paginator } from '~/components';
import TagPostList from '~/components/post/TagPostList';
import config from '~/config';
import Helmeter from '~/screens/Helmeter';
import JsonLd from '~/screens/jsonLd';
import Banner from '~/components/Banner';

type Params = {
    tag: string
}

const TagScreen: FC = () => {
  const { tag } = useParams<Params>();

  const canonicalLink = `${config('APP_HOST')}/${`tag/${tag}`}`;

  return (
    <>
      <Banner />
      <Page>
        <Helmeter link={[{ rel: 'canonical', href: canonicalLink }]} />
        <JsonLd name={tag} />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <TagPostList
              slug={tag}
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
            />
          )}
        </Paginator>
      </Page>
    </>
  );
};

TagScreen.displayName = 'TagScreen';
export default TagScreen;
