import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import useSubscription from '~/resources/useSubscription';
import { SubscriptionPlan } from '~/resources/models';
import useSubscriptionFlow from '~/resources/useSubscriptionFlow';
import { SubscriptionStatus } from '~/utils/purshase-event';
import useProfile from '~/resources/useProfile';
import disableScroll from '~/utils/disableScroll';
import Title from '~/components/typography/Title';
import Button from '~/components/Button/Button';
import Paragraph from '~/components/typography/Paragraph';
import SubscriptionFormNew from '~/components/PaidSubscription/ui/SubscriptionFormNew';
import { FetchStatus } from '~/resources';
import useWindowDimensions from '~/utils/useWindowDimensions';
import { SCREEN_MD } from '~/utils/breakpoints';
import { 
  AlertModalType, AuthModalFrom, AuthModalState, usePageContext,
} from '../page/context.page';
import { SubscriptionFlowStep, SubscriptionType } from '../page/context.subscription-flow';

import c from './Paywall.sass';
import Loader from '../Loader';
import { SubscriptionCheck } from '../PaidSubscription/SubscriptionCheck';
import { SubscriptionPayment } from '../PaidSubscription/SubscriptionPayment';
import { SubscriptionStatusModal } from '../PaidSubscription/SubscriptionStatusModal';
import { ymReachGoal } from '~/utils/ym';

export interface TProps {
  isReady?: boolean;
  postSlug?: string;
}

const Paywall: FC<TProps> = ({ isReady, postSlug }) => {
  const bannerRef = useRef(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const { url } = useRouteMatch();
  const { width: windowWidth } = useWindowDimensions();
  const {
    authModal, profile, auth, alertModal, profileStatus,
  } = usePageContext();
  const { fetchBy: fetchPlans, data: plans } = useSubscription();
  const {
    buySubscription,
    subscriptionFlowVal,
    cancelSubscription,
    checkPayment,
  } = useSubscriptionFlow();
  const [finalStatus, setFinalStatus] = useState<SubscriptionStatus>();
  const step = subscriptionFlowVal && subscriptionFlowVal.step;
  const plan = subscriptionFlowVal && subscriptionFlowVal.plan;
  const [orderId, setOrderId] = useState<number>();
  const { data: profileData, fetchBy: fetchProfile } = useProfile();

  const plansSorted = plans?.sort((a, b) => b.id - a.id);

  const profileStatusValue = profileStatus?.[0] ?? undefined;
  const isLoading = !profileStatusValue || profileStatusValue === FetchStatus.Fetching;
  const isProfile = profile && profile[0];

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    const handleIntersection = (entries: any) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        ymReachGoal('paywall_shown');

        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      }
    };

    observerRef.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });

    if (bannerRef.current) {
      observerRef.current.observe(bannerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!profileData || !profile || !profile[1]) { return; }
    if (alertModal) {
      alertModal[1]({ alertType: AlertModalType.NONE });
    }
    disableScroll(false);
    profile[1](profileData);
  }, [profileData]);

  const onLogin = () => {
    if (authModal) {
      authModal[1]({ authModalState: AuthModalState.CHECK, authModalFrom: AuthModalFrom.PAYWALL });
    }
    disableScroll(true);
  };

  const buy = (selectedPlan: SubscriptionPlan) => {
    if (!selectedPlan) { return; }
    buySubscription(selectedPlan, SubscriptionType.NEW, url);
  };

  const onStatus = (status: SubscriptionStatus) => {
    setFinalStatus(status);
    cancelSubscription();
  };

  const onSuccessPayment = (oId: number) => {
    setOrderId(oId);
    checkPayment();
  };

  const cancel = () => {
    if (
      finalStatus === SubscriptionStatus.PAYMENT_CHECK_PROCESSED
      || finalStatus === SubscriptionStatus.ALREADY_SUBSCRIBED
    ) {
      fetchProfile();
      auth?.clearCache();
      window.scrollTo({ behavior: 'smooth', top: 0 });
    }
    setFinalStatus(undefined);
    cancelSubscription();
  };

  const renderPayment = () => (step === SubscriptionFlowStep.PAYMENT && plan
    ? (
      <SubscriptionPayment
        plan={plan}
        promocode=""
        onError={onStatus}
        onSuccess={onSuccessPayment}
        onClose={cancel}
        postSlug={postSlug}
      />
    )
    : null
  );

  const renderCheck = () => (step === SubscriptionFlowStep.CHECK && orderId && (
    <SubscriptionCheck
      orderId={orderId}
      onClose={cancel}
      onCheck={onStatus}
    />
  ));

  const renderStatus = () => (finalStatus && (
    <SubscriptionStatusModal status={finalStatus} onClose={cancel} />
  ));

  const paywallTitle = windowWidth > SCREEN_MD || !windowWidth
    ? 'Подпишитесь, чтобы дочитать статью и получить полный доступ ко\xa0всем материалам и\xa0закрытым мероприятиям'
    : 'Подпишитесь, чтобы получить доступ к материалам Reminder';

  return (
    <div className={c.alertwrapper} ref={bannerRef}>
      <div className={c.gradient} />
      {
        isReady ? (
          <>
            <div className={c.paywall_wrapper}>
              <Title level={3}>
                {paywallTitle}
              </Title>
              {!isLoading && !isProfile && (
                <Button variant="secondary" size="large" className={c.paywall_wrapper__btn_has_subscription} onClick={onLogin}>
                  <Paragraph weight="bold">У меня есть подписка</Paragraph>
                </Button>
              )}
              <SubscriptionFormNew
                plans={plansSorted ?? []}
                onSubmit={buy}
                itemClassName={c.paywall_subscription_item}
              />
            </div>
            {renderPayment()}
            {renderCheck()}
            {renderStatus()}
          </>
        ) : (<Loader />)
      }
    </div>
  );
};

Paywall.displayName = 'Paywall';
Paywall.defaultProps = {
  isReady: false,
  postSlug: undefined,
};

Paywall.displayName = 'Paywall';
export default Paywall;
