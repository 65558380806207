import React, { FC, useEffect } from 'react';
import { useConfig } from '~/resources';

interface TProps {
    name: string,
}

const ServiceText: FC<TProps> = ({ name }) => {
  const { data: config, fetchBy } = useConfig();

  useEffect(() => {
    fetchBy();
  }, []);

  const text = config ? config.service_texts[name] : '';
  if (!text) {
    return null;
  }
  return (
    <span
      style={{ whiteSpace: 'pre-wrap' }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

ServiceText.displayName = 'ServiceText';
export default ServiceText;
