import React, { FC } from 'react';

const ArrowDownIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8108_1718)">
        <path
          d="M11.9999 15.0537L6.34619 9.39997L7.16144 8.58472L11.9999 13.423L16.8384 8.58472L17.6537 9.39997L11.9999 15.0537Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8108_1718">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

ArrowDownIcon.displayName = 'ArrowDownIcon';
export default ArrowDownIcon;
