import { Resource } from './useResource';

export const INITIAL_CACHE = '__INITIAL__CACHE__';
export const AUTH_KEYS = ['token_type', 'access_token', 'expires_in', 'refresh_token'];

type CacheStore<T> = {
  [key: string]: T,
}

export default class Auth {
  DATA: CacheStore<Resource<any>> = {}

  AUTH = {}

  promises: CacheStore<Promise<void>> = {};

  constructor() {
    if (typeof window !== 'undefined') {
      this.DATA = window[INITIAL_CACHE] ? window[INITIAL_CACHE] : {};
      AUTH_KEYS.forEach((key) => {
        if (localStorage.hasOwnProperty(key)) {
          this.AUTH[key] = localStorage.getItem(key);
        }
      });
    }
  }

  setAuth = (auth = {}) => {
    AUTH_KEYS.forEach((key) => {
      if (auth[key] !== undefined) {
        if (typeof window !== 'undefined') {
          localStorage.setItem(key, auth[key]);
        }
        this.AUTH[key] = auth[key];
      }
    });
    return this.AUTH;
  }

  resetAuth = () => {
    AUTH_KEYS.forEach((key) => {
      if (localStorage.hasOwnProperty(key)) {
        localStorage.removeItem(key);
      }
    });
  }

  getCache = (): CacheStore<Resource<any>> => this.DATA;

  setCache = (cacheKey: string, data: any): void => {
    this.DATA[cacheKey] = data;
  };

  clearCache = (): void => {
    this.DATA = {};
  }
}
