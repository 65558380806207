import useResource, { ResourceResult, Resource, Fetcher } from './useResource';
import { Config } from './models';

import config from '../config';
import anyFetch from './anyFetch';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}/config`;

type QueryParams = {
    [key: string]: string | string[]
};

const configFetcher: Fetcher<Config> = async (
  params = {},
  opts = {},
): Promise<Resource<Config>> => {
  const res = await anyFetch(PATH(), opts);
  const data = await res.json();
  return data as Resource<Config>;
};

// ToDo: remove config into context
const useConfig = (key = 'config'): ResourceResult<Config> =>
  useResource({}, {}, configFetcher, key);

export default useConfig;

export const fetchSSRConfig = async (auth: Auth, opts: RequestInit = {}): Promise<any> => {
  const res = await configFetcher({}, opts);
  auth.setCache('config', res);
};

const generateCacheKey = (params: QueryParams = {}, slug = 'posts') =>
  `${slug}${JSON.stringify(params)}`;
