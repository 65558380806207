import React, { FC, useEffect } from 'react';

import {
  Page, Paginator, ReportsList,
} from '~/components';
import { useConfig } from '~/resources';
import config from '~/config';
import Banner from '~/components/Banner';
import Helmeter from './Helmeter';

const ReportsScreen: FC = () => {
  const { fetchBy } = useConfig();
  useEffect(() => {
    fetchBy();
  }, []);

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          description="Отчеты Reminder — это сборники материалов, в которых глубоко исследуется тема и даются практические советы."
          metaDescription="Отчеты Reminder — это сборники материалов, в которых глубоко исследуется тема и даются практические советы."
          img={`${config('APP_HOST')}/public/reports_og.png`}
        />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <ReportsList
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
            />
          )}
        </Paginator>
      </Page>
    </>
  );
};

ReportsScreen.displayName = 'ReportsScreen';
export default ReportsScreen;
