import React, { FC } from 'react';

const SuccessIcon: FC = () => (

  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M48 8C26 8 8 26 8 48C8 70 26 88 48 88C70 88 88 70 88 48C88 26 70 8 48 8ZM48 80C30 80 16 66 16 48C16 30 30 16 48 16C66 16 80 30 80 48C80 66 66 80 48 80Z" fill="url(#paint0_linear_6093_1572)" />
    <path d="M41.6894 64.2056C40.16 65.7576 37.6699 65.8001 36.0885 64.3011L26.9186 55.6092C25.3092 54.0837 25.2479 51.5401 26.782 49.9389L26.861 49.8564C28.3983 48.2518 30.9488 48.2078 32.5406 49.7585L36.0483 53.1757C37.6394 54.7258 40.1886 54.6826 41.7263 53.0795L59.2489 34.8108C60.7494 33.2465 63.2229 33.1619 64.8266 34.6202L64.9192 34.7044C66.6003 36.2329 66.6722 38.8531 65.0774 40.4715L41.6894 64.2056Z" fill="url(#paint1_linear_6093_1572)" />
    <defs>
      <linearGradient id="paint0_linear_6093_1572" x1="88" y1="55.2131" x2="7.99014" y2="55.0904" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F85B2E" />
        <stop offset="1" stopColor="#B688E4" />
      </linearGradient>
      <linearGradient id="paint1_linear_6093_1572" x1="68" y1="52.6557" x2="23.9946" y2="52.5709" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F85B2E" />
        <stop offset="1" stopColor="#B688E4" />
      </linearGradient>
    </defs>
  </svg>
);

SuccessIcon.displayName = 'SuccessIcon';
export default SuccessIcon;
