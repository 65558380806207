import React, { FC } from 'react';

import c from './AuthPage.sass';

interface TProps {
  links: {
    [key: string]: string,
  },
  onClick: (key: string) => void
}

const AuthPage: FC<TProps> = ({
  links, onClick,
}) => (
  <div className={c.auth}>
    <div className={c.auth_tit}>
      Прежде чем купить подписку, пожалуйста, зарегистрируйтесь.
    </div>
    {links ? (
      <ul>
        {Object.keys(links).map((key) => (
          <li key={key} className={c.auth_icon}>
            <button
              type="button"
              className={`${c.auth_btn} ${c[key]}`}
              onClick={() => onClick(key)}
            >
              {key.toLowerCase() === 'facebook' ? 'FB' : key.toLowerCase()}
              {/* <span className={c[key]} /> */}
            </button>
          </li>
        ))}
      </ul>
    ) : (
      <>Server Error</>
    )}
    <div className={c.auth_notice}>
      А если вы уже покупали подписку,
      то доступ к статьям откроется автоматически,
      когда вы войдете.
    </div>
  </div>
);

AuthPage.displayName = 'AuthPage';
export default AuthPage;
