import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import config from '~/config';
import DonateUnsubscribeForm from '~/components/Donate/DonateUnsubscribeForm';

import logo from '~/images/logo.svg';

import c from '~/components/Donate/Donate.sass';
import Helmeter from './Helmeter';

const DonateUnsubscribeScreen: FC = () => {
  const { pathname } = useLocation();
  const canonicalLink = pathname.match(/donate/) !== null ? `${config('APP_HOST')}/donate` : `${config('APP_HOST')}/subscription`;

  // Костыль для асинхронных запросов, чтобы при ssr к картинкам в src добавлялся префикс /static
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);
  if (!show) return null;

  return (
    <>
      <Helmeter
        title="Поддержите Reminder. Инвестируйте в себя."
        metaTitle="Поддержите Reminder. Инвестируйте в себя."
        link={[
          { rel: 'canonical', href: canonicalLink },
        ]}
      />
      {/* Title */}
      <div className={c.container} style={{ height: '100vh' }}>
        <div className={c.wrap}>
          <a href="/">
            <img src={logo} className={c.logo} alt="#" />
          </a>
          <div className={c.titleContainer}>
            <div className={c.titleBox}>
              <p className={c.title}>Личный кабинет.</p>
            </div>
          </div>
          {/* Form */}
          <div className={c.description}>
            Чтобы зайти в профиль и отписаться, напомните email
          </div>
          <DonateUnsubscribeForm />
        </div>
      </div>
    </>
  );
};

DonateUnsubscribeScreen.displayName = 'DonateUnsubscribeScreen';
export default DonateUnsubscribeScreen;
