import React, { FC } from 'react';

const ProfileIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7ZM17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM21.5426 20C21.744 20.6412 21.8826 21.3103 21.9511 22H19.9387H4.06212H2.0498C2.11828 21.3103 2.25683 20.6412 2.45823 20C3.7325 15.9429 7.52279 13 12.0004 13C16.4781 13 20.2684 15.9429 21.5426 20ZM12.0004 15C15.3574 15 18.2327 17.0686 19.4194 20H4.5815C5.76812 17.0686 8.6435 15 12.0004 15Z"
        fill="currentColor"
      />
    </svg>
  );
}

ProfileIcon.displayName = 'ProfileIcon';
export default ProfileIcon;
