import React, { FC } from 'react';

const Giftcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="interface-icons">
      <path id="Vector" d="M10.6582 8L8.52347 7.58345C8.15216 7.51757 8 7.40794 8 7.14477C8 7.0571 8.02158 6.96925 8.06587 6.85962L8.5882 5.21486C8.67563 4.9299 8.78463 4.79831 8.98108 4.79831C9.09008 4.79831 9.22067 4.84205 9.3944 4.9299L11.3781 5.85102L11.1158 3.65794C11.0943 3.59224 11.0942 3.52635 11.0942 3.43869C11.0942 3.10963 11.2248 3 11.6393 3L13.3607 3C13.7309 3 13.9058 3.13159 13.9058 3.46047L13.883 3.65794L13.6219 5.85102L15.5829 4.9299C15.7578 4.84205 15.8883 4.79831 15.9974 4.79831C16.1711 4.79831 16.3028 4.9299 16.3891 5.21486L16.9341 6.85962C16.9784 6.96925 17 7.0571 17 7.14477C17 7.40794 16.8467 7.51757 16.4765 7.58345L14.3191 8L10.6582 8Z" fill="white" />
      <path id="Subtract" fillRule="evenodd" clipRule="evenodd" d="M18.6064 14H9.84909L3.51233 20.3368C3.87845 20.7439 4.40932 21 5 21H11.6064L18.6064 14ZM15.8491 21H19C20.1046 21 21 20.1046 21 19V15.8491L15.8491 21ZM3 14H5.60645L3 16.6064V14Z" fill="white" />
      <path id="Subtract_2" fillRule="evenodd" clipRule="evenodd" d="M10.6064 9H5C3.89543 9 3 9.89543 3 11V12L7.60645 12L10.6064 9ZM11.8491 12L21 12V11C21 9.89543 20.1046 9 19 9H14.8491L11.8491 12Z" fill="white" />
    </g>
  </svg>
);

Giftcon.displayName = 'Giftcon';
export default Giftcon;
