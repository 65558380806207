import React, { FC, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Banner from '~/components/Banner';
import useAuthor from '~/resources/useAuthor';
import Helmeter from './Helmeter';
import config from '~/config';
import { Loader, Page, Paginator } from '~/components';
import JsonLd from './jsonLd';
import AuthorPage from '~/components/page/AuthorPage';
import { isResourceFetchError } from '~/components/Subscription/utils';
import AuthorPostList from '~/components/post/AuthorPostList';

const AuthorScreen: FC = () => {
  const { authorId } = useParams<{ authorId: string }>();
  const { data: author, fetchBy, status } = useAuthor(authorId);

  const canonicalLink = `${config('APP_HOST')}/author/${authorId}`;

  useEffect(() => {
    fetchBy();
  },[]);

  if (!author) {
    return isResourceFetchError(author, status) ? <Redirect to="/404" /> : <Loader />;
  }

  return (
    <>
    <Banner />
    <Helmeter
      title={'Автор'}
      description={'Информация об авторе'}
      metaTitle={`${author?.name} — Reminder`}
      metaDescription={'Информация об авторе'}
    //   img={post?.image?.src}
      type="author"
      link={[
        { rel: 'canonical', href: canonicalLink },
      ]}
      srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
    />
    <JsonLd name={author?.name || ''}/>
    <Page hideSeparator>
        {author ? <AuthorPage author={author}/> : <Loader/>}
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <AuthorPostList
              authorId={authorId}
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
            />
          )}
        </Paginator>
    </Page>
  </>
  );
};

AuthorScreen.displayName = 'AuthorScreen';
export default AuthorScreen;
