import React, {
  ChangeEvent, FC, FormEvent, useEffect, useRef, useState,
} from 'react';
import config from '~/config';
import anyFetch from '~/resources/anyFetch';
import validateEmail from '~/utils/email';
import c from './DonateForm.sass';
import DonateModal from './DonateModal';

declare global {
  interface Window {
    YooMoneyCheckoutWidget: any
  }
}

interface DonateFormData {
  email: string
  firstName: string
  lastName: string
  amount: string
  isAuto: boolean
}

const keys = {
  LOADING: 0,
  PAYMENT: 1,
  SUCCESS: 2,
  IN_PAY: 3,
  FAIL: 4,
  SOMETHING_WENT_WRONG: 5,
};
const states = {
  [keys.LOADING]: { key: keys.LOADING },
  [keys.PAYMENT]: { key: keys.PAYMENT },
  [keys.SUCCESS]: { key: keys.SUCCESS },
  [keys.IN_PAY]: { key: keys.IN_PAY },
  [keys.FAIL]: { key: keys.FAIL },
  [keys.SOMETHING_WENT_WRONG]: { key: keys.SOMETHING_WENT_WRONG },
};
const URL = `${config('API_HOST')}/donates`;
const amountValues = ['500', '1000', '4000'];

const DonateForm: FC = () => {
  const [formData, setFormData] = useState<DonateFormData>({
    firstName: '',
    lastName: '',
    email: '',
    amount: amountValues[0],
    isAuto: true,
  });
  const [state, setState] = useState(states[keys.LOADING]);
  const [donate, setDonate] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const customAmountRef = useRef<HTMLInputElement>(null);
  const customAmountInputRef = useRef<HTMLInputElement>(null);
  const defaultAmountRef = useRef<HTMLInputElement>(null);

  const processAmount = (amount: string) => {
    let processedAmount = amount;
    if (amount === '') {
      [processedAmount] = amountValues;
      if (defaultAmountRef.current) {
        defaultAmountRef.current.checked = true;
      }
    }
    return Number(processedAmount) * 100;
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>, value?: unknown) => {
    const { name, value: eventValue } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value ?? eventValue }));
  };

  const sendFormData = async (body: any) => {
    const res = await anyFetch(URL, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res.ok) {
      setState(states[keys.SOMETHING_WENT_WRONG]);
      throw new Error(`Donate fetch error. Status: ${res.status}`);
    }
    const json = await res.json();
    if (json.error) {
      setState(states[keys.SOMETHING_WENT_WRONG]);
      throw new Error(`Donate fetch data error: ${json.error}`);
    }
    return json;
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState(states[keys.LOADING]);
    const {
      firstName, lastName, email, amount, isAuto,
    } = formData;
    if (!validateEmail(email)) {
      setIsEmailInvalid(true);
      throw new Error('Email is invalid');
    }
    setOpenModal(true);
    const donateResponse = await sendFormData({
      first_name: firstName.trim() || null,
      last_name: lastName.trim() || null,
      email: email.toLowerCase(),
      amount: processAmount(amount),
      is_auto: isAuto,
    });
    setState(states[keys.PAYMENT]);
    setDonate(donateResponse);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const urlParams = new URLSearchParams(window.location.search);
    const donateId = urlParams.get('donate_id');
    if (!donateId) return;
    window.history.pushState(
      {},
      document.title,
      document.location.origin + document.location.pathname,
    );
    setOpenModal(true);
    setState(states[keys.LOADING]);
    setTimeout(async () => {
      const res = await anyFetch(`${URL}/${donateId}/status`);
      if (!res.ok) {
        setState(states[keys.SOMETHING_WENT_WRONG]);
        throw new Error(`Donate fetch hook error. Status: ${res.status}`);
      }
      const json = await res.json();
      if (json.error) {
        setState(states[keys.SOMETHING_WENT_WRONG]);
        throw new Error(`Donate fetch hook data error: ${json.error}`);
      }
      switch (json.data.status) {
        case 'in_pay': {
          setState(states[keys.IN_PAY]);
          break;
        }
        case 'failed': {
          setState(states[keys.FAIL]);
          break;
        }
        case 'pending':
        case 'processed': {
          setState(states[keys.SUCCESS]);
          break;
        }
        default: {
          setState(states[keys.SOMETHING_WENT_WRONG]);
          break;
        }
      }
    }, 5000);
  }, []);

  useEffect(() => {
    if (!donate) return;
    const checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token: donate.confirmation.confirmation_token,
      return_url: `${window.location.href}?donate_id=${donate.data.id}`,
      error_callback(error: any) {
        console.log(`Yookassa error: ${error}`);
      },
    });
    checkout.render('payment-form');
  }, [donate]);

  return (
    <>
      <DonateModal
        keys={keys}
        stateKey={state.key}
        open={openModal}
        setOpenModal={setOpenModal}
      />
      <form
        className={c.form}
        onSubmit={onSubmit}
      >
        <div className={c.boxRadio}>
          <label htmlFor="auto1" className={c.label}>
            <input
              type="radio"
              id="auto1"
              name="isAuto"
              className={c.radio}
              defaultChecked
              onChange={(e) => {
                onChange(e, true);
                customAmountInputRef.current?.setAttribute('min', '150');
              }}
            />
            <p className={c.inputRadio}>Каждый месяц</p>
          </label>
          <label htmlFor="auto2" className={c.label}>
            <input
              type="radio"
              id="auto2"
              name="isAuto"
              className={c.radio}
              onChange={(e) => {
                onChange(e, false);
                customAmountInputRef.current?.setAttribute('min', '1');
              }}
            />
            <p className={c.inputRadio}>Один раз</p>
          </label>
        </div>
        <div className={c.boxRadio}>
          <label htmlFor="amount" className={c.label}>
            <input
              ref={customAmountRef}
              type="radio"
              id="amount"
              name="amount"
              className={c.radio}
              onChange={onChange}
            />
            <input
              ref={customAmountInputRef}
              type="number"
              name="amount"
              placeholder="Другая сумма"
              aria-label="Другая сумма"
              onChange={onChange}
              onFocusCapture={onChange}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onFocus={() => {
                if (customAmountRef.current) {
                  customAmountRef.current.checked = true;
                }
              }}
              inputMode="numeric"
              pattern="\d*"
              className={c.inputRadio}
              min="150"
            />
          </label>
          {amountValues.map((el, i) => (
            <label key={i} htmlFor={`amount${i}`} className={c.label}>
              <input
                {...(i === 0 && { ref: defaultAmountRef })}
                type="radio"
                value={el}
                id={`amount${i}`}
                name="amount"
                className={c.radio}
                defaultChecked={i === 0}
                onFocus={onChange}
                onChange={onChange}
              />
              <p className={c.inputRadio}>
                {el}
                {' '}
                рублей
              </p>
            </label>
          ))}
        </div>
        <div className={c.boxEmail}>
          <input
            type="email"
            name="email"
            placeholder="Введите e-mail"
            aria-label="Email"
            value={formData.email}
            onChange={onChange}
            className={isEmailInvalid ? `${c.inputText} ${c.inputText_invalid}` : c.inputText}
            onInput={() => setIsEmailInvalid(false)}
            required
          />
          {isEmailInvalid && <span className={c.error}>Неправильно ввели адрес почты</span>}
        </div>
        <div className={c.boxText}>
          <input
            name="firstName"
            placeholder="Имя"
            aria-label="Имя"
            value={formData.firstName}
            onChange={onChange}
            className={c.inputText}
          />
          <input
            name="lastName"
            placeholder="Фамилия"
            aria-label="Фамилия"
            value={formData.lastName}
            onChange={onChange}
            className={c.inputText}
          />
        </div>
        <button
          type="submit"
          aria-label="Поддержать"
          className={`${c.inputText} ${c.submit}`}
        >
          Поддержать
        </button>
      </form>
    </>
  );
};

DonateForm.displayName = 'DonateForm';
export default DonateForm;
