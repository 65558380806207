import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Post } from '~/resources/models';
import c from './PreviewNews.sass';

type TProps = {
  post: Post;
};

const PreviewNews: FC<TProps> = ({ post }) => {
  const {
    topic, title, slug,
  } = post;

  const urlTopic = topic && `/topic/${post.topic.slug}`;
  const urlNews = `/post/${slug}`;

  return (
    <div>
      <Link to={urlTopic} className={c.topic}>
        {topic.title}
      </Link>
      <Link to={urlNews} className={c.title}>
        {title}
      </Link>
    </div>
  );
};

PreviewNews.displayName = 'PreviewNews';
export default PreviewNews;
