import React, { FC, useEffect } from 'react';
import Carousel from '~/components/carousel/Carousel';

import useSubscriptionReviews from '~/resources/useSubscriptionReviews';
import Loader from '~/components/Loader';
import ReviewCard from '~/components/carousel/ui/ReviewCard';
import clsx from 'clsx';
import c from './SubscriptionReviews.sass';

type TProps = {
  withLoader?: boolean;
  className?: string;
}

const SubscriptionReviews: FC<TProps> = ({ withLoader = true, className}) => {
  const { data: reviews, fetchBy } = useSubscriptionReviews();

  useEffect(() => {
    fetchBy();
  }, []);

  if (!reviews) {
    if (withLoader) return (<Loader />);

    return null;
  }

  return (
    <Carousel title="О нас говорят" className={clsx(c.reviews_carousel, className)}>
      {reviews.map((review) => <ReviewCard review={review} key={review.id} />)}
    </Carousel>
  );
}

SubscriptionReviews.displayName = 'SubscriptionReviews';
export default SubscriptionReviews;
