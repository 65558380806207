import React, {
  ChangeEvent, FC, useEffect, useRef, useState,
} from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import config from '~/config';
import CloseIcon from '~/components/Subscription/ModalSubscription/CloseIcon';
import Loader from '~/components/Loader';
import { keys, sendPurchaseEvent, states } from '~/utils/purshase-event';
import validateEmail from '~/utils/email';
import { SubscriptionPlan } from '~/resources/models';
import { Resource } from '~/resources';
import useGiftSubscription from '~/resources/useGiftSubscription';
import numberWithSpaces from '~/utils/price';
import disableScroll from '~/utils/disableScroll';
import { AlertModalType, usePageContext } from '../page/context.page';
import c from '../PaidSubscription/PaidSubscriptionForm.sass';
import c1 from './Gift.sass';

interface GiftRequest {
  email?: string;
}
interface CheckFormData {
  email: string;
}

const convertResourceToSubscriptionPlan = (resource: Resource<any>): SubscriptionPlan => {
  const { data } = resource;

  return {
    id: data.id,
    title: data.title,
    description: data.description,
    price: data.price,
    slug: data.slug,
    period: data.period,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
    full_price: data.full_price,
    type: data.type,
  };
};

const GiftForm: FC = () => {
  const { fetchBy, data: plan } = useGiftSubscription();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();
  const [formData, setFormData] = useState<CheckFormData>({
    email: '',
  });
  const [isEmailValid, setIsEmailValid] = useState(false);
  const { alertModal } = usePageContext();

  const [order, setOrder] = useState<any>();
  const [isPaymentFormReady, setIsPaymentFormReady] = useState(false);
  const [state, setState] = useState(states[keys.SUBMIT]);
  const checkRef = useRef(false);
  const [originalViewportMetaContent] = useState<string | null>(null);
  const history = useHistory();

  useEffect(() => {
    fetchBy();
  }, []);

  useEffect(() => {
    if (!plan) { return; }
    setSelectedPlan(plan);
  }, [plan]);

  const onClose = () => {
    setOpen(false);
    setState(states[keys.SUBMIT]);
    setIsPaymentFormReady(false);
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta && originalViewportMetaContent) {
      viewportMeta.setAttribute('content', originalViewportMetaContent);
    }
    history.push('/');
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('subscription_order_id');
    const subscriptionPlanId = urlParams.get('subscription_plan_id');
    const subscriptionPlanTitle = urlParams.get('subscription_plan_title');
    const subscriptionPlanPrice = urlParams.get('subscription_plan_price');

    const sendEvent = () => {
      if (
        !orderId
        || !subscriptionPlanPrice
        || !subscriptionPlanId
        || !subscriptionPlanTitle
      ) return;
      sendPurchaseEvent({
        orderId,
        revenue: subscriptionPlanPrice,
        product: {
          id: subscriptionPlanId,
          price: subscriptionPlanPrice,
          name: subscriptionPlanTitle,
        },
      });
    };

    if (orderId) {
      checkRef.current = true;
      setIsPaymentFormReady(false);
      window.history.pushState(
        {},
        document.title,
        document.location.origin + document.location.pathname,
      );
      setTimeout(() => {
        fetch(`${config('API_HOST')}/subscriptions/${orderId}/status`)
          .then((response) => response.json())
          .then((response) => {
            if (response.error) {
              console.log(response.error);
              return;
            }
            switch (response.data.status) {
              case 'pending':
              case 'processed': {
                sendEvent();
                if (alertModal) {
                  setOpen(false);
                  alertModal[1]({
                    alertType: AlertModalType.SUBSCRIPTION_GIFT_SUCCESS,
                  });
                  disableScroll(true);
                }
                break;
              }
              case 'failed':
              default: {
                if (alertModal) {
                  setOpen(false);
                  alertModal[1]({
                    alertType: AlertModalType.SUBSCRIPTION_GIFT_FAIL,
                  });
                  disableScroll(true);
                }
                break;
              }
            }
          });
      }, 5000);
      setOpen(true);
      setState(states[keys.LOADING]);
    }
  }, []);

  const showPaymentForm = (email: string) => {
    setLoading(true);
    setState(states[keys.PAYMENT]);
    setOpen(true);

    const body: GiftRequest = { email };
    fetch(`${config('API_HOST')}/gift`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then((response) => response.json()).then((response) => {
      if (!response.error) {
        setOrder(response);
      } else {
        setState(states[keys.SOMETHING_WENT_WRONG]);
      }
    });
  };

  const onSubmit = (e: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement> | null) => {
    e?.preventDefault();
    const {
      email,
    } = formData;

    if (!validateEmail(email)) {
      setIsEmailValid(false);
      throw new Error('Email is invalid');
    }
    showPaymentForm(email);
  };

  useEffect(() => {
    if (!order || !selectedPlan) return;
    switch (order.confirmation.type) {
      case 'redirect': {
        window.location.href = order.confirmation.confirmation_url;
        break;
      }
      case 'embedded': {
        const subscriptionPlanPrice = selectedPlan.price;
        const subscriptionPlanId = selectedPlan.id;
        const subscriptionPlanTitle = selectedPlan.title;
        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: order.confirmation.confirmation_token,
          return_url: `${window.location.href}?subscription_order_id=${order?.data.id}&subscription_plan_price=${subscriptionPlanPrice}&subscription_plan_id=${subscriptionPlanId}&subscription_plan_title=${subscriptionPlanTitle}`,
          embedded_3ds: true,
          error_callback(error: any) {
            console.log(error);
          },
        });
        setIsPaymentFormReady(true);
        checkout.render('payment-form');
        break;
      }
      case 'free_of_charge': {
        setState(states[keys.FREE_SUCCESS]);
        break;
      }
      default:
    }
  }, [order]);

  if (!selectedPlan) {
    return (<Loader />);
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>, value?: unknown) => {
    const { name, value: eventValue } = e.target;
    const newValue = value ?? eventValue;

    setFormData((prev) => ({ ...prev, [name]: newValue }));
    if (name === 'email') {
      const isValid = validateEmail(newValue.toString());
      setIsEmailValid(isValid);
    }
  };

  const getPrice = () => {
    const fullPrice = selectedPlan.full_price
      ? (
        <div className={c1.form_title_crossed}>
          <div className={c1.form_title_crossed_line} />
          {numberWithSpaces(selectedPlan.full_price / 100)}
        </div>
      )
      : (<></>);
    const price = selectedPlan?.price
      ? (
        <div>
          {numberWithSpaces(selectedPlan?.price / 100)}
          ₽
        </div>
      )
      : (<></>);
    return (
      <div className={c1.prices}>
        {fullPrice}
        {price}
      </div>
    );
  };

  const getType = selectedPlan?.type === 'monthly' ? ' на месяц' : ' на год'; // —

  return (
    <>
      <form
        className={c1.form}
        onSubmit={onSubmit}
      >
        <div className={c1.form_title}>
          <p>
            Подарочный сертификат
            {getType}
          </p>
          {getPrice()}
        </div>
        <div className={c1.form_item}>
          <input
            type="email"
            name="email"
            placeholder="Ваша почта"
            aria-label="Email"
            value={formData.email}
            onChange={onChange}
            className={
              isEmailValid
                ? c1.inputText
                : `${c1.inputText} ${c1.inputText_invalid}`
            }
            onInput={() => setIsEmailValid(true)}
            required
          />
          <div className={c1.info}>Пришлем чек и сертификат вам на почту</div>
        </div>

        <button
          disabled={loading || !isEmailValid}
          type="submit"
          aria-label="Подарить подписку"
          className={
            (isEmailValid)
              ? `${c1.btn} ${c1.btn_enabled}`
              : `${c1.btn} ${c1.btn_disabled}`
          }
        >
          Подарить подписку
        </button>
        <div className={c1.oferta}>
          Покупая подписку в подарок, вы соглашаетесь с условиями
          {' '}
          <a className={c1.ofertaLink} href="https://reminder.media/public_offer_subscription">оферты</a>
          .
        </div>
      </form>
      <Modal
        isOpen={open}
        ariaHideApp={false}
        onRequestClose={onClose}
        overlayClassName={c.modal}
        shouldCloseOnOverlayClick={false}
        className={c.modal__content}
        style={{ overlay: { zIndex: 10000 } }}
      >
        {state.key === keys.LOADING && (
          <div className={c.payment_form_wrapper}>
            <Loader />
          </div>
        )}

        {state.key === keys.PAYMENT && (
          <div className={`${c.payment_form_wrapper}`}>
            <button className={c.modal__close} type="button" onClick={onClose}>
              <CloseIcon />
            </button>
            {!isPaymentFormReady && <Loader className={c.payment_form} />}
            <div className={c.payment_form} id="payment-form" />
          </div>
        )}
      </Modal>
    </>
  );
};

GiftForm.displayName = 'GiftForm';
export default GiftForm;
