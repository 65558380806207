import React, { Dispatch, FC, SetStateAction } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import Loader from '../Loader';
import c from '../Reports/Form.sass';

interface TProps {
  keys: {[key: string]: number}
  stateKey: number
  open: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
}

const DonateUnsubscribeModal: FC<TProps> = ({
  keys,
  stateKey: key,
  open,
  setOpenModal,
}) => {
  const history = useHistory();
  const states = {
    [keys.LETTER_SENT]: {
      title: 'Письмо отправлено на почту',
      subtitle: 'Перейдите в письме по ссылке чтобы отписаться от платежей',
      button: 'Хорошо',
      action: () => setOpenModal(false),
    },
    [keys.SUCCESS]: {
      title: 'Вы успешно отписались от платежей!',
      subtitle: '',
      button: 'На главную',
      action: () => history.push('/'),
    },
    [keys.NOT_FOUND]: {
      title: 'Ошибка',
      subtitle: 'На найден пользователь с таким email',
      button: 'Назад',
      action: () => setOpenModal(false),
    },
    [keys.ALREADY_UNSUBSCRIBED]: {
      title: 'Ошибка',
      subtitle: 'Вы уже отписались от платежей',
      button: 'На главную',
      action: () => history.push('/'),
    },
    [keys.SOMETHING_WENT_WRONG]: {
      title: 'Упс...',
      subtitle: 'Что-то пошло не так',
      button: 'Назад',
      action: () => setOpenModal(false),
    },
  };

  return (
    <Modal
      isOpen={open}
      ariaHideApp={false}
      overlayClassName={c.modal}
      shouldCloseOnOverlayClick={false}
      className={c.modal__content}
      style={{ overlay: { zIndex: 10000 } }}
    >
      {key === keys.LOADING && (
        <div className={c.form}>
          <Loader />
        </div>
      )}
      {key !== keys.LOADING && (
        <div className={c.form}>
          <div className={c.form__content}>
            <h1 className={`${c.form__title} ${c.form__title_ok}`}>
              <span>{states[key].title}</span>
            </h1>
            <h2 className={c.form__subTitle}>{states[key].subtitle}</h2>
          </div>
          <button
            className={`${c.form__submit} ${c.form__submit_ok}`}
            onClick={states[key].action}
            type="button"
          >
            {states[key].button}
          </button>
        </div>
      )}
    </Modal>
  );
};

DonateUnsubscribeModal.displayName = 'DonateUnsubscribeModal';
export default DonateUnsubscribeModal;
