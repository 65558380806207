import React, { FC } from 'react';

import SubscriptionForm from '~/components/Subscription/SubscriptionForm';

import Title from '~/components/typography/Title';
import { MailingStatus } from '~/resources/useMailing';
import { usePageContext } from '~/components';
import c from './FooterEmailSubscription.sass';

type TProps = {
  setMailingStatus: (status: MailingStatus) => void;
}

const FooterEmailSubscription: FC<TProps> = ({ setMailingStatus }) => {
  const [hide] = usePageContext().hideMenu;

  return (
    <SubscriptionForm
      formClassName={c.footer_email_subscription}
      inputClassName={c.footer_email_subscription_input}
      inputPlaceholder="Ваш Email"
      inputDisabled={!hide}
      buttonClassName={c.footer_email_subscription_button}
      onChangeStatus={setMailingStatus}
    >
      <Title level={4}>Подписаться</Title>
    </SubscriptionForm>
  );
}

FooterEmailSubscription.displayName = 'FooterEmailSubscription';
export default FooterEmailSubscription;
