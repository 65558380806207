import React, { FC } from 'react';

const NoPicture: FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8783_6044)">
        <rect width="100%" height="100%" fill="transparent" />
        <g filter="url(#filter0_f_8783_6044)">
          <circle cx="150" cy="150" r="93" fill="#974DE0" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.984 170.353L132.814 155.715L112.882 151.906C109.422 151.304 108 150.302 108 147.895C108 147.094 108.204 146.291 108.609 145.288L113.491 130.25C114.304 127.645 115.324 126.442 117.152 126.442C118.171 126.442 119.389 126.842 121.017 127.645L139.525 136.067L137.085 116.016C136.883 115.415 136.883 114.812 136.883 114.011C136.883 111.002 138.103 110 141.966 110H158.035C161.493 110 163.121 111.203 163.121 114.21L162.915 116.016L160.476 136.067L178.781 127.645C180.409 126.842 181.627 126.442 182.646 126.442C184.272 126.442 185.492 127.645 186.305 130.25L191.391 145.288C191.798 146.291 192 147.094 192 147.895C192 150.302 190.578 151.304 187.12 151.906L166.984 155.715L181.018 170.353C182.442 171.757 183.255 173.161 183.255 174.364C183.255 175.766 182.238 177.17 180.409 178.373L167.391 187.596C165.967 188.598 164.949 189 163.934 189C162.306 189 161.289 187.998 159.865 185.591L149.9 167.947L140.137 185.591C138.711 187.797 137.492 189 136.068 189C135.051 189 133.831 188.598 132.407 187.596L119.593 178.373C117.763 176.969 116.745 175.766 116.745 174.364C116.745 173.161 117.558 171.757 118.984 170.353Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_8783_6044"
          x="-3"
          y="-3"
          width="306"
          height="306"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="30" result="effect1_foregroundBlur_8783_6044" />
        </filter>
        <clipPath id="clip0_8783_6044">
          <rect width="300" height="300" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

NoPicture.displayName = 'NoPicture';
export default NoPicture;
