import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Page } from '~/components';
import c from '~/components/post/ContentPost.sass';
import Banner from '~/components/Banner';
import Helmeter from './Helmeter';

const NotFoundScreen: FC = () => (
  <>
    <Banner />
    <Page>
      <Helmeter
        title="404"
        metaTitle="404"
      />
      <div className={c.container}>
        <div className={c.wrap} />
        <h2>404*</h2>
        <br />
        <span>
          Такой страницы нет. Есть другие, и очень много. Чтобы найти их,
          {' '}
          <Link to="/"> начните с главной</Link>
          .
        </span>
      </div>
    </Page>
  </>

);

NotFoundScreen.displayName = 'NotFoundScreen';
export default NotFoundScreen;
