import React, { FC } from 'react';

const LogoYandexIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5910_2401)">
      <path
        d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
        fill="#FC3F1D"
      />
      <path
        d="M13.691 19.212H16.198V4.81201H12.551C8.88396 4.81201 6.95697 6.69751 6.95697 9.47391C6.95697 11.6909 8.01397 12.9962 9.89897 14.3429L6.62598 19.212H9.33997L12.987 13.7628L11.723 12.9133C10.189 11.8773 9.44397 11.0693 9.44397 9.32881C9.44397 7.79561 10.521 6.75961 12.572 6.75961H13.691V19.212Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5910_2401">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>

);

LogoYandexIcon.displayName = 'LogoYandexIcon';
export default LogoYandexIcon;
