import React, { FC, useEffect } from 'react';

import PageHeader from '~/components/page/header/PageHeader';
import MenuPage from '~/components/page/MenuPage';
import Footer from '~/components/page/footer/Footer';
import clsx from 'clsx';
import c from './PageMain.sass';
import AlertModal from '../Alert/AlertModal';
import { AuthModal } from '../Auth';
import useExtrernalScripts from '~/resources/useExtrernalScripts';

interface TProps {
    dark?: boolean;
    hideSeparator?: boolean;
    isMainPage?: boolean;
}

const Page: FC<TProps> = ({
  dark = false,
  hideSeparator = false,
  isMainPage = false,
  children,
}) => {
  const { fetchBy } = useExtrernalScripts();

  useEffect(() => {
    if (isMainPage) { return; }
    fetchBy();
  },[isMainPage]);

  return (
    <>
      <div className={clsx(c.page, { [c.dark]: dark })}>
        <MenuPage />
        <div className={`${c.content} ${c.page_content} ${!hideSeparator ? c.page_content_with_separator : ''}`}>
          <PageHeader dark={dark} />
          <div className={c.inner_content}>
            {children}
          </div>
          <Footer />
        </div>
        <AuthModal />
        <AlertModal />
      </div>
    </>
  );
};

Page.displayName = 'Page';
export default Page;
