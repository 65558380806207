import React, { FC } from 'react';

const HamburgerIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 17.6345V16.5H20.5V17.6345H3.5ZM3.5 12.5672V11.4327H20.5V12.5672H3.5ZM3.5 7.49998V6.36548H20.5V7.49998H3.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

HamburgerIcon.displayName = 'HamburgerIcon';
export default HamburgerIcon;
