import React, { FC } from 'react';
import { TAuthor } from '~/resources/models';
import NoPicture from '../icons/NoPicture';
import c from './AuthorPage.sass';

interface TProps {
  author: TAuthor;
}

const AuthorPage: FC<TProps> = ({author}: TProps) => {
  const { image, name, bio } = author;
  return(
    <div className={c.container}>
      <div className={c.author_item}>
        {image
          ? <img src={image.src} alt={image.alt ?? name} className={c.author_item__avatar} />
          : <div className={c.author_item__avatar}><NoPicture /></div>}
        <h1 className={c.author_item__name}>Автор: {author.name}</h1>
      </div>
      {bio 
        ? <div 
          dangerouslySetInnerHTML={{ __html: bio }}
          className={c.bio}
        />
        : null}

    </div>
  );
};

AuthorPage.displayName = 'AuthorPage';
export default AuthorPage;
