import { MutableRefObject } from 'react';

type TParams = {
  headers: HTMLHeadingElement[];
  firstRender: MutableRefObject<boolean>;
  setActiveIdx: (idx: number) => void;
}

const MINIMUM_LEAVING_BELOW_IDX = 4;

export function getIntersectionObserver(params: TParams): IntersectionObserver | null {
  const { headers, firstRender, setActiveIdx } = params;

  const headersVisible = { count: 0 };
  const entriesCounter = { count: 0 };

  const headersIndices = new Map<Element, number>();

  headers.forEach((header, idx) => {
    headersIndices.set(header, idx);
  });

  const viewportHeight = window.visualViewport?.height;
  if (!viewportHeight) return null;

  const viewportHeightHalf = viewportHeight / 2;

  const handleEntry = (entry: IntersectionObserverEntry) => {
    const { isIntersecting, target, boundingClientRect } = entry;

    entriesCounter.count += 1;

    const curIndex = headersIndices.get(target);

    if (curIndex === undefined) return;

    if (isIntersecting) {
      headersVisible.count += 1;

      const isIntersectingAbove = boundingClientRect.top < viewportHeightHalf;

      if (isIntersectingAbove) {
        setActiveIdx(curIndex);
      }

      return;
    }

    headersVisible.count = Math.max(headersVisible.count - 1, 0);

    const isLeavingAbove = boundingClientRect.top < 0;
    const isLeavingBelow = boundingClientRect.top > viewportHeightHalf;

    if (isLeavingBelow
      && !headersVisible.count
      && entriesCounter.count > MINIMUM_LEAVING_BELOW_IDX
    ) {
      setActiveIdx(Math.max(curIndex - 1, 0));
    }

    if (isLeavingAbove) {
      setActiveIdx(curIndex);
    }
  };

  return new IntersectionObserver((entries) => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    entries.forEach((entry) => {
      handleEntry(entry);
    });
  }, {
    root: null,
    threshold: 1,
  });
}
