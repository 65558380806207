export interface PurchaseEvent {
  orderId: string;
  revenue: string;
  product: {
    id: string,
    name: string,
    price: string;
  }
}

export const sendPurchaseEvent = (event: PurchaseEvent) => {
  if (typeof window !== 'undefined' && typeof (window as any).dataLayer !== 'undefined') {
    (window as any).dataLayer.push({
      ecommerce: {
        currencyCode: 'RUB',
        purchase: {
          actionField: {
            id: event.orderId,
            affiliation: 'Online Store',
            revenue: event.product.price,
          },
          products: [{
            id: event.product.id,
            name: event.product.name,
            price: event.product.price,
            brand: 'Reminder',
            category: 'Products/Reports',
            quantity: 1,
          }],
        },
      },
      event: 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Purchase',
      'gtm-ee-event-non-interaction': 'False',
    });
  }
};

export enum SubscriptionStatus {
  SOMETHING_WENT_WRONG = 'something_went_wrong',
  ALREADY_SUBSCRIBED = 'already_subscribed',
  PLAN_NOT_ALLOWED = 'plan_not_allowed',
  PAYMENT_FAILED = 'payment_failed',
  UNAUTHORIZED = 'unauthorized',
  PAYMENT_FORM_ERROR = 'payment_form_error',
  PAYMENT_CHECK_PENDING = 'pending',
  PAYMENT_CHECK_FAILED = 'failed',
  PAYMENT_CHECK_PROCESSED = 'processed',
}

export const keys = {
  LOADING: 0,
  SUBMIT: 1,
  PAYMENT: 2,
  SUCCESS: 3,
  FREE_SUCCESS: 4,
  IN_PAY: 5,
  FAIL: 6,
  SOMETHING_WENT_WRONG: 7,
  REQUEST_DATA: 8,
  ERROR_ALREADY_SUBSCRIBED: 9,
  ERROR_PLAN_NOT_ALLOWED: 10,
};

export const states = {
  [keys.LOADING]: { key: keys.LOADING },
  [keys.SUBMIT]: { key: keys.SUBMIT },
  [keys.REQUEST_DATA]: { key: keys.REQUEST_DATA },
  [keys.PAYMENT]: { key: keys.PAYMENT },
  [keys.SUCCESS]: { key: keys.SUCCESS },
  [keys.FREE_SUCCESS]: { key: keys.FREE_SUCCESS },
  [keys.IN_PAY]: { key: keys.IN_PAY },
  [keys.FAIL]: { key: keys.FAIL },
  [keys.SOMETHING_WENT_WRONG]: { key: keys.SOMETHING_WENT_WRONG },
  [keys.ERROR_ALREADY_SUBSCRIBED]: { key: keys.ERROR_ALREADY_SUBSCRIBED },
  [keys.ERROR_PLAN_NOT_ALLOWED]: { key: keys.ERROR_PLAN_NOT_ALLOWED },
};
