import React, { FC } from 'react';

import Paragraph from '~/components/typography/Paragraph';
import Title from '~/components/typography/Title';
import { SubscriptionPlan } from '~/resources/models';
import { getPriceLabels } from '~/components/PaidSubscription/helpers/getPriceLabels';
import RubleIcon from '~/components/icons/Ruble';
import Button from '~/components/Button/Button';
import clsx from 'clsx';
import RubleThinIcon from '~/components/icons/RubleThin';
import c from './SubscriptionFormItem.sass';

type TProps = {
  plan: SubscriptionPlan;
  onSubmit: () => void;
  className?: string;
};

const SubscriptionFormItem: FC<TProps> = ({plan, onSubmit, className}) => {

  const { type } = plan;

  const {
    priceTitle, priceSubtitle, percentLabel,
  } = getPriceLabels(plan);

  const isAnnual = type === 'annual';

  const headerText = isAnnual ? 'Сразу на год' : 'Попробовать месяц';

  const renderMonthlyDescription = () => (
    <Paragraph size="small" className={c.description}>Отменить подписку можно в любой момент.</Paragraph>
  );

  const renderAnnualDescription = () => (
    <>
      <div className={c.floating_discount_badge}>
        {percentLabel}
      </div>
      <Paragraph size="small" className={c.description}>
        Если поняли, что будете регулярно обращаться к Reminder.
      </Paragraph>
    </>
  );

  return (
    <div className={clsx(c.subscription_form_item, className, {
      [c.subscription_form_item_is_annual]: isAnnual,
    })}
    >
      <div className={c.header}>
        <Paragraph weight="bold" className={c.title}>{headerText}</Paragraph>
      </div>
      <div className={c.separator} />
      <div className={c.prices_container}>
        <Title level={3} className={c.price_title}>
          {priceTitle}
          <div className={c.ruble_wrapper}>
            <RubleIcon />
          </div>
        </Title>
        <Paragraph
          size="small"
          className={clsx(c.price_subtitle, {
            [c.price_subtitle_accent]: isAnnual,
          })}
        >
          <span>{priceSubtitle}</span>
          {!isAnnual && (
            <>
              <span className={c.ruble_wrapper}>
                <RubleThinIcon />
              </span>
              <span>{' /год'}</span>
            </>
          )}
        </Paragraph>
      </div>
      {isAnnual ? renderAnnualDescription() : renderMonthlyDescription()}
      <Button size="large" variant="primary" className={c.subscribe_btn} onClick={onSubmit}>Подписаться</Button>
    </div>
  );
}

SubscriptionFormItem.displayName = 'SubscriptionFormItem';
export default SubscriptionFormItem;
