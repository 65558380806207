import { SCREEN_MD } from '~/utils/breakpoints';

const TOOLTIP_WIDTH = 300;
const TOOLTIP_TOP_OFFSET = 15;
const TOOLTIP_X_OFFSET = 8;
const SIDEBAR_WIDTH = 310;

export const getNewLeftPos = (linkRect: DOMRect, windowWidth: number) => {
  const leftOffset = (TOOLTIP_WIDTH - linkRect.width) / 2;
  const sidebarOffset = windowWidth > SCREEN_MD ? SIDEBAR_WIDTH : 0;

  let newLeftPos = linkRect.x - leftOffset - sidebarOffset;

  const diff = windowWidth - newLeftPos - TOOLTIP_WIDTH;

  if (diff < 0) {
    newLeftPos = newLeftPos + diff - TOOLTIP_X_OFFSET;
  }

  if (newLeftPos < 0) {
    newLeftPos = TOOLTIP_X_OFFSET;
  }

  return newLeftPos;
};

export const getNewTopPos = (linkRect: DOMRect) => (
  linkRect.y + linkRect.height + TOOLTIP_TOP_OFFSET + window.scrollY
);
