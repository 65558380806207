import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { Redirect, useParams } from 'react-router';

import Helmeter from '~/screens/Helmeter';
import JsonLdPost from '~/screens/jsonLdPost';
import {
  ContentPost, HeaderPost, Loader, Page, Recommendations, usePageContext,
} from '~/components';
import usePost from '~/resources/usePost';
import config from '~/config';
import { usePartnerMaterialHooks } from '~/utils/PartnerMaterialsHooks';
import { isResourceFetchError } from '~/components/Subscription/utils';
import Banner from '~/components/Banner';
import { FetchStatus } from '~/resources';
import increasePostViewFetcher, { updatePostViewFetcher } from '~/resources/usePostView';
import { PostType } from '~/resources/PostType';

const SuperPostScreen: FC = () => {
  const { slug } = useParams<{ slug: string }>();

  const [isReadyPaywall, setIsReadyPaywall] = useState(false);

  const {
    auth, profile, profileStatus,
  } = usePageContext();
  const [_, setLastPost] = usePageContext().lastPost;
  const { status, fetchBy, data: post } = usePost(slug, auth);
  // ToDo: remove kostyl'
  const isFirstLoad = useRef(profile && !profile[0]);
  const [isFirstLook, setIsFirstLook] = useState(false);

  const increasePostView = async () => {
    if (!auth?.DATA.postToken
      || typeof auth?.DATA.postToken !== 'string') {
      const data = await updatePostViewFetcher(slug);
      if (data.result) {
        increasePostViewFetcher({ token: data.result });
      }
      return;
    }
    increasePostViewFetcher({ token: auth?.DATA.postToken });
    auth?.setCache('postToken', undefined);
  };

  useEffect(() => {
    if (slug) {
      if (post?.slug === slug) {
        increasePostView();
      } else {
        setIsFirstLook(true);
      }
      fetchBy();
    }
  }, [slug]);

  useEffect(() => {
    if (!post || !isFirstLook) { return; }
    setIsFirstLook(false);
    increasePostView();
  }, [post]);

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (post?.is_preview && profileStatus && profileStatus[0] === FetchStatus.Fetched) {
      if (
        isFirstLoad.current 
        && profile
        && profile[0]
        && (profile[0].is_subscribed || profile[0].is_admin)
      ) {
        fetchBy(true);
        isFirstLoad.current = false;
        // disableScroll(false);
      } else {
        setIsReadyPaywall(true);
      }
    }
  }, [profile, profileStatus, post]);

  const prefix = post?.preview_mode === 'preview_super' ? PostType.SUPER : PostType.LONGREAD;
  const canonicalLink = `${config('APP_HOST')}/${prefix}/${slug}`;

  usePartnerMaterialHooks(post);

  if (!post) {
    return isResourceFetchError(post, status) ? <Redirect to="/404" /> : <Loader />;
  }
  if ((auth?.AUTH as any).access_token) {
    setLastPost(post);
  }

  return (
    <>
      <Banner />
      <Helmeter
        title={post.title}
        description={post.description}
        metaTitle={post.meta_title || post.title}
        metaDescription={post.meta_description || post.description}
        img={post?.image?.src}
        type="article"
        link={[
          { rel: 'canonical', href: canonicalLink },
        ]}
        srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
      />
      <JsonLdPost post={post} />
      <Page hideSeparator>
        <HeaderPost post={post} />
        <ContentPost
          post={post}
          checkLimit={false}
          isPaywallReady={isReadyPaywall}
        />
        <Recommendations postExcludeId={post.id} />
      </Page>
    </>
  );
};

SuperPostScreen.displayName = 'SuperPostScreen';
export default SuperPostScreen;
