import
React, {
  FC,
  useEffect,
  useRef,
  useState,
  createRef,
} from 'react';
import { useParams } from 'react-router';

import usePosts from '~/resources/usePosts';
import { PostType } from '~/resources/PostType';
import PreviewLongread from './PreviewLongread';
import c from './longread.sass';

const ListLongread: FC = () => {
  const { topic } = useParams();
  const { fetchBy, data: posts } = usePosts({ type: PostType.LONGREAD, page: '1', topic });
  const scroll = useRef();
  const [refs, setRefs] = useState([]);
  useEffect(() => {
    fetchBy();
  }, [topic]);

  useEffect(() => {
    if (posts) {
      setRefs(posts.map(() => createRef()));
    }
  }, [posts]);

  const onScroll = (line: 'left' | 'right') => {
    const scrollElement = scroll.current as HTMLDivElement;
    let element: HTMLDivElement;
    const stepCount = 20;

    if (line === 'left') {
      for (let indexRef = refs.length - 1; indexRef >= 0; indexRef--) {
        element = refs[indexRef].current;

        if (element.offsetLeft < scrollElement.scrollLeft) {
          const stepSlide = (a, b) => {
            if (a >= b) {
              scrollElement.scrollLeft = a;
              setTimeout(() => stepSlide(a - stepCount, b), stepCount);
            } else {
              scrollElement.scrollLeft = b;
            }
          };

          return stepSlide(scrollElement.scrollLeft, element.offsetLeft);
        }
      }
    } else {
      for (let indexRef = 0; indexRef <= refs.length - 1; indexRef++) {
        element = refs[indexRef].current;

        if ((element.offsetLeft + element.offsetWidth) >= scrollElement.scrollLeft) {
          const stepSlide = (a, b) => {
            if (a <= b) {
              scrollElement.scrollLeft = a;
              setTimeout(() => stepSlide(a + stepCount, b), stepCount);
            } else {
              scrollElement.scrollLeft = b;
            }
          };

          return stepSlide(scrollElement.scrollLeft, refs[indexRef + 1].current.offsetLeft);
        }
      }
    }
  };

  return ((posts) ? (
    <div className={c.list}>
      <div className={c.list_header}>
        <div className={c.list_title}>Отчеты</div>
        <div className={c.list_icons}>
          <button
            type="button"
            className={c.list_icon}
            onClick={() => onScroll('left')}
          >
            {'<'}
          </button>
          <button
            type="button"
            className={c.list_icon}
            onClick={() => onScroll('right')}
          >
            {'>'}
          </button>
        </div>
      </div>
      <div className={c.list_scroll} ref={scroll}>
        <div className={c.list_wrap}>
          {posts.map((post, i) => (
            <PreviewLongread key={post.id} ref={refs[i]} post={post} />
          ))}
        </div>
      </div>
    </div>
  ) : null);
};

ListLongread.displayName = 'ListLongread';
export default ListLongread;
