import { useEffect, useRef, useState } from 'react';
import { useConfig } from '~/resources';

const BANNER_HEIGHT = 30;

export default function useStickyHeader() {
  const { data: config } = useConfig();
  const banner = config?.banner;
  const headerRef = useRef<HTMLDivElement>(null);
  const lastScroll = useRef(0);
  const lastTop = useRef(0);
  const lastBorder = useRef(false);
  const [scroll, setScroll] = useState(0);
  const [bannerHeight, setBannerHeight] = useState(0);

  useEffect(() => {
    setBannerHeight(banner ? BANNER_HEIGHT : 0);
  }, [banner]);

  const onScroll = () => {
    const newScroll = window.pageYOffset;
    setScroll((s: number) => {
      lastScroll.current = s;
      return newScroll;
    });
  };

  useEffect(() => {
    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  const scrollLogic = () => {
    let top = 0;
    let topFix = false;
    let border = lastBorder.current;

    if (lastTop.current < 0 || scroll <= 0) {
      top = 0;
      border = false;
      topFix = true;
    } else if (headerRef.current) {
      const h = +headerRef.current.clientHeight + 1 + bannerHeight;
      const isUp = lastScroll.current > scroll;
      const isDown = lastScroll.current <= scroll;

      if (isUp) {
        const isHoldUp = lastTop.current + bannerHeight < scroll;

        if (isHoldUp) {
          top = lastTop.current;
        } else if (banner && scroll < BANNER_HEIGHT && scroll > 0) {
          top = 0;
        } else {
          top = scroll - bannerHeight;
          topFix = true;
        }
      } else if (isDown) {
        const isHoldDown = lastTop.current > scroll - h;

        if (isHoldDown) {
          top = lastTop.current;
        } else {
          top = scroll - h;

          if (scroll > h) {
            border = true;
          }
        }
      }
    }

    lastTop.current = top;
    lastBorder.current = border;

    return { top, topFix };
  };

  const { top, topFix } = scrollLogic();

  return {
    headerRef, top, topFix, scroll, bannerHeight,
  };
}
