import NodeFetch, { RequestInit as NodeRequestInit, RequestInfo as NodeRequestInfo } from 'node-fetch';

const optionsFormat = (opts: RequestInit) => {
  const { headers = {}, credentials, ...restOptions } = opts;
  const options = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    ...restOptions,
  };
  if (typeof FormData !== 'undefined') {
    if (options.body instanceof FormData) {
      delete options.headers['Content-Type'];
    }
  }
  if (credentials) {
    let tokentype = 'Bearer';
    if (credentials.token_type) {
      tokentype = credentials.token_type;
    }
    if (credentials.access_token) {
      options.headers = {
        ...options.headers,
        Authorization: `${tokentype} ${credentials.access_token}`,
      };
    }
  }
  return options;
};

const anyFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  let res;
  const extInit = optionsFormat(init || {});

  if (typeof window !== 'undefined') {
    res = await fetch(input, extInit as RequestInit);
  } else {
    res = await NodeFetch(
      input as NodeRequestInfo,
      extInit as NodeRequestInit,
    ) as unknown as Response;
  }

  return res;
};

export default anyFetch;
