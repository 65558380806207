import React, {
  ChangeEvent, FC, FormEvent, useEffect, useState,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import anyFetch from '~/resources/anyFetch';
import config from '~/config';
import useProfile from '~/resources/useProfile';
import useSubscriptionFlow from '~/resources/useSubscriptionFlow';
import disableScroll from '~/utils/disableScroll';
import c from './Auth.sass';
import { EyeCloseIcon, EyeOpenIcon } from './Icons';
import {
  AlertModalType, AuthModalFrom, AuthModalState, usePageContext,
} from '../page/context.page';
import Loader from '../Loader';
import { SubscriptionFlowStep } from '../page';

interface CheckFormData {
  email: string
  password: string
}

interface TProps {
  _email: string,
  from: AuthModalFrom,
}

const SigninForm: FC<TProps> = ({ _email, from }) => {
  const { url } = useRouteMatch();
  const [formData, setFormData] = useState<CheckFormData>({
    email: _email,
    password: '',
  });
  const { data: profile, fetchBy: fetchProfile } = useProfile();
  const {
    authModal, auth, alertModal, profile: profileState,
  } = usePageContext();
  const { subscriptionFlowVal, buyAfterAuth, cancelSubscription } = useSubscriptionFlow();
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const setProfile = profileState && profileState[1];

  const onChange = (e: ChangeEvent<HTMLInputElement>, value?: unknown) => {
    const { name, value: eventValue } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value ?? eventValue }));
    setIsPasswordInvalid(false);
  };

  useEffect(() => {
    if (!profile || !setProfile) { return; }
    setProfile(profile);
    disableScroll(false);
    if (authModal) {
      authModal[1]({ authModalState: AuthModalState.NONE });
    }
    if (profile.is_subscribed) {
      cancelSubscription();
      return;
    }
    if (
      subscriptionFlowVal
      && subscriptionFlowVal.step === SubscriptionFlowStep.AUTH
      && url === subscriptionFlowVal.subscriptionStartUrl
    ) {
      buyAfterAuth();
    } else {
      history.push('/profile');
    }
  }, [profile]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = formData;
    const apiUrl = `${config('API_HOST')}/oauth/token`.replace('/api', '');
    setIsLoading(true);
    const res = await anyFetch(apiUrl, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        grant_type: 'reminder_passport',
        client_id: config('CLIENT_ID'),
        client_secret: config('CLIENT_SECRET'),
      }),
    });
    const data = await res.json();
    setIsLoading(false);
    if (auth && data.access_token) {
      auth.setAuth(data);
      fetchProfile();
    } else {
      setIsPasswordInvalid(true);
    }
  };

  const onRecoverPasswordError = () => {
    if (!authModal || !alertModal) { return; }
    authModal[1]({ authModalState: AuthModalState.NONE });
    alertModal[1]({ alertType: AlertModalType.ERROR });
    disableScroll(true);
  };

  const onRecoverPasswordSuccess = (email: string) => {
    if (!authModal || !alertModal) { return; }
    authModal[1]({ authModalState: AuthModalState.NONE });
    alertModal[1]({ alertType: AlertModalType.WITHOUT_PASSWORD, email });
    disableScroll(true);
  };

  const RecoverPassword = async () => {
    setIsPasswordInvalid(!isPasswordInvalid);
    const { email } = formData;
    setIsLoading(true);
    const res = await anyFetch(`${config('API_HOST')}/auth/forgot-password`, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    });
    const data = await res.json();
    setIsLoading(false);
    if (res.status !== 200 || data.error) {
      onRecoverPasswordError();
    } else {
      onRecoverPasswordSuccess(email);
    }
    setIsPasswordInvalid(true);
  };

  return (
    <>
      <div className={c.title}>
        { from === AuthModalFrom.NONE
          ? 'Войдите или зарегистрируйтесь'
          : 'Войдите или зарегистрируйтесь, чтобы подписаться' }
      </div>
      <form
        className={c.form}
        onSubmit={onSubmit}
      >
        <input
          disabled
          type="email"
          name="email"
          placeholder="Ваш e-mail"
          aria-label="Email"
          value={formData.email}
          className={c.inputText}
          required
        />
        <div
          className={isPasswordInvalid ? `${c.inputText_password} ${c.inputText_invalid}` : c.inputText_password}
        >
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Пароль"
            aria-label="Password"
            value={formData.password}
            className={c.fullWidth}
            onChange={onChange}
            onInput={() => setIsPasswordInvalid(false)}
            required
          />
          {showPassword ? (
            <button
              type="button"
              className={c.icon_eye}
              onClick={() => setShowPassword(false)}
            >
              <EyeOpenIcon />
            </button>
          ) : (
            <button
              type="button"
              className={c.icon_eye}
              onClick={() => setShowPassword(true)}
            >
              <EyeCloseIcon />
            </button>
          )}
        </div>

        {isPasswordInvalid && (
          <span
            className={c.error}
          >
            Пароль не подходит
          </span>
        )}
        <button
          type="submit"
          aria-label="Войти"
          className={
            isPasswordInvalid
              ? `${c.btn} ${c.btn_disabled}`
              : `${c.btn} ${c.btn_enabled}`
          }
        >
          Войти
        </button>
        <button
          type="button"
          aria-label="Не помню пароль"
          className={`${c.btn} ${c.btn_link}`}
          onClick={RecoverPassword}
        >
          <u>Не помню пароль</u>
        </button>
      </form>
      {isLoading && (
        <div className={c.modal__loading}>
          <Loader />
        </div>
      )}
    </>
  );
};

SigninForm.displayName = 'SigninForm';
export default SigninForm;
