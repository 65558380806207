import React, { FC } from 'react';

const FailIcon: FC = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48 8C26 8 8 26 8 48C8 70 26 88 48 88C70 88 88 70 88 48C88 26 70 8 48 8ZM48 80C30 80 16 66 16 48C16 30 30 16 48 16C66 16 80 30 80 48C80 66 66 80 48 80Z"
      fill="url(#paint0_linear_6093_1584)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.8016 60.6451C38.3886 62.2222 40.9624 62.1847 42.5029 60.5622L48.6051 54.1346L54.9334 60.1425C56.5452 61.6727 59.0953 61.5956 60.6117 59.9709L60.7314 59.8426C62.2381 58.2283 62.1517 55.6984 60.5382 54.1907L54.1865 48.2555L60.0819 42.0458C61.6121 40.434 61.535 37.8839 59.9103 36.3675L59.782 36.2478C58.1677 34.7411 55.6378 34.8276 54.1301 36.441L48.2627 42.7202L41.8837 36.7595C40.3099 35.2889 37.8525 35.3343 36.3341 36.8622C34.757 38.4492 34.7945 41.0231 36.4171 42.5635L42.7717 48.5965L36.6989 55.0955C35.2282 56.6693 35.2737 59.1268 36.8016 60.6451Z"
      fill="url(#paint1_linear_6093_1584)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6093_1584"
        x1="88"
        y1="55.2131"
        x2="7.99014"
        y2="55.0904"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F85B2E" />
        <stop offset="1" stopColor="#B688E4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6093_1584"
        x1="61.8072"
        y1="50.8892"
        x2="35.172"
        y2="50.8484"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F85B2E" />
        <stop offset="1" stopColor="#B688E4" />
      </linearGradient>
    </defs>
  </svg>

);

FailIcon.displayName = 'FailIcon';
export default FailIcon;
