import { MutableRefObject, useEffect, useRef } from 'react';
import { usePageContext } from '~/components';
import { getIntersectionObserver } from '~/components/post/hooks/useGuideContents/getIntersectionObserver';
import { Post } from '~/resources/models';
import { PostType } from '~/resources/PostType';
import useWindowDimensions from '~/utils/useWindowDimensions';
import { SCREEN_MD } from '~/utils/breakpoints';
import { useParams } from 'react-router';

type TParams = {
  containerRef: MutableRefObject<HTMLDivElement | undefined>;
  post: Post;
  isPaywallReady: boolean;
}

const SCROLL_OFFSET = 1;
const PAGE_HEADER_HEIGHT = 64;

export default function useGuideContents(params: TParams) {
  const {
    containerRef, post, isPaywallReady,
  } = params;

  const { guideContents } = usePageContext();
  const { width: windowWidth } = useWindowDimensions();

  const headersRef = useRef<HTMLDivElement[]>([]);
  const { slug } = useParams<{ slug: string }>();

  const firstRender = useRef(true);

  const setActiveIdx = (idx: number) => {
    if (!containerRef.current || !guideContents) return;

    const [_, setGuideContents] = guideContents;

    setGuideContents((prev) => {
      if (!prev) return prev;

      return { ...prev, activeIdx: idx };
    });
  };

  useEffect(() => {
    if (!guideContents || !guideContents[0] || guideContents[0]?.shouldScrollToIdx === null) return;

    const shouldScrollToIdx = guideContents[0]?.shouldScrollToIdx;
    const currentIdx = guideContents[0]?.activeIdx;
    const headerToScroll = headersRef.current[shouldScrollToIdx];
    const setGuideContents = guideContents[1];

    let offset = currentIdx < shouldScrollToIdx ? SCROLL_OFFSET : -SCROLL_OFFSET;

    if (windowWidth < SCREEN_MD && currentIdx > shouldScrollToIdx) {
      offset -= PAGE_HEADER_HEIGHT;
    }

    window.scrollTo({
      top: headerToScroll.getBoundingClientRect().top + window.scrollY + offset,
      behavior: 'smooth',
    });

    setGuideContents((prev) => {
      if (!prev) return prev;

      return { ...prev, shouldScrollToIdx: null };
    });
  }, [guideContents?.[0]?.shouldScrollToIdx]);

  useEffect(() => {
    if (!containerRef.current
      || !guideContents
      || post.type !== PostType.GUIDE
    ) return;

    const [_, setGuideContents] = guideContents;

    if (isPaywallReady) {
      setGuideContents(null);
      return;
    }

    const headers = Array.from(containerRef.current.querySelectorAll('h2')).filter((header) => Boolean(header.textContent));
    const contents = headers.map((header) => header.textContent ?? '');

    headersRef.current = headers;

    setGuideContents({
      slug,
      contents,
      activeIdx: 0,
      shouldScrollToIdx: null,
    });

    const headersIndices = new Map<Element, number>();

    headers.forEach((header, idx) => {
      headersIndices.set(header, idx);
    });

    const observer = getIntersectionObserver({ headers, setActiveIdx, firstRender });

    headers.forEach((header) => observer?.observe(header));

    return () => {
      headers.forEach((header) => observer?.unobserve(header));

      setGuideContents(null);
      firstRender.current = true;
    };
  }, [containerRef, isPaywallReady, post]);
}
