import useResource, { ResourceResult, Resource, Fetcher } from './useResource';
import { TExternalScripts } from './models';

type QueryParams = {
    [key: string]: string | string[]
};

const twitterInit = (): void => {
  window.twttr = (function (d, s, id) {
    let js;
    const fjs = d.getElementsByTagName(s)[0];
    const t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://platform.twitter.com/widgets.js';
    fjs.parentNode.insertBefore(js, fjs);
    t._e = [];
    t.ready = function (f) {
      t._e.push(f);
    };
    return t;
  }(document, 'script', 'twitter-wjs'));
};

const facebookInit = () => {
  const s = document.createElement('script');
  s.src = 'https://connect.facebook.net/en_US/sdk.js';
  s.async = true;
  s.defer = true;
  document.body.appendChild(s);
};

const instagramInit = () => {
  const s = document.createElement('script');
  s.src = 'https://platform.instagram.com/en_US/embeds.js';
  s.async = true;
  s.defer = true;
  document.body.appendChild(s);
};

export const scriptsFetcher = () => async (
    params: QueryParams = {},
    opts: RequestInit = {},
  ): Promise<Resource<TExternalScripts>> => {
    twitterInit();
    facebookInit();
    instagramInit();
    const data = {data: {loaded: true}};
    return data as Resource<TExternalScripts>;
  };

const useExtrernalScripts = (): ResourceResult<TExternalScripts> =>
    useResource({}, {}, scriptsFetcher(), `externalScripts`);

export default useExtrernalScripts;