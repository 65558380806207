import React, { Dispatch, FC, SetStateAction } from 'react';
import Modal from 'react-modal';
import Loader from '../Loader';
import CloseIcon from '../Subscription/ModalSubscription/CloseIcon';
import c from '../Reports/Form.sass';

interface TProps {
  keys: {[key: string]: number}
  stateKey: number
  open: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
}

const DonateModal: FC<TProps> = ({
  keys,
  stateKey: key,
  open,
  setOpenModal,
}) => {
  const states = {
    [keys.SUCCESS]: {
      title: 'Спасибо за поддержку!',
      subtitle: '',
      button: 'Ура!',
    },
    [keys.IN_PAY]: {
      title: 'Платёж обрабатывается',
      subtitle: 'Как только платёж будет зачислен, мы отправим письмо',
      button: 'Хорошо',
    },
    [keys.FAIL]: {
      title: 'Ошибка',
      subtitle: 'Не удалось зачислить платёж',
      button: 'Назад',
    },
    [keys.SOMETHING_WENT_WRONG]: {
      title: 'Упс...',
      subtitle: 'Что-то пошло не так',
      button: 'Назад',
    },
  };

  return (
    <Modal
      isOpen={open}
      ariaHideApp={false}
      overlayClassName={c.modal}
      shouldCloseOnOverlayClick={false}
      className={c.modal__content}
      style={{ overlay: { zIndex: 10000 } }}
    >
      {key === keys.LOADING && (
        <div className={c.form}>
          <Loader />
        </div>
      )}
      {key === keys.PAYMENT && (
        <div className={`${c.form} ${c.payment_form_wrapper}`}>
          <button type="button" className={c.modal__close} onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </button>
          <div className={c.payment_form} id="payment-form" />
        </div>
      )}
      {![keys.LOADING, keys.PAYMENT].includes(key) && (
        <div className={c.form}>
          <div className={c.form__content}>
            <h1 className={`${c.form__title} ${c.form__title_ok}`}>
              <span>{states[key].title}</span>
            </h1>
            <h2 className={c.form__subTitle}>{states[key].subtitle}</h2>
          </div>
          <button
            className={`${c.form__submit} ${c.form__submit_ok}`}
            onClick={() => setOpenModal(false)}
            type="button"
          >
            {states[key].button}
          </button>
        </div>
      )}
    </Modal>
  );
};

DonateModal.displayName = 'DonateModal';
export default DonateModal;
