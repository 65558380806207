import React, { FC } from 'react';
import c from '~/components/post/ContentPost.sass';
import ServiceText from '~/components/ServiceText';
import { Page } from '~/components';
import config from '~/config';
import Banner from '~/components/Banner';
import Helmeter from './Helmeter';

const canonicalLink = `${config('APP_HOST')}/about`;

const AboutScreen: FC = () => {
  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title="О проекте — Reminder"
          metaTitle="О проекте — Reminder"
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <div className={c.container}>
          <div className={c.wrap}>
            <ServiceText name="about_project" />
          </div>
        </div>
      </Page>
    </>
  );
};

AboutScreen.displayName = 'AboutScreen';
export default AboutScreen;
