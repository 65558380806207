export const getPathname = (url: string): string => {
  const queryStart = url.indexOf('?');
  return queryStart > -1 ? url.substr(0, queryStart) : url;
};

export const getSearch = (url: string): string => {
  const queryStart = url.indexOf('?');
  return queryStart > -1 ? url.substr(queryStart) : url;
};

export const pluralize = (word:string, num: number, endings: string[]): string => {
  if (Math.floor(num / 10) === 1) {
    return word + endings[2];
  }
  if (num % 10 === 1) {
    return word + endings[0];
  }
  if (num % 10 > 4) {
    return word + endings[2];
  }
  return word + endings[1];
};
