import anyFetch from '~/resources/anyFetch';
import config from '~/config';

export default async (body: any): Promise<any> => {
  const res = await anyFetch(
    `${config('API_HOST')}/likes`,
    { method: 'POST', body: JSON.stringify(body) },
  );

  return res.json();
};
