import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Helmeter from '~/screens/Helmeter';
import config from '~/config';
import useSubscriptionList from '~/resources/useSubscriptionList';

import logo from '~/images/logo.svg';

import c from '~/components/Donate/Donate.sass';
import cf from '~/components/Donate/DonateForm.sass';
import anyFetch from '~/resources/anyFetch';
import DonateUnsubscribeModal from '~/components/Donate/DonateUnsubscribeModal';
import validateEmail from '~/utils/email';

const DONATE_URL = `${config('API_HOST')}/donates/unsubscribe`;
const SUBSCRIPTION_URL = `${config('API_HOST')}/subscription/unsubscribe`;

const keys = {
  LOADING: 0,
  LETTER_SENT: 1,
  SUCCESS: 2,
  NOT_FOUND: 3,
  ALREADY_UNSUBSCRIBED: 4,
  SOMETHING_WENT_WRONG: 5,
};
const states = {
  [keys.LOADING]: { key: keys.LOADING },
  [keys.LETTER_SENT]: { key: keys.LETTER_SENT },
  [keys.SUCCESS]: { key: keys.SUCCESS },
  [keys.NOT_FOUND]: { key: keys.NOT_FOUND },
  [keys.ALREADY_UNSUBSCRIBED]: { key: keys.ALREADY_UNSUBSCRIBED },
  [keys.SOMETHING_WENT_WRONG]: { key: keys.SOMETHING_WENT_WRONG },
};

const DonateUnsubscribeConfirmationScreen: FC = () => {
  const { pathname } = useLocation();
  const isDonate = pathname.match(/donate/) !== null;
  const URL = isDonate ? DONATE_URL : SUBSCRIPTION_URL;
  const canonicalLink = isDonate ? `${config('APP_HOST')}/donate` : `${config('APP_HOST')}/subscription`;
  const history = useHistory();

  const [email, setEmail] = useState(null);
  const [error, setError] = useState(false);
  const [key, setKey] = useState<string | null>(null);
  const [state, setState] = useState(states[keys.LOADING]);
  const [openModal, setOpenModal] = useState(false);
  const { fetchBy, data: subscription } = useSubscriptionList({ key: `${key}` });

  // Костыль для асинхронных запросов, чтобы при ssr к картинкам в src добавлялся префикс /static
  const [show, setShow] = useState(false);

  const parseEmail = (keyParam: string) => {
    const split = keyParam.split('.');

    if (split.length < 2) {
      setError(true);
    }

    const emailEncoded = split[0];
    const emailDecoded = atob(emailEncoded);
    const emailNew = JSON.parse(emailDecoded);

    if (!validateEmail(emailNew.email)) {
      setError(true);
    }

    setEmail(emailNew.email);
  };

  useEffect(() => {
    setShow(true);

    if (typeof window === 'undefined') return;

    const urlParams = new URLSearchParams(window.location.search);
    const keyParam = urlParams.get('key');

    if (!keyParam) {
      setError(true);
      return;
    }

    setKey(keyParam);
    parseEmail(keyParam);
  }, []);

  useEffect(() => {
    if (key && !isDonate) {
      fetchBy();
    }
  }, [key]);

  if (!show) return null;

  const onError = () => {
    history.push('/');
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModal(true);
    setState(states[keys.LOADING]);
    const form = e.currentTarget;
    const subscriptionId = form.subscriptionId?.value || null;
    const res = await anyFetch(`${URL}/${key}${subscriptionId ? `?id=${subscriptionId}` : ''}`);

    if (!res.ok) {
      setState(states[keys.SOMETHING_WENT_WRONG]);
    }

    const json = await res.json();

    if (json.error) {
      setState(states[keys.SOMETHING_WENT_WRONG]);
    }

    switch (json.data.status) {
      case 'ok': {
        setState(states[keys.SUCCESS]);
        break;
      }
      case 'already_unsubscribed': {
        setState(states[keys.ALREADY_UNSUBSCRIBED]);
        break;
      }
      default: {
        setState(states[keys.SOMETHING_WENT_WRONG]);
        break;
      }
    }
  };

  let form;
  if (isDonate) {
    form = (
      <form
        className={cf.form}
        onSubmit={onSubmit}
        style={{ gap: 0 }}
      >
        <div className={cf.boxEmail}>
          Чтобы отписаться от автоплатежей - нажмите на кнопку
        </div>
        <button
          type="submit"
          aria-label="Отписаться"
          className={`${cf.inputText} ${cf.submit}`}
          style={{ transform: 'translateY(45px)' }}
        >
          Отписаться
        </button>
      </form>
    );
  } else if (subscription && subscription.length > 0) {
    form = [];
    subscription.forEach((sub) => {
      form.push((
        <form
          className={cf.form}
          onSubmit={onSubmit}
          style={{ gap: 0 }}
          key={sub.id}
        >
          <input
            type="hidden"
            id={`subscriptionId-${sub.id}`}
            name="subscriptionId"
            value={sub.id}
          />
          <div className={cf.boxEmail}>
            {`Активная подписка: ${sub.title} от `}
            {new Date(+sub.subscribed_at * 1000).toLocaleDateString('ru')}
          </div>
          <button
            type="submit"
            aria-label="Отписаться"
            className={`${cf.inputText} ${cf.submit}`}
            style={{ transform: 'translateY(45px)' }}
          >
            Отписаться
          </button>
        </form>
      ));
    });
  } else if (typeof subscription === 'object' && subscription.length < 1) {
    form = (
      <>
        <form
          className={cf.form}
          onSubmit={onError}
          style={{ gap: 0 }}
        >
          <div className={cf.boxEmail}>
            У вас нет активных подписок
          </div>
          <button
            type="submit"
            aria-label="Войти"
            className={`${cf.inputText} ${cf.submit}`}
            style={{ transform: 'translateY(45px)' }}
          >
            На главную
          </button>
        </form>
      </>
    );
  }

  return (
    <>
      <Helmeter
        title="Поддержите Reminder. Инвестируйте в себя."
        metaTitle="Поддержите Reminder. Инвестируйте в себя."
        link={[
          { rel: 'canonical', href: canonicalLink },
        ]}
      />
      <DonateUnsubscribeModal
        keys={keys}
        stateKey={state.key}
        open={openModal}
        setOpenModal={setOpenModal}
      />
      <div className={c.container} style={{ height: '100vh' }}>
        <div className={c.wrap}>
          <a href="/">
            <img src={logo} className={c.logo} alt="#" />
          </a>
          <div className={c.titleContainer}>
            <div className={c.titleBox}>
              <p className={c.title}>Личный кабинет.</p>
            </div>
          </div>
          {(email && !error) && (
          <>
            <p className={c.subtitle}>
              Ваш email:
              {' '}
              { email }
            </p>
            {form}
          </>
          )}
          {error && (
          <>
            <p className={c.subtitle}>Что-то пошло не так :(</p>
            <form
              className={cf.form}
              onSubmit={onError}
              style={{ gap: 0 }}
            >
              <div className={cf.boxEmail}>
                Перейдите на главную и попробуйте войти снова
              </div>
              <button
                type="submit"
                aria-label="Войти"
                className={`${cf.inputText} ${cf.submit}`}
                style={{ transform: 'translateY(45px)' }}
              >
                На главную
              </button>
            </form>
          </>
          )}
          {/* Form */}
        </div>
      </div>
    </>
  );
};

DonateUnsubscribeConfirmationScreen.displayName = 'DonateUnsubscribeConfirmationScreen';
export default DonateUnsubscribeConfirmationScreen;
