import React, { FC } from 'react';

const BulletedListIcon: FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1173 19.7903V18.6558H21V19.7903H10.1173ZM10.1173 4.56726V3.43276H21V4.56726H10.1173ZM10.1173 12.3288V11.1943H21V12.3288H10.1173ZM5.46625 20.648C5.06508 20.648 4.723 20.5114 4.44 20.2383C4.157 19.9651 4.0155 19.6277 4.0155 19.226C4.0155 18.8243 4.15467 18.4843 4.433 18.206C4.71133 17.9277 5.05133 17.7885 5.453 17.7885C5.85467 17.7885 6.19208 17.9316 6.46525 18.2178C6.73842 18.5041 6.875 18.8459 6.875 19.2433C6.875 19.6269 6.73708 19.957 6.46125 20.2335C6.18525 20.5098 5.85358 20.648 5.46625 20.648ZM5.46625 5.42501C5.06508 5.42501 4.723 5.28435 4.44 5.00301C4.157 4.72168 4.0155 4.38735 4.0155 4.00001C4.0155 3.61268 4.15767 3.27835 4.442 2.99701C4.72633 2.71568 5.06808 2.57501 5.46725 2.57501C5.85292 2.57501 6.18392 2.71568 6.46025 2.99701C6.73675 3.27835 6.875 3.61268 6.875 4.00001C6.875 4.38735 6.73708 4.72168 6.46125 5.00301C6.18525 5.28435 5.85358 5.42501 5.46625 5.42501Z"
        fill="black"
      />
      <path
        d="M3.38547 13.665C3.97578 14.2573 4.6773 14.5534 5.49003 14.5534C6.3178 14.5534 7.02632 14.2573 7.61558 13.665C8.20519 13.0727 8.5 12.3688 8.5 11.5534C8.5 10.738 8.20484 10.0341 7.61453 9.44182C7.02422 8.84954 6.31937 8.5534 5.5 8.5534C4.68063 8.5534 3.97578 8.84954 3.38547 9.44182C2.79516 10.0341 2.5 10.738 2.5 11.5534C2.5 12.3688 2.79516 13.0727 3.38547 13.665Z"
        fill="black"
      />
    </svg>
  );
}

BulletedListIcon.displayName = 'BulletedListIcon';
export default BulletedListIcon;
