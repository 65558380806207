import React, { FC } from 'react';
import Title from '~/components/typography/Title';

import Paragraph from '~/components/typography/Paragraph';
import { SubscriptionReview } from '~/resources/models';
import NoPicture from '~/components/icons/NoPicture';
import c from './ReviewCard.sass';

type TProps = {
  review: SubscriptionReview;
};

const ReviewCard: FC<TProps> = (props: TProps) => {
  const { review } = props;

  const {
    text, name, position, image,
  } = review;

  return (
    <div className={c.review_card}>
      <Title level={3} className={c.review_text}>{text}</Title>
      <div className={c.author_section}>
        {image?.src
          ? <img src={image.src} alt={name} className={c.avatar} />
          : <div className={c.avatar}><NoPicture /></div>}
        <Paragraph weight="bold">{name}</Paragraph>
        <Paragraph variant="caption" className={c.position}>{position ?? ' '}</Paragraph>
      </div>
    </div>
  );
}

ReviewCard.displayName = 'ReviewCard';
export default ReviewCard;
