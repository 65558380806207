import { TExtendedLinkState } from '~/components/post/extendedLink/types';
import { useEffect, useRef, useState } from 'react';
import { useIsMounted } from '~/utils/hooks/useIsMounted';
import { WORD_REGEX } from '~/utils/regex';

const EXTENDED_LINK_ACTIVE_CLASSNAME = 'extended-link_active';

type TParams = {
  deps: unknown[];
}

export default function useExtendedLinks(params: TParams) {
  const { deps } = params;

  const [extendedLinksStates, setExtendedLinksStates] = useState<TExtendedLinkState[]>([]);

  const isMounted = useIsMounted();

  const isExtendedLinkStructured = useRef<Map<HTMLElement, boolean>>(new Map());

  const setOpenExtendedLink = (idx: number, isOpen: boolean) => {
    setExtendedLinksStates((prev) => prev.map((curState, stateIdx) => {
      if (stateIdx !== idx) return curState;

      return { ...curState, isOpen };
    }));
  };

  const handleOpen = (idx: number, extendedLink: HTMLElement) => {
    setOpenExtendedLink(idx, true);
    extendedLink.classList.add(EXTENDED_LINK_ACTIVE_CLASSNAME);
  };

  const handleClose = (idx: number, extendedLink: HTMLElement) => {
    setOpenExtendedLink(idx, false);
    extendedLink.classList.remove(EXTENDED_LINK_ACTIVE_CLASSNAME);
  };

  useEffect(() => {
    if (!isMounted()) return;

    const extendedLinks = Array.from(document.querySelectorAll<HTMLElement>('.extended-link'));

    const newExtendedLinksStates: TExtendedLinkState[] = [];

    extendedLinks.forEach((extendedLink, idx) => {
      newExtendedLinksStates.push({
        url: extendedLink.getAttribute('data-extended-link-value') ?? '',
        description: extendedLink.getAttribute('data-extended-link-description') ?? '',
        isOpen: false,
        extendedLinkLabel: extendedLink,
        close: () => handleClose(idx, extendedLink),
      });

      if (!isExtendedLinkStructured.current.get(extendedLink)) {
        Array.from({ length: 2 }).forEach(() => {
          // eslint-disable-next-line no-param-reassign
          extendedLink.innerHTML = extendedLink.innerHTML.replace(WORD_REGEX, '$1<span class="word">$2</span>');
        });

        isExtendedLinkStructured.current.set(extendedLink, true);
      }

      // eslint-disable-next-line no-param-reassign
      extendedLink.onclick = () => {
        handleOpen(idx, extendedLink);
      };
    });

    setExtendedLinksStates(newExtendedLinksStates);
  }, [isMounted(), ...deps]);

  return {
    extendedLinksStates,
  };
}
