import React, { FC, PropsWithChildren } from 'react';

import Title from '~/components/typography/Title';
import clsx from 'clsx';
import ArrowLeftBoldIcon from '~/components/icons/ArrowLeftBold';
import ArrowRightBoldIcon from '~/components/icons/ArrowRightBold';
import useCarousel from '~/components/carousel/hooks/useCarousel';
import c from './Carousel.sass';

type TProps = PropsWithChildren<{
  title?: string
  className?: string;
}>;

const Carousel: FC<TProps> = (props: TProps) => {
  const { title, className, children } = props;

  const {
    ref, isLeftDisabled, isRightDisabled, handleScroll, handleChange,
  } = useCarousel();

  return (
    <div className={clsx(c.carousel, className)}>
      <div className={clsx(c.carousel_header, {
        [c.carousel_header_empty_title]: !title,
      })}
      >
        <Title level={2}>{title}</Title>
        <div className={c.carousel_header_buttons_container}>
          <button
            type="button"
            className={clsx(c.carousel_header_button, {
              [c.carousel_header_button__disabled]: isLeftDisabled,
            })}
            disabled={isLeftDisabled}
            onClick={() => handleChange('left')}
          >
            <ArrowLeftBoldIcon />
          </button>
          <button
            type="button"
            className={clsx(c.carousel_header_button, {
              [c.carousel_header_button__disabled]: isRightDisabled,
            })}
            disabled={isRightDisabled}
            onClick={() => handleChange('right')}
          >
            <ArrowRightBoldIcon />
          </button>
        </div>
      </div>
      <div className={c.items_container} ref={ref} onScroll={handleScroll}>
        {children}
      </div>
    </div>
  );
}

Carousel.displayName = 'Carousel';
export default Carousel;
